.display {
  @import "./vars";

  letter-spacing: $display_letter-spacing;
  font-size: $display_font-size;
  text-transform: uppercase;
  font-weight: $display_font-weight;

  &--indent-fix {
    text-indent: $display_text-indent-fix;
  }

  &--line-height-none {
    line-height: $display-line-height-none_line-height;
  }

  @include media-min(md) {
    font-size: $display_font-size_min-md;
  }

  @include media-min(xxl) {
    font-size: $display_font-size_min-xxl;
  }
}
