.modal {
  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 400;

  &__overlay {
    position: absolute;

    width: 100%;
    height: 100%;

    z-index: -1;

    background-color: rgba(0, 0, 0, 0.2);
  }

  &__content {
    width: 100%;

    max-width: rem(600px);
    max-height: 80vh;
  }

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.enter &__content {
    transform: translateY(-100px);
  }

  &.enter-active &__content {
    transform: translateY(0);
    transition: transform 0.5s;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }

  &.exit &__content {
    transform: translateY(0);
  }

  &.exit-active &__content {
    transform: translateY(-100px);
    transition: transform 0.5s;
  }
}

.contact-modal {
  &__dialog {
    background-color: $color-white;

    display: flex;
    max-height: 80vh;
    flex-direction: column;

    overflow: hidden;
  }

  &__header {
    padding: rem(30px) rem(30px) rem(15px) rem(30px);
  }

  &__header-top {
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    padding: rem(15px) rem(30px) rem(30px) rem(30px);

    overflow-x: auto;
  }
}
