.preloader {
  @import "./body/vars";

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;

  z-index: 999;
  background-color: $body_background-color;
  opacity: 0;
  visibility: hidden;

  transition: all 0.4s;

  &__svg {
    margin: auto;
    opacity: 1;
    backface-visibility: hidden;

    &.is-blinking {
      animation-name: preloader-svg-blinking;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-direction: alternate;
      animation-timing-function: linear;
    }
  }

  &.is-active {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  @keyframes preloader-svg-blinking {
    to {
      opacity: 0;
    }
  }
}
