@import "/src/scss/mixins.scss";

.services-articles-section {
  &__header {
    gap: rem(45px);
  }

  padding: rem(160px) 0;

  @include media-max(md) {
    padding: rem(125px) 0;
  }

  @include media-max(sm) {
    padding: rem(100px) 0;
  }

  &__body {
    height: 100%;
    padding: rem(160px) 0 0 0;

    * {
      text-wrap-mode: wrap !important;
    }

    @include media-max(lg) {
      padding: 115px 0 0 0;
    }

    @include media-max(md) {
      gap: 50px;
      padding: 80px 0 0 0;

    }

    .item {
      width: 50%;

      &:nth-child(even) {
        width: 45.45455%;
        margin-left: auto;
      }

      @include media-max(lg) {
        padding-bottom: rem(20px);
        &:nth-child(even) {
          width: calc(50% - 20px);
        }
      }

      @include media-max(md) {
        width: 100%;
        padding-bottom: rem(20px);

        &:nth-child(even) {
          width: 100%;
        }
      }

      .content {
        padding-top: 15px;

        @include media-max(md) {
          padding-top: 20px;
        }

        .left {
          gap: 10px;

          @include media-max(lg) {
            align-items: flex-end;
          }
        }

        .right {
          text-align: right;

          @include media-max(lg) {
            margin-bottom: rem(9px);
            text-align: left;
          }
        }
      }

      .arrow-link {
        width: rem(32px);
        height: rem(32px);
        transform: rotate(-135deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        transition: 0.3s transform;
        will-change: transform;
        margin-top: auto;
      }

      &:hover {
        .arrow-link {
          transform: rotate(-135deg) translate(0, 15px);
        }
      }
    }
  }
}