.reveal-when-in-view {
  display: block;

  &--inline {
    display: inline-block;
  }

  &__target {
    display: block;
  }

  img {
    backface-visibility: hidden;
  }

  &--inline &__target {
    display: inline-block;
  }
}
