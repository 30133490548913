.showreel-button {
  @import "./vars";

  position: relative;

  font-size: $showreel-button_font-size;
  font-weight: $showreel-button_font-weight;

  // &:hover {
  //   cursor: none;
  // }

  // &:hover & {
  //   &__label {
  //     position: relative;
  //     mix-blend-mode: difference;
  //     color: $color-black-haze;
  //     z-index: 1;
  //   }
  // }

  &--hover &,
  &--hover-in & {
    &__label {
      position: relative;
      mix-blend-mode: difference;
      color: $color-black-haze;
      z-index: 1;
      pointer-events: none;
    }
  }

  &--hover,
  &--hover-in,
  &--fullscreen,
  &--fullscreen-in,
  &--fullscreen-out {
    // z-index: 400;
  }

  &__play-btn-container {
    height: 100%;
    width: 100%;
  }

  &__player-container {
    position: absolute;

    left: 50%;
    top: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    // width: 100%;
    // height: 100%;
    width: 100vw;
    height: 100vh;

    border-radius: 100%;
    overflow: hidden;

    transform: translate(-50%, -50%);

    will-change: width, height, transform;

    opacity: 0;
    z-index: 1;
  }

  &__player {
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-width: none;
    // max-width: 80vw;
    pointer-events: none;
  }

  &__player-background {
    opacity: 0;
    background-color: $primary-color;
    width: 100%;
    height: 100%;
  }

  &__right {
    position: relative;
    display: inline-block;
    // align-items: center;

    width: 1em;
    height: 1em;
    font-size: $showreel-button-play-icon_size;

    margin: 0 $showreel-button-right_padding-x;
  }

  &__right-inner {
    position: relative;
    width: 100%;
    height: 100%;

    display: inline-flex;

    overflow: hidden;
  }

  &__play-btn {
    display: inline-block;

    width: 100%;
    height: 100%;

    border-radius: 100%;
    background-color: $primary-color;
  }

  &__play-btn-inner {
    position: absolute;

    left: 50%;
    top: 50%;

    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    // overflow: hidden;

    border-radius: 100%;
    overflow: hidden;

    // background-color: $primary-color;
    transform: translate(-50%, -50%);
  }

  // &:hover &__play-btn-content,
  // &.is-animating &__play-btn-content {
  //   top: 50%;
  //   left: 50%;

  //   transform: translate(-50%, -50%);
  // }

  &__play-btn-content {
    // pointer-events: none;
    width: 100%;
    height: 100%;

    // will-change: transform;
    // backface-visibility: hidden;

    // transition: all 0.6s;
  }

  &__play-icon {
    position: absolute;

    top: 0;
    right: em(-2px);
    bottom: 0;
    left: 0;

    width: em(6.1px);
    height: em(9.14px);

    margin: auto;

    color: #fff;
  }

  &__video-wrapper {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    // border-radius: 100%;
  }

  &__play-btn-mover {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__video {
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-width: none;
    // max-width: 80vw;
    pointer-events: none;

    opacity: 0;
  }

  // &__close-btn {
  //   top: 0;
  //   left: 0;
  //   position: absolute;
  //   mix-blend-mode: difference;
  //   color: $color-white;
  //   z-index: 1;
  //   opacity: 0;
  // }

  // &__close-btn-icon {
  //   font-size: 3em;
  // }

  @include media-min(md) {
    font-size: $showreel-button_font-size_min-md;
  }

  @include media-min(xl) {
    font-size: $showreel-button_font-size_min-xxl;
  }
}
