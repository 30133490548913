.view-about-services-section {
  @import "./vars";

  &__container {
    padding-top: $view-about-services-section-container_padding-top;
    padding-bottom: $view-about-services-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $view-about-services-section-header_margin-bottom;
  }

  &__heading {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-weight: 300;
  }

  &__heading-term {
    //text-transform: uppercase;
    font-style: italic;
    margin-bottom: $view-about-services-section-heading-term_margin-bottom;
  }

  &__heading-description {
    font-size: $view-about-services-section-heading-description_font-size;
  }

  &__overline {
    margin-bottom: $view-about-services-section-overline_margin-bottom;
  }

  &__body-image {
    width: 100%;
    object-fit: cover;
  }

  &__body-col-with-image {
    overflow: hidden;
    margin-bottom: $view-about-services-section-body-col-with-image_margin-bottom;
  }

  &__body-col-with-image {
    overflow: hidden;
    // height: rem(400px);
  }

  &__body-parallax-image {
    width: 100%;
  }

  &__description-item:not(:last-child) {
    margin-bottom: $view-about-services-section-description-item_margin-bottom;
  }

  // &__body-parallax,
  // &__body-parallax .react-parallax-background-children {
  //   height: rem(700);
  // }

  @include media-min(md) {
    &__container {
      padding-top: $view-about-services-section-container_padding-top_min-md;
      padding-bottom: $view-about-services-section-container_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $view-about-services-section-header_margin-bottom_min-md;
    }

    &__overline {
      margin-bottom: $view-about-services-section-overline_margin-bottom_min-md;
    }

    &__body-col-with-image {
      margin-bottom: $view-about-services-section-body-col-with-image_margin-bottom_min-md;
    }

    &__description {
      margin: 0 auto;
    }

    &__heading-description {
      font-size: $view-about-services-section-heading-description_font-size_min-md;
    }
  }

  @include media-min(lg) {
    &__container {
      padding-top: $view-about-services-section-container_padding-top_min-md;
      padding-bottom: $view-about-services-section-container_padding-bottom_min-lg;
    }

    &__header {
      position: relative;

      margin-bottom: $view-about-services-section-header_margin-bottom_min-lg;
    }

    &__overline {
      position: absolute;

      top: 0;
      left: 0;

      margin-bottom: $view-about-services-section-overline_margin-bottom_min-lg;
    }

    &__heading-term {
      text-align: right;
    }

    &__body-col-with-image {
      margin-bottom: $view-about-services-section-body-col-with-image_margin-bottom_min-lg;
    }

    &__description {
      margin: 0;
    }
  }

  @include media-min(xl) {
    &__heading-term {
      font-size: vw(64 / 1440);
    }

    &__heading-description {
      font-size: vw(45 / 1440);
    }

    &__body-col-with-image {
      overflow: hidden;
      // height: rem(700px);
    }

    &__body-parallax-image {
      height: rem(900px);
      width: 100%;
    }
  }
}
