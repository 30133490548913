.view-portfolio-item-about-section--type-cns {
  padding-top: rem(160);
  padding-bottom: rem(358);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }
}

.portfolio-item-section {

  &--type-cns-3 {
    padding-top: rem(235);
    padding-bottom: rem(208);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(248);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
      }
    }

    @include media-max(md) {
      padding-top: rem(80);
      padding-bottom: rem(80);

      .description {
        margin-bottom: rem(50);
      }
    }

    @media (max-width: 500px) {
      .description {
        flex-direction: column;
        gap: rem(15);

        .list {
          margin-left: 0;
          white-space: normal;
        }
      }
    }
  }

  &--type-cns-5 {
    padding-top: rem(235);
    padding-bottom: rem(273);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(225);

      .list {
        display: flex;
        margin-left: 60%;
        white-space: nowrap;
        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);

      .description {
        margin-bottom: rem(50);
      }
    }

    @media (max-width: 500px) {
      .description {
        flex-direction: column;
        gap: rem(15);

        .list {
          margin-left: 20%;
          white-space: normal;
        }
      }
    }
  }

  &--type-cns-7 {
    padding-top: rem(235);
    padding-bottom: rem(410);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 50%;
        white-space: nowrap;
        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);

      .description {
        margin-bottom: rem(50);
      }
    }

    @media (max-width: 500px) {
      .description {
        flex-direction: column;
        gap: rem(15);

        .list {
          margin-left: 20%;
          white-space: normal;
        }
      }
    }
  }

  &--type-cns-8 {
    .container {
      margin: rem(205) 0 rem(250);
      padding: 0 rem(192) 0;
    }

    @include media-max(xl) {
      .container {
        margin: rem(100) 0 rem(150);
        padding: 0 rem(92) 0;
      }
    }

    @include media-max(md) {
      .container {
        margin: rem(80) 0 rem(80);
        padding: 0 rem(24) 0;
      }
    }
  }

  &--type-cns-9 {
    padding-top: rem(235);
    padding-bottom: rem(290);

    .description {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);

      .description {
        margin-bottom: rem(50);
      }
    }

    @media (max-width: 500px) {
      .description {
        flex-direction: column;
        gap: rem(15);

        .list {
          margin-left: 20%;
          white-space: normal;
          span:not(:last-child) {
            margin-right: rem(10);
          }
        }
      }
    }
  }

  &--type-cns-10 {
    padding: 0 rem(192) 0;

    @include media-max(xl) {
      padding: 0 rem(92) 0;
    }

    @include media-max(md) {
      padding: 0 rem(24) 0;
    }
  }

  &--type-cns-11 {
    padding-top: rem(195);
    padding-bottom: rem(496);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);
    }
  }

  &--type-cns-12 {
    padding: rem(180) rem(192) rem(220);

    @include media-max(xl) {
      padding: rem(120) rem(92) rem(160);
    }

    @include media-max(md) {
      padding: rem(80) rem(24) rem(80);
    }
  }
}