.view-portfolio-wildberries-container {
  padding-left: rem(30);
  padding-right: rem(30);

  @include media-max(xl) {
    padding-left: rem(9);
    padding-right: rem(9);
  }
  @include media-max(md) {
    padding-left: rem(20);
    padding-right: rem(20);
  }
}
