.view-privacy-policy-lead-section {
  &__inner {
    justify-content: space-between;
  }

  &__footer {
    align-items: flex-end;
  }

  @include media-max(lg) {
    &__title {
      font-size: vw(114 / 991);
    }
  }
}
