.view-portfolio-item-section {
  $this: &;
  @import "./vars";
  @import "../../../layout/body/vars";

  background-color: $body_background-color;

  &--layout-gallery-wss-1 & {
    &__header {
      margin-bottom: rem(85px);
    }

    &__header-first-col {
      margin-bottom: rem(30px);
    }
  }

  &--layout-gallery-wss-2 & {
    &__body {
      @include gap(rem(20px));
    }

    &__header {
      margin-bottom: rem(115px);
    }

    &__container {
      overflow: hidden;
    }
  }

  &--layout-gallery-wss-3 & {
    &__header {
      margin-bottom: rem(80px);
    }

    &__body {
      @include gap(rem(55px), "v");
    }

    &__marker {
      &--dot {
        $size: rem(15px);

        width: $size;
        height: $size;

        border-radius: $size;

        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    &__col {
      display: flex;

      &--with-marker {
        display: none;
      }

      &--align-end {
        align-items: flex-end;
      }

      &--justify-end {
        justify-content: flex-end;
      }
    }
  }

  &--layout-gallery-wss-4 & {
    &__container {
      overflow: hidden;
    }

    &__row {
      @include gap(rem(20px));
    }
  }

  &--layout-wss-all-pages & {
    &__header {
      margin-bottom: rem(220px / 2);
    }

    &__body {
      @include gap(rem(70px), "v");
    }

    &__body-subrow {
      @include gap(rem(20px));
    }

    &__slider .swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  &--layout-gosapteka-manager-monitoring {
    #{$this}__container {
      padding-top: rem(60px);
      padding-bottom: rem(60px);
    }

    #{$this}__col-description {
      display: flex;
      align-items: center;
    }
  }

  &--layout-gosapteka-manager-monitoring-gallery & {
    &__container {
      padding-top: rem(325px / 2);
      padding-bottom: rem(360px / 2);
    }

    &__col-image:not(:last-child) {
      margin-bottom: rem(435px / 2);
    }
  }

  @include media-min(sm) {
    &--layout-gallery-wss-2 & {
      &__header {
        margin-bottom: rem(155px);
      }
    }

    &--layout-gallery-wss-3 & {
      &__header {
        margin-bottom: rem(130px);
      }
    }

    &--layout-gallery-wss-4 & {
      &__row {
        @include gap(rem(50px));
      }
    }

    &--layout-wss-all-pages & {
      &__header {
        margin-bottom: rem(220px / 1.5);
      }
    }
  }

  @include media-min(md) {
    &--layout-gallery-wss-1 & {
      &__header {
        margin-bottom: rem(125px);
      }

      &__header-first-col {
        margin-bottom: rem(45px);
      }
    }

    &--layout-gallery-wss-3 & {
      &__col {
        &--with-marker {
          display: flex;
        }
      }

      &__header {
        margin-bottom: rem(195px);
      }

      &__body {
        @include gap(rem(110px), "v");
      }
    }

    &--layout-wss-all-pages & {
      &__header {
        margin-bottom: rem(220px);
      }
    }

    &--layout-gosapteka-manager-monitoring-gallery & {
      &__container {
        padding-top: rem(325px / 1.5);
        padding-bottom: rem(360px / 1.5);
      }

      &__col-image:not(:last-child) {
        margin-bottom: rem(435px / 1.5);
      }
    }
  }

  @include media-min(lg) {
    &--layout-gallery-wss-1 & {
      &__header {
        margin-bottom: rem(190px);
      }
    }

    &--layout-gosapteka-manager-monitoring-gallery & {
      &__container {
        padding-top: rem(325px);
        padding-bottom: rem(360px);
      }

      &__col-image:not(:last-child) {
        margin-bottom: rem(435px);
      }
    }
  }

  @each $name, $size in $view-portfolio-item-section-sizes {
    &--size-top-#{$name} {
      padding-top: $size;
    }

    &--size-bottom-#{$name} {
      padding-bottom: $size;
    }

    &--size-#{$name} {
      padding-top: $size;
      padding-bottom: $size;
    }
  }

  @include media-min(md) {
    @each $name, $size in $view-portfolio-item-section-sizes_min-md {
      &--size-top-#{$name} {
        padding-top: $size;
      }

      &--size-bottom-#{$name} {
        padding-bottom: $size;
      }

      &--size-#{$name} {
        padding-top: $size;
        padding-bottom: $size;
      }
    }

    &--layout-gallery-wss-2 & {
      &__body {
        @include gap(rem(60px));
      }

      &__header {
        margin-bottom: rem(230px);
      }
    }
  }

  @include media-min(xxl) {
    @each $name, $size in $view-portfolio-item-section-sizes_min-xxl {
      &--size-top-#{$name} {
        padding-top: $size;
      }

      &--size-bottom-#{$name} {
        padding-bottom: $size;
      }

      &--size-#{$name} {
        padding-top: $size;
        padding-bottom: $size;
      }
    }
  }
}
