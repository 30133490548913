.arrow-diagonal-button {
  $offset: rem(10px);
  $offset-left: rem(-10px);

  position: relative;

  &__icon, &__icon-left {
    font-size: rem(35px);
    transition: all 0.4s;
  }

  &:hover & {
    &__icon {
      transform: translateX($offset) translateY($offset);
      transition: transform 0.4s cubic-bezier(.27,0,.66,.99),height 0.4s cubic-bezier(.27,0,.66,.99),top 0.4s cubic-bezier(.27,0,.66,.99);
      }
      &__icon-left{
        transform: translateX($offset-left) translateY($offset);
        transition: transform 0.4s cubic-bezier(.27,0,.66,.99),height 0.4s cubic-bezier(.27,0,.66,.99),top 0.4s cubic-bezier(.27,0,.66,.99);
      }
  }
   @include media-min(md) {
    &__icon {
      font-size: rem(34px);
    }
  }
}