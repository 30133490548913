@include generate-utility(
  ml,
  margin-left,
  (
    xxs: (
      font-euclid-flex-indent-fix: percentage(1 / 4),
    ),
  )
);

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-ml-auto{
  margin-left: auto;

}