// container
$home-our-work-section-container_padding-top: rem(113px);
$home-our-work-section-container_padding-top_min-md: rem(136px);
$home-our-work-section-container_padding-top_min-xxl: rem(185px);

$home-our-work-section-container_padding-bottom: rem(127px);
$home-our-work-section-container_padding-bottom_min-md: rem(162px);
$home-our-work-section-container_padding-bottom_min-xxl: rem(187px);

// headier
$home-our-work-section-header_margin-bottom: rem(106px);
$home-our-work-section-header_margin-bottom_min-md: rem(167px);
$home-our-work-section-header_margin-bottom_min-xxl: rem(202px);

// col-with-heading
$home-our-work-section-col-with-heading_margin-bottom: rem(37px);
$home-our-work-section-col-with-heading_margin-bottom_min-md: rem(63px);
$home-our-work-section-col-with-heading_margin-bottom_min-xxl: 0;

// heading
$home-our-work-section-heading_font-size_max-md: rem(30px);

// portfolio-item
$home-our-work-section-portfolio-item-not-last_margin-bottom: rem(106px);
$home-our-work-section-portfolio-item-not-last_margin-bottom_min-md: rem(115px);
$home-our-work-section-portfolio-item-not-last_margin-bottom_min-xxl: rem(
  140px
);

// portfolio-item-name
$home-our-work-section-portfolio-item-name_font-size: rem(19px);

// portfolio-item-caption
$home-our-work-section-portfolio-item-caption_margin-top_min-xxl: rem(25px);

// portfolio-item-preview-inner
$portfolio-item-preview-video-inner_padding-x_min-md: percentage(
  65 / 600
); //percentage(65px / 600px);
$portfolio-item-preview-video-inner_padding-x_min-xxl: percentage(
  155px / 1080px
);

// portfolio-item-preview-video media
$portfolio-item-preview-video-media_padding-y_min-md: 50px; //percentage(50px / 600px);
$portfolio-item-preview-video-media_padding-y_min-xxl: percentage(
  60px / 1080px
);
