.split-text {
  // display: flex;
  // flex-direction: column;

  &__word {
    display: inline-block;

    // Добавляем пробел после каждого слова
    // Сделано через псевдо-элемент, так как при добавление пробела через код,
    // происходил странный перенос некоторыx слов на след. строку
    &:not(:last-child):after {
      content: "\00a0";
      display: inline-block;
    }
  }

  &__line {
    display: block;
  }
}
