.background {
  @import "./vars";

  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  margin: auto;

  z-index: 0;

  @each $color-name, $color in $background-colors {
    &--#{$color-name} {
      background-color: $color;
    }
  }
}
