.parallax {
  height: 100%;

  &__background,
  .react-parallax-background-children {
    height: 100%;
    width: 100%;
  }

  // &__background-parallax,
  // &__background-parallax-target {
  //   height: 100%;
  // }
}
