.vacancy-about-section {
  @import "./vars";

  position: relative;

  &__container-with-text {
    padding-top: $vacancy-about-section-container-with-text_padding-y;
    padding-bottom: $vacancy-about-section-container-with-text_padding-y;
  }

  &__parallax {
    height: $vacancy-about-section-parallax_height;
    object-fit: cover;
  }

  @include media-min(md) {
    &__container-with-text {
      display: none;
    }

    &__parallax {
      height: $vacancy-about-section-parallax_height_min-md;
    }
  }

  @include media-min(xxl) {
    &__parallax {
      height: $vacancy-about-section-parallax_height_min-xxl;
    }
  }
}
