@import "./view-portfolio-csd-5.scss";
@import "./view-portfolio-csd-8.scss";
@import "./view-portfolio-csd-9.scss";
@import "./view-portfolio-csd-13.scss";
@import "./view-portfolio-csd-11.scss";
@import "./view-portfolio-csd-15.scss";
.border{
  border: 1px solid #D7D7D7;
  }

.portfolio-item-section {
  &--type-csd-3 {
    padding-top: rem(142);
    padding-bottom: rem(150);
    .border{
      border: 1px solid #FFFF;
    }
    .portfolio-item-section {
      &__col-with-description {
        margin-bottom: rem(113);
        justify-content: space-between;
      }
      &__item-left {
        width: 21.5%;
        margin-left: 21.4%;
        .img-wrap{
          padding-top: 229%;
          background-color: rgb(247, 247, 247);
          position: relative;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }
      &__item-right {
        width: 21.5%;
        margin-left: 15.2%;
        .u-ratio{
          padding-top: 229%;
        }
      }
    }
  }
  &--type-csd-4 {
    padding-top: rem(350);
    padding-bottom: rem(100);
    position: relative;
    z-index: 1;
    .portfolio-item-section {
      &__description {
        margin-bottom: rem(105);
        justify-content: space-between;
      }
    }
    .u-ratio{
      padding-top: 56.5%;
    }
  }
  &--type-csd-6 {
    padding-top: calc(286px + 37%);
    padding-bottom: rem(178);
    .portfolio-item-section {
      &__item--right {
        margin-bottom: rem(267);
        .item--right__description {
          margin-bottom: 100px;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      &__item--left {
        width: 100%;
        max-width: rem(180);
      }
    }
    .u-ratio{
      padding-top: 56.15%;
    }
  }
  &--type-csd-7 {
    justify-content: space-between;
    padding-bottom: rem(240);
    .portfolio-item-section {
      &__right-side {
        &--gif {
          width: 84%;
          padding-bottom: rem(228);
        }
        &--img {
          width: 62%;
          margin-left: auto;
        }
      }
    }
  }
  &--type-csd-12 {
    padding-top: rem(26);
    padding-bottom: rem(396);
    .portfolio-item-section {
      &__item {
        &--top {
          justify-content: space-between;
          margin-bottom: rem(232);
          .offset-xl-0 {
            align-self: flex-end;
          }
        }
        &--bottom {
          .portfolio-item-section {
            &__description {
              &--item-text {
                margin-bottom: rem(20);
              }
            }
          }
        }
      }
      &__img {
        img {
          width: 50%;
          &:nth-child(1) {
            padding-right: 10px;
            padding-bottom: 10px;
          }
          &:nth-child(2) {
            padding-left: 10px;
            padding-bottom: 10px;
          }
          &:nth-child(3) {
            padding-right: 10px;
            padding-top: 10px;
          }
          &:nth-child(4) {
            padding-left: 10px;
            padding-top: 10px;
          }
        }
      }
    }
  }
  &--type-csd-14 {
    //padding-bottom: rem(20);
  }

  @include media-max(xl) {
    &--type-csd-3 {
      padding-top: rem(100);
      padding-bottom: rem(100);
      .portfolio-item-section {
        &__col-with-description {
          margin-bottom: rem(90);
        }
        &__item-left {
          width: 25%;
          margin-left: 21.4%;
        }
        &__item-right {
          width: 25%;
          margin-left: 15.2%;
        }
      }
    }
    &--type-csd-4 {
      padding-top: rem(100);
      padding-bottom: rem(100);
      .portfolio-item-section {
        &__description {
          margin-bottom: rem(70);
        }
      }
    }
    &--type-csd-6 {
      padding-top: calc(280px + 45%);
      padding-bottom: rem(120);
      .portfolio-item-section {
        &__item--right {
          margin-bottom: rem(150);
        }
      }
    }
    &--type-csd-12 {
      padding-bottom: rem(150);
      padding-top: rem(150);
      .portfolio-item-section {
        &__item {
          &--top {
            margin-bottom: rem(200);
          }
          &--bottom {
            .portfolio-item-section {
              &__description {
                margin-bottom: rem(100);
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    &--type-csd-14 {
      padding-bottom: rem(150);
    }
  }

  @include media-max(md) {
    &--type-csd-3 {
      padding-top: rem(60);
      padding-bottom: rem(60);
      .portfolio-item-section {
        &__item-sm {
          justify-content: space-evenly;
        }
        &__col-with-description {
          margin-bottom: rem(50);
          .col-md-2 {
            margin-bottom: 10px;
          }
        }
        &__item-left {
          width: 42%;
          margin-left: 0;
        }
        &__item-right {
          width: 42%;
          margin-left: 0;
        }
      }
    }
    &--type-csd-4 {
      padding-top: rem(80);
      padding-bottom: rem(80);
      .portfolio-item-section {
        &__description {
          margin-bottom: rem(50);
        }
      }
    }
    &--type-csd-6 {
      padding-top: rem(100);
      padding-bottom: rem(60);
      .portfolio-item-section {
        &__item--right {
          margin-bottom: rem(120);
          .item--right__description{
            margin-bottom: 50px;
          }
        }
      }
    }
    &--type-csd-7 {
      padding-bottom: rem(165);
      .portfolio-item-section {
        &__right-side {
          &--gif {
            padding-top: rem(100);
            padding-bottom: rem(100);
            width: 100%;
          }
          &--img {
            width: 83%;
            margin-left: auto;
          }
        }
      }
    }
    &--type-csd-12 {
      padding-bottom: rem(100);
      padding-top: rem(100);
      .portfolio-item-section {
        &__item {
          &--top {
            margin-bottom: rem(80);
          }
        }
      }
    }
    &--type-csd-14 {
      padding-bottom: rem(100);
    }
  }
  @media (max-width: 414px){
    &--type-csd-3 {
      padding-top: rem(50);
      padding-bottom: rem(80);
      .portfolio-item-section {
        &__item-sm {
          flex-direction: column;
          align-items: center;
        }
        &__col-with-description {
          margin-bottom: rem(40);
        }
        &__item-left {
          width: 75%;
          margin-bottom: rem(120);
        }
        &__item-right {
          width: 75%;
          margin-left: 0;
        }
      }
    }
  }
  @include media-max(sm) {
    &--type-csd-6 {
      .portfolio-item-section {
        &__item--right {
          margin-bottom: rem(120);
        }
      }
    }
    &--type-csd-12 {
      .portfolio-item-section {
        &__item {
          &--top {
            margin-bottom: rem(130);
          }
        }
        &__img {
          img {
            &:nth-child(1) {
              padding-right: 5px;
              padding-bottom: 5px;
            }
            &:nth-child(2) {
              padding-left: 5px;
              padding-bottom: 5px;
            }
            &:nth-child(3) {
              padding-right: 5px;
              padding-top: 5px;
            }
            &:nth-child(4) {
              padding-left: 5px;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}