.transition-overlay {
  position: fixed;

  opacity: 0;

  top: 0;

  width: 100vw;
  height: 100vh;

  overflow: hidden;
  z-index: -1;

  &__item {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // transform: translateY(101%);
  }

  &__item-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    // transform: translate(-101%);
    img{
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
