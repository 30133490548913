@import "/src/scss/mixins.scss";

.view-developer-sites-our-experience-section {
  padding: 185px 0;

  @include media-max(md) {
    padding: 125px 0;
  }

  @include media-max(sm) {
    padding: 100px 0;
  }

  &__body {
    margin: 152px 0 110px 0;

    @include media-max(md) {
      gap: 65px;
      margin: 123px 0 120px 0;
    }

    @include media-max(sm) {
      margin: 45px 0 65px 0;
    }
  }

  &__job-types-list {
    flex-direction: column;
  }

  &__caption-list-title {
    margin-bottom: 12px;
  }

  &__footer {
    img {
      height: 70px;
      width: auto;

      @include media-max(md) {
        height: 50px;
      }

      @include media-max(sm) {
        height: 40px;
      }
    }

    .text {
      white-space: pre-line;
      width: rem(100px);

      @include media-max(lg) {
        width: auto;
      }

      div {
        height: 100%;
        display: flex;
      }

      p {
        margin: auto 0;
      }

      @include media-max(md) {
        white-space: initial;
      }
    }
  }

  &__awards-container {
    gap: rem(10px);

    @include media-max(lg) {
      flex-direction: column;
    }

    >p {
      margin: auto 0;
    }
  }

  &__footer-items {
    gap: 30px;

    @include media-max(lg) {
      gap: 10px;
    }
  }
}