.view-portfolio-woman-sport-club-cards-section {
  &__header {
    margin-bottom: rem(120px);
  }

  &__container {
    padding-top: rem(120px);
    padding-bottom: rem(100px);
  }

  &__cards-image {
    max-width: rem(1440px);
    margin-left: auto;
  }

  @include media-min(xxl) {
    &__container {
      padding-top: rem(220px);
      padding-bottom: rem(150px);
    }
  }
}
