.distortion-image {
  width: 100%;
  height: 100%;
  position: relative;

  &__img {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    z-index: 0;

    &--displacement {
      display: none;
    }
  }

  &__canvas {
    position: relative;

    display: block;

    width: 100%;
    height: 100%;
  }
}
