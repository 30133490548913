.view-portfolio-atlantic-specialist-card-section {
  &__header {
    margin-bottom: rem(115px);
  }

  &__header-list {
    margin-top: 35px;
    // align-self: flex-end;
  }

  @include media-min(md) {
    &__header {
      justify-content: center;
    }
  }

  @include media-min(xxl) {
    &__header {
      justify-content: flex-start;
    }
  }

  @include media-max(md) {
    &__col-with-card {
      flex-basis: percentage(1 / 3);
    }
  }
}
