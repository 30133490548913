@mixin gap($size: 30, $dir: "") {
  $half-gap-size: $size / 2;

  @if $dir == "h" {
    margin-left: -$half-gap-size;
    margin-right: -$half-gap-size;
  
    & > * {
      padding-left: $half-gap-size;
      padding-right: $half-gap-size;
    }
  } @else if $dir == "v" {
    margin-top: -$half-gap-size;
    margin-bottom: -$half-gap-size;

    & > * {
      padding-top: $half-gap-size;
      padding-bottom: $half-gap-size;
    }
  } @else {
    margin: -$half-gap-size;

    & > * {
      padding:  $half-gap-size;
    }
  }
}