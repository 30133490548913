.view-portfolio-doma-veka-equipment-card-section {
  &__col-with-cation {
    display: flex;
    align-items: center;

    margin-top: rem(30px);
  }

  &__caption-item {
    &--first {
      margin-bottom: 1em;
    }
  }

  @include media-min(md) {
    &__col-with-cation {
      margin-top: rem(60px);
      align-items: flex-end;
    }
  }

  @include media-min(lg) {
    &__caption-item {
      margin-top: 0;

      &--first {
        margin-bottom: 0;
        text-align: right;
      }

      &--second {
        margin-bottom: rem(75px);
      }
    }

    &__col-with-cation {
      align-items: center;
    }
  }
}
