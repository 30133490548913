.portfolio-item-section--type-csd-13 {
  padding-top: rem(40);
  padding-bottom: rem(300);
  flex-wrap: nowrap;
  .view-portfolio-csd-gallery-section {
    &__group-image {
      &--col-1 {
        flex-wrap: nowrap;
        & > div {
          &:nth-child(2) {
            margin-right: rem(16.28);
            width: 31.5%;
          }
          &:nth-child(3) {
            width: 7.8%;
            margin-right: rem(16.28);
          }
          &:nth-child(4) {
            margin-right: rem(16.28);
            width: 12%;
          }
        }
      }
      &--col-2 {
        flex-wrap: nowrap;
        & > div {
          &:nth-child(1) {
            margin-right: rem(16.28);
            width: 38.8%;
          }
          &:nth-child(2) {
            margin-right: rem(16.28);
            width: 5.5%;
          }
          &:nth-child(3) {
            margin-right: rem(16.28);
            width: 43.4%;
          }
          &:nth-child(4) {
            width: 6.3%;
          }
        }
      }
    }
    &__first-group-image-col {
      width: 52.6%;
      margin-right: rem(16.94);
      img {
        &:nth-child(1) {
          margin-bottom: rem(16.28);
        }
      }
    }
  }
  @include media-max(xl) {
    padding-bottom: rem(150);
    padding-top: rem(150);
    flex-direction: column;
    flex-wrap: wrap;
    .view-portfolio-csd-gallery-section {
      &__group-image {
        &--col-1 {
          margin-bottom: 70px;
          div {
            &:nth-child(2) {
              width: 21.5%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              margin-right: rem(0);
              width: 12%;
            }
          }
        }
      }
    }
  }
  @include media-max(md) {
    padding-bottom: rem(100);
    padding-top: rem(100);
  }
}