.view-portfolio-item-lead-section.cuprum {
  .svg-title {
    height: 30vw;

    @include media-min(md) {
      height: 20vw;
    }

    @include media-min(xl) {
      height: 240px;
    }
  }
}

.view-portfolio-item-about-section--type-cuprum {
  padding-top: rem(160);
  padding-bottom: rem(294);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 52%;
  }
}

.portfolio-item-section {

  &--type-cuprum-6 {
    padding-top: rem(206);
    padding-bottom: rem(315);

    .description {
      margin-bottom: rem(248);
    }

    @include media-max(md) {
      padding-top: rem(80);
      padding-bottom: rem(80);

      .description {
        margin-bottom: rem(50);
      }
    }

    img {
      background-color: #050505;
    }
  }

  &--type-cuprum-7 {
    padding-top: rem(270);

    .col-9 {
      margin-bottom: rem(280);
      img {
        background-color: #050505;
      }
    }

    @include media-max(md) {
      padding-top: rem(100);

      .col-9 {
        margin-bottom: rem(100);
      }
    }
  }

  &--type-cuprum-9 {
    padding-top: rem(205);
    padding-bottom: rem(318);

    .description {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: rem(354);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    img {
      background-color: #050505;
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);

      .description {
        margin-bottom: rem(50);
      }
    }

    @media (max-width: 500px) {
      .description {
        flex-direction: column;
        gap: rem(15);

        .list {
          margin-left: 20%;
          white-space: normal;
          span:not(:last-child) {
            margin-right: rem(10);
          }
        }
      }
    }
  }

  &--type-cuprum-12 {
    .u-ratio {
      //
    }
  }
}