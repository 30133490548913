.ticker {
  $ticker: &;

  position: relative;
  overflow: hidden;

  &__inner {
    position: relative;
    white-space: nowrap;

    will-change: transform;
    backface-visibility: hidden;

    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &__inner,
  &__text,
  &__text-item {
    display: inline-block;
  }

  &__text {
    &--duplicate {
      position: absolute;

      top: 0;
      right: -100%;
    }
  }

  // &:not(.is-active) &__inner {
  //   animation-duration: 0s;
  // }

  &--from-right-to-left &__inner {
    animation-name: ticker-from-right-to-left;
  }

  @keyframes ticker-from-right-to-left {
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }
}
