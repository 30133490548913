.view-portfolio-doma-veka-gallery-section {
  $this_padding-y: rem(45);
  $this_padding-y_min-md: rem(75);
  $this_padding-y_min-xxl: rem(315px);

  &__item:not(:last-child) {
    margin-bottom: $this_padding-y;
  }

  @include media-min(md) {
    &__item:not(:last-child) {
      margin-bottom: $this_padding-y_min-md;
    }
  }

  @include media-min(xxl) {
    &__item:not(:last-child) {
      margin-bottom: $this_padding-y_min-xxl;
    }
  }
}
