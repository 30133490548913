.header {
  @import "./vars";

  position: fixed;

  left: 0;
  top: 0;
  right: 0;

  display: flex;
  align-items: flex-end;

  height: $header_height;

  z-index: $header_z-index;

  mix-blend-mode: difference;
  color: $header_color;

  pointer-events: none;

  &__logo,
  &__menu-toggle,
  &__language-switcher {
    pointer-events: auto;
  }

  &__container {
    padding-bottom: $header-container_padding-bottom;
  }

  &__col-with-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__telegram {
    display: flex;
    align-items: center;
    margin-right: rem(25px);
    text-decoration: none;
    pointer-events: auto;

    img {
      width: 18px;
      margin-right: 0;
    }

    p {
      display: none;
      color: #d4d5d3;

      span{
        display: none;
      }
    }
  }

  &__menu-toggle-container {
    //min-width: $header-menu-toggle-container_min-width;
    display: flex;
    justify-content: flex-end;
    margin-left: rem(10px);
  }

  &__logo {
    vertical-align: bottom;
  }

  &__inner {
    align-items: first baseline;
  }

  @include media-min(xs) {
    &__telegram {
      img {
        display: block;
      }
    }
  }

  @include media-min(sm) {
    &__telegram {
      img {
        display: none;
        margin-right: 8px;
      }
      p {
        display: block;
      }
    }
  }

  @include media-min(md) {
    height: $header_height_min-md;

    &__container {
      padding-bottom: $header-container_padding-bottom_min-md;
    }

    &__telegram {
      p span{
        display: inline;
      }
    }
  }

  @include media-min(xl) {
    height: $header_height_min-xl;

    &__container {
      padding-bottom: $header-container_padding-bottom_min-xxl;
    }

    &__telegram {
      img {
        display: block;
      }
    }
  }
}
