.view-home-portfolio-section {
  @import "./vars";

  position: relative;
  overflow: hidden;

  &__container {
    padding-top: $home-our-work-section-container_padding-top;
    padding-bottom: $home-our-work-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $home-our-work-section-header_margin-bottom;
  }

  &__col-with-heading {
    margin-bottom: $home-our-work-section-col-with-heading_margin-bottom;
  }

  &__portfolio-item:not(:last-child) {
    margin-bottom: $home-our-work-section-portfolio-item-not-last_margin-bottom;
  }

  &__portfolio-item {
    .portfolio-item {
      &__name {
        font-size: $home-our-work-section-portfolio-item-name_font-size;
      }
      &.no-padding{
        .portfolio-item__preview::before {
          padding-top: 50%;
        }
      }
    }
  }

  @include media-max(md) {
    &__heading {
      font-size: $home-our-work-section-heading_font-size_max-md;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $home-our-work-section-container_padding-top_min-md;
      padding-bottom: $home-our-work-section-container_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $home-our-work-section-header_margin-bottom_min-md;
    }

    &__col-with-heading {
      margin-bottom: $home-our-work-section-col-with-heading_margin-bottom_min-md;
    }

    &__portfolio-item:not(:last-child) {
      margin-bottom: $home-our-work-section-portfolio-item-not-last_margin-bottom_min-md;
    }

    &__portfolio-item {
      .portfolio-item {
        &:not(.no-padding){
          .portfolio-item__preview--video .portfolio-item__preview-inner {
            padding-left: $portfolio-item-preview-video-inner_padding-x_min-md;
            padding-right: $portfolio-item-preview-video-inner_padding-x_min-md;
          }
        }
      }
    }
  }

  @include media-min(xl) {
    &__header {
      margin-bottom: $home-our-work-section-header_margin-bottom_min-xxl;
    }

    &__col-with-heading {
      margin-bottom: $home-our-work-section-col-with-heading_margin-bottom_min-xxl;
    }

    &__portfolio-item:not(:last-child) {
      margin-bottom: $home-our-work-section-portfolio-item-not-last_margin-bottom_min-xxl;
    }

    &__portfolio-item {
      .portfolio-item {
        &__caption {
          margin-top: $home-our-work-section-portfolio-item-caption_margin-top_min-xxl;
        }
        &:not(.no-padding){
          .portfolio-item__preview--video .portfolio-item__preview-inner {
            padding-left: $portfolio-item-preview-video-inner_padding-x_min-xxl;
            padding-right: $portfolio-item-preview-video-inner_padding-x_min-xxl;
          }
        }
      }
    }
  }
}
