.vacancy-lead-section {
  @import "./vars";

  height: 100vh;
  min-height: $mobile-min-height;

  &__container {
    height: 100%;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: $vacancy-lead-section-header_margin-bottom;
  }

  &__body {
    flex: 1 1 auto;
    align-content: space-between;
    align-items: flex-end;
  }

  &__col-with-description {
    display: none;
  }

  &__header-col-with-email-link {
    display: none;
  }

  &__arrow {
    font-size: $vacancy-lead-section-arrow_font-size;
  }

  @include media-max(md) {
    &__title {
      font-size: $vacancy-lead-section-title_font-size_max-md;
    }
  }

  @include media-min(md) {
    &__header {
      margin-bottom: $vacancy-lead-section-header_margin-bottom_min-md;
    }

    &__col-with-title {
      margin-bottom: $vacancy-lead-section-col-with-title_margin-bottom_min-md;
    }

    &__header-col-with-email-link {
      display: flex;
      align-items: flex-end;
    }

    &__body-col-with-email-link {
      display: none;
    }

    &__col-with-description {
      display: block;
    }

    &__arrow {
      font-size: $vacancy-lead-section-arrow_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__header {
      margin-bottom: $vacancy-lead-section-header_margin-bottom_min-xl;
    }

    &__col-with-title {
      margin-bottom: $vacancy-lead-section-col-with-title_margin-bottom_min-xl;
    }

    &__header-col-with-email-link {
      justify-content: flex-end;
      // padding: $vacancy-lead-section-header-col-with-email-link_padding-bottom_min-xl;
    }

    &__body {
      align-content: flex-end;
    }

    &__col-with-arrow {
      display: flex;
      justify-content: flex-end;
    }

    &__looking-for .list .list__item {
      margin-bottom: 0;
    }
  }
}
