.wrapper {
  @import "../body/vars";

  // &--scroll-top-on-refresh {
  //   height: 100vh;
  //   overflow: hidden;
  // }

  background-color: $body_background-color;
}
