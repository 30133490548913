@import '/src/scss/mixins.scss';

.portfolio-lead-section {
  @import "./vars";

  height: 100vh;
  min-height: $portfolio-lead-section_min-height;

  &__container {
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    height: 100%;
  }

  &__header {
    align-items: flex-end;
    justify-content: space-between;

    margin-bottom: $portfolio-lead-section-header_margin-bottom;
  }

  &__body {
    flex: 1 1 auto;
  }

  &__col-with-label {
    text-align: right;
  }

  &__col-with-title {
    margin-bottom: $portfolio-lead-section-col-with-display_margin-bottom;
  }

  &__col-with-stats {
    display: flex;
    justify-content: space-between;
  }

  &__stat {
    display: flex;
    align-items: flex-end;
  }

  &__stat-counter {
    font-size: $portfolio-lead-section-stat-counter_font-size;
    margin-right: $portfolio-lead-section-stat-counter_margin-right;
  }

  &__stat-label {
    margin-bottom: $portfolio-lead-section-stat-label_margin-bottom;
    font-size: $portfolio-lead-section-stat-label_font-size;
  }

  &__col-with-arrow {
    display: none;
  }

  &__arrow {
    display: block;

    font-size: $portfolio-lead-section-arrow_font-size;
  }

  &__stat:not(:last-child) {
    margin-right: $portfolio-lead-section-stat-not-last_margin-right;
  }

  @include media-max(md) {
    &__title {
      font-size: $portfolio-lead-section-title_font-size_max-md;
    }
  }

  @include media-min(sm) {
    &__col-with-stats {
      justify-content: unset;
    }

    &__stat-counter {
      font-size: $portfolio-lead-section-stat-counter_font-size_min-sm;
    }

    &__stat-label {
      font-size: $portfolio-lead-section-stat-label_font-size_min-sm;
    }

    &__stat:not(:last-child) {
      margin-right: $portfolio-lead-section-stat-not-last_margin-right_min-sm;
    }
  }

  @include media-min(576px) {
    &__col-with-stats-and-arrow {
      justify-content: space-between;
      align-items: flex-end;
    }

    &__col-with-arrow {
      display: block;
    }
  }

  @include media-min(md) {
    &__header {
      margin-bottom: $portfolio-lead-section-header_margin-bottom_min-md;
    }

    &__col-with-title {
      margin-bottom: $portfolio-lead-section-col-with-display_margin-bottom_min-md;
    }

    &__stat:not(:last-child) {
      margin-right: $portfolio-lead-section-stat-not-last_margin-right_min-md;
    }
  }

  @include media-min(xl) {
    &__header {
      padding-bottom: $portfolio-lead-section-header_margin-bottom_min-xl;
    }

    &__body {
      align-items: flex-end;
      justify-content: space-between;
    }

    &__stat-counter {
      font-size: $portfolio-lead-section-stat-counter_font-size_min-xl;
      margin-right: $portfolio-lead-section-stat-counter_margin-right_min-xl;
    }

    &__col-with-arrow {
      display: none;
    }

    &__stat:not(:last-child) {
      margin-right: $portfolio-lead-section-stat-not-last_margin-right_min-xl;
    }
  }
}
