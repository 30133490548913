.view-vacancy-footer {
  &__content-label {
    margin-bottom: rem(15px);
    padding-left: 0.25rem;
  }

  &__header {
    margin-bottom: 10vh;
  }

  &__content-item-link,
  &__content-item-label {
    font-size: rem(25px);
    font-weight: $font-weight-regular;
    text-transform: lowercase;
    // font-weight: $font-weight-medium;
  }

  @include media-min(md) {
    &__content-item-link,
    &__content-item-label {
      font-size: rem(35px);
    }
  }

  @include media-min(lg) {
    &__header {
      margin-bottom: 0;
    }
  }
}
