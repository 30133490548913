@import "./view-portfolio-ensemble-6";
@import "./view-portfolio-ensemble-7";
@import "./view-portfolio-ensemble-11";

.portfolio-item-section{
  &--type-ens-2{
    padding-top: 40px;
    padding-bottom: 305px;
  }
  &--type-ens-4{
    padding-top: 300px;
    .portfolio-item-section__inner{
      & > div {
        margin-bottom: 300px;
      }
    }
  }
  &--type-ens-9{
    padding-bottom: 100px;
    .top-video{
      padding-top: 47.1%;
    }
    .bottom-video{
      padding-top: 65.9%;
    }
  }
  @include media-max(xl) {
    &--type-ens-2{
      padding-top: 40px;
      padding-bottom: 200px;
    }
    &--type-ens-4{
      padding-top: 200px;
      .portfolio-item-section__inner{
        & > div {
          margin-bottom: 200px;
        }
      }
    }
  }
  @include media-max(md) {
    &--type-ens-2{
      padding-top: 0;
      padding-bottom: 100px;
    }
    &--type-ens-4{
      padding-top: 100px;
      .portfolio-item-section__inner{
        & > div {
          margin-bottom: 100px;
        }
      }
    }
  }
}