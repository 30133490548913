.vacancy-item {
  @import "./vars";
  @import "/src/scss/components/list/vars";

  position: relative;
  overflow: hidden;

  &:nth-child(even) {
    background-color: $vacancy-item-every-second_background-color;
  }

  &__container {
    padding-top: $vacancy-item_padding-top;
    padding-bottom: $vacancy-item_padding-bottom;
  }

  &__header {
    margin-bottom: $vacancy-item-header_margin-bottom;
  }

  &__body {
    align-items: flex-end;
  }

  &__title {
    padding-right: rem(15px);
    margin-bottom: $vacancy-item-title_margin-bottom;
    font-feature-settings: "cpsp" on, "ss02" on;
    text-transform: none;
  }

  &__skills {
    display: flex;
    flex-wrap: wrap;
  }

  &__skill:not(:last-child) {
    margin-right: $vacancy-item-skill-not-last_margin-right;
  }

  &__col-with-title-and-skills {
    margin-bottom: $vacancy-item-col-with-title-and-skills_margin-bottom;
  }

  &__col-with-experience-and-link {
    display: flex;
    justify-content: space-between;
  }

  &__col-with-description {
    margin-bottom: $vacancy-item-col-with-description_margin-bottom;
  }

  &__details-title {
    font-size: $vacancy-item-details-title_font-size;
  }

  &__more-btn {
    display: block;
    width: 100%;

    .button {
      &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__label {
        text-align: left;
      }

      &__right {
        margin-bottom: 0.2em;
        backface-visibility: hidden;
        transform-origin: center;
        transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
      }
    }

    .icon {
      &__svg path {
        transition: d 0.15s ease-in-out;
      }
    }

    &--is-active .button {
      &__right {
        // transform: rotate(90deg) translateZ(0);
      }
    }

    &--is-active .icon {
      &__svg > path {
        d: path(
          "M 39.7805 93.6 L 44.4605 93.6 L 44.4605 47.58 L 35.1005 47.58 L 44.4605 76.18 L 44.4605 0 L 35.1005 0 L 35.1005 76.18 L 44.4605 47.58 L 35.1005 47.58 L 35 93.6 L 39.7805 93.6 Z"
        );
      }
    }
  }

  &__more-btn-icon {
    font-size: $vacancy-item-more-btn-icon_font-size;
  }

  &__details-inner {
    position: relative;
  }

  &__details-container {
    padding-bottom: $vacancy-item-details-container_padding-bottom;
    padding-top: $vacancy-item-details-container_padding-top;
  }

  &__details-item {
    &:not(:last-child) {
      margin-bottom: rem(80px);
    }

    &--align-items {
      display: flex;
      align-items: center;
    }
  }

  &__details-item-label {
    margin-bottom: rem(30px);

    &--secondary {
      margin-bottom: rem(20px);
    }
  }

  &__details-item-sublabel {
    margin-top: rem(65px);
  }

  &__details-item-sublabel {
    margin-left: $list-marked-item_padding-left;
  }

  @include media-min(md) {
    &__container {
      padding-top: $vacancy-item_padding-top_min-md;
      padding-bottom: $vacancy-item_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $vacancy-item-header_margin-bottom_min-md;
    }

    &__col-with-title-and-skills {
      margin-bottom: $vacancy-item-col-with-title-and-skills_margin-bottom_min-md;
    }

    &__col-with-experience-and-link {
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding-top: $vacancy-item-col-with-experience-and-link_padding-top_min-md;
    }

    &__title {
      margin-bottom: $vacancy-item-title_margin-bottom_min-md;
    }

    &__col-with-description {
      margin-bottom: $vacancy-item-col-with-description_margin-bottom_min-md;
    }

    &__description {
      max-width: $vacancy-item-description_max-width_min-md;
      font-size: $vacancy-item-description_font-size_min-md;
    }

    &__details-container {
      padding-bottom: $vacancy-item-details-container_padding-bottom_min-md;
      padding-top: $vacancy-item-details-container_padding-top_min-md;
    }

    &__col-with-more-btn {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    &__more-btn {
      width: auto;

      .button {
        &__inner {
          justify-content: flex-end;
        }

        &__label {
          margin-right: $vacancy-item-more-btn-label_margin-right_min-md;
          white-space: pre-line;
        }
      }
    }

    &__details-item {
      &--header {
        top: -0.8rem;
      }
    }
    &__details-item-list {
      &--secondary .list__item {
        padding-left: rem(40px);
      }
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $vacancy-item_padding-top_min-xl;
      padding-bottom: $vacancy-item_padding-bottom_min-xl;
    }

    &__header {
      margin-bottom: $vacancy-item-header_margin-bottom_min-xl;
    }

    &__col-with-experience-and-link {
      flex-direction: row;
      align-items: flex-start;
    }

    &__description {
      max-width: $vacancy-item-description_max-width_min-xl;
      font-size: $vacancy-item-description_font-size_min-xl;
    }

    &__col-with-more-btn {
      justify-content: flex-start;
      align-items: center;
    }

    &__more-btn {
      &.is-active .button,
      &:hover .button {
        &__right {
          transform: rotate(-90deg) translate3d(0, rem(10px), 0);
        }
      }

      .button {
        &__label {
          margin-right: $vacancy-item-more-btn-label_margin-right_min-xl;
        }

        &__inner {
          align-items: center;
        }

        &__right {
          transform: rotate(-90deg) translateZ(0);
        }
      }

      &--is-active .button {
        &__right {
          // transform: rotate(0deg) translateZ(0);
        }
      }
    }
  }
}
