.sublabel {
  @import "./vars";

  font-family: $sublabel_font-family;
  font-size: $sublabel_font-size;
  text-transform: lowercase;

  &--secondary {
    color: $sublabel-secondary_color;
  }
}
