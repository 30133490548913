.view-home-lead-section {
  @import "./vars";

  $this: &;

  %display-flex {
    display: flex;
  }

  %content-full-height {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
  }

  min-height: $home-lead-section_min-height;
  //min-height: rem(820);
  // max-height: rem(780px);
  display: flex;
  flex-direction: column;

  overflow: hidden;
  z-index: unset;

  &__container {
    @extend %content-full-height;

    padding-bottom: $home-lead-section_padding-bottom;
  }

  &__inner {
    @extend %content-full-height;
  }

  &__main {
    @extend %content-full-height;
  }

  &__main-inner {
    flex: 1 1 100%;
    // align-content: flex-start;
    // align-content: space-between;
  }

  &__col-with-what-we-do {
    align-self: flex-start;
    // margin-bottom: vh(
    //   85 / 610
    // );
    //$home-lead-section-col-with-what-we-do_margin-bottom;
  }

  &__col-with-slogan {
    @extend %display-flex;
    align-items: flex-end;
    p{
      white-space: pre-wrap;
    }
  }

  &__col-with-arrow {
    @extend %display-flex;

    align-items: flex-end;
    justify-content: flex-end;
  }

  &__col-with-showreel {
    display: flex;
    // margin-bottom: vh(65 / 610);
  }

  &__showreel {
    font-size: $home-lead-section-showreel_font-size;
    text-transform: lowercase;

    &-icon {
      margin: 0 $home-lead-section-showreel-icon_margin-x;
      font-size: $home-lead-section-showreel-icon_size;
    }
  }

  &__arrow {
    font-size: $home-lead-section-arrow_size;
  }

  &__slider {
    flex: 0 0 auto;
    width: 100%;

    margin-top: $home-lead-section-slider_margin-top;
    margin-bottom: $home-lead-section-slider_margin-bottom;

    align-items: center;

    user-select: none;

    // padding-bottom: $home-lead-section-slider_padding-bottom;

    overflow: unset;
  }

  &__slider .swiper-wrapper {
    align-items: flex-end;
  }

  &__slider {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 0;
      height: 100%;

      width: 20%;
      z-index: 2;
    }

    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }
  }

  &__slide {
    display: flex;
    width: auto;
    align-items: flex-end;
    transform: translateZ(0);
    backface-visibility: hidden;
    // height: $home-lead-section-slide-svg_height;
  }

  &__slide-svg {
    display: block;
    width: auto;

    * {
      transition: 0.5s;
    }

    &--word {
      height: vw(75 / 414); // $home-lead-section-slide-svg_height;
    }

    &--dash {
      height: vw(5 / 414); //$home-lead-section-slide-svg-dash_height;
      margin-bottom: vw(
        30 / 414
      ); //$home-lead-section-slide-svg-dash_margin-bottom;
    }

    // &--branding {
    //   height: $home-lead-section-slide-svg-branding_height;
    //   transform: translateY($home-lead-section-slide-svg-branding_translate-y);
    // }
  }

  &__slide-col {
    &--dash {
      padding: 0 vw(30 / 414);
    }
  }

  &__do-list {
    font-size: rem(22px);
  }

  &__do-list-item {
    min-height: 1em;
  }

  // &__slider-nav-btn {
  //   position: absolute;
  //   top: 0;

  //   width: 20%;
  //   height: 100%;

  //   z-index: 1;

  //   cursor: none;

  //   &--next {
  //     right: 0;
  //   }

  //   &--prev {
  //     left: 0;
  //   }
  // }

  &__slide-col {
    &--dash {
      justify-content: center;
    }
  }

  // &__next-slide-btn {
  //   font-size: 2em;
  //   z-index: 1;
  //   cursor: none;
  //   opacity: 0;
  //   transition: opacity 0.4s;
  // }

  @include media-min(sm) {
    // &__slide-svg {
    //   &--dash {
    //     margin-left: $home-lead-section-slide-svg-dash_margin-x_min-sm;
    //     margin-right: $home-lead-section-slide-svg-dash_margin-x_min-sm;
    //   }
    // }
  }

  @include media-min(md) {
    // height: auto;
    min-height: rem(780);
    &__container {
      padding-bottom: $home-lead-section_padding-bottom_min-md;
    }

    &__slider {
      width: 100%;
      margin-top: $home-lead-section-slider_margin-top_min-md;
      margin-bottom: $home-lead-section-slider_margin-bottom_min-md;
    }

    &__col-with-what-we-do {
      //margin-top: auto;
      margin-bottom: $home-lead-section-col-with-what-we-do_margin-bottom_min-md;
    }

    &__col-with-showreel {
      // margin-bottom: $home-lead-section-col-with-showreel_margin-bottom_min-md;
    }

    &__col-with-slogan {
      //justify-content: flex-end;
      // margin-bottom: vh(165 / 780);
    }

    &__showreel {
      font-size: $home-lead-section-showreel_font-size_min-md;
    }

    &__arrow {
      font-size: $home-lead-section-arrow_size_min-md;
    }

    &__slide-svg {
      &--word {
        height: vw(120 / 768);
      }

      &--dash {
        height: vw(9 / 768);
        margin-bottom: vw(43 / 768);
      }
      // &--dash {
      //   margin-left: $home-lead-section-slide-svg-dash_margin-x_min-md;
      //   margin-right: $home-lead-section-slide-svg-dash_margin-x_min-md;
      // }
    }

    &__slide-col {
      &--dash {
        padding: 0 vw(95 / 768);
      }
    }
  }

  @include media-min(xl) {
    // height: auto;

    &__slider {
      width: 100%;
      margin-top: $home-lead-section-slider_margin-top_min-xxl;
      // margin-bottom: vh(115 / 780);
      margin-bottom: 0;
      // margin-bottom: $home-lead-section-slider_margin-bottom_min-xxl;
    }

    &__main-inner {
      align-content: flex-end;
    }

    &__col-with-showreel {
      font-size: $home-lead-section-showreel_font-size_min-xxl;
      align-items: flex-end;
      // margin-bottom: $home-lead-section-col-with-showreel_margin-bottom_min-xxl;
      // margin-bottom: vh(150 / 780);
    }

    &__col-with-slogan {
      align-items: flex-end;
      justify-content: flex-start;
      margin: 0;
    }

    &__showreel {
      font-size: $home-lead-section-showreel_font-size_min-xxl;
    }

    &__slide {
      &--with-dash {
        padding: 0;
      }
    }

    &__slide-svg {
      &--word {
        height: vw(180 / 1440);
      }

      &--dash {
        height: vw(13.5 / 1440);
        margin-bottom: vw(70 / 1440);
      }
    }

    &__slide-col {
      &--dash {
        padding: 0 vw(105 / 768);
      }
    }

    &__col-with-what-we-do-and-showreel {
      //height: 100%;
    }

    &__col-with-what-we-do {
      //margin-top: auto;
    }
  }
}

.view-home-lead-section__col-reward {
  justify-content: space-between;
  margin-top: rem(15);
  padding: rem(6) rem(60) rem(7);
  background-color: $color-shark-1;

  .item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: rem(15);
    line-height: 1.2;
    font-weight: 500;
    color: #fff;

    p {
      display: flex;
      align-items: center;
      margin-right: rem(30);

      svg {
        margin-right: rem(3);
      }
    }

    span {
      //
    }
  }
  @include media-max(xl) {
    margin-top: 0;
    padding: 0;
    background-color: transparent;

    .item {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: rem(15);
      color: $color-shark-1;
      max-width: rem(195);

      p {
        margin-right: 0;
      }
    }
  }

  @include media-max(md) {
    flex: inherit;
    margin-top: rem(40);
  }
}
