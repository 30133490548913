@import './vars';
.view-portfolio-domaveka-gallery-section {
  font-size: rem(14);
  line-height: 1;
  text-transform: lowercase;

  &__item {
    width: 50%;
    padding: rem(81) rem(60);
    border-top: rem(1) solid $view-portfolio-item_border-color;
    border-left: rem(1) solid $view-portfolio-item_border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:nth-last-of-type(even) {
      border-left: none;
    }
    &:nth-last-child(-n + 2) {
      padding-bottom: rem(163);
    }
    &:nth-child(-n + 2) {
      border-top: none;
      padding-top: rem(80);
    }
    &:nth-child(6) {
      .view-portfolio-domaveka-gallery-section__heading {
        width: 120px;
      }
      .view-portfolio-domaveka-gallery-section__content {
        position: relative;
        bottom: 13px;
      }
    }
    &:nth-child(7) {
      .view-portfolio-domaveka-gallery-section__heading {
        width: 120px;
      }
      .view-portfolio-domaveka-gallery-section__content {
        position: relative;
        left: 25px;
        bottom: 15px;
      }
    }
  }
  &__content {
    color: $view-portfolio-item_primary-text-color;
    padding-left: rem(60);
  }
  &__heading {
    color: $view-portfolio-item_tertiary-text-color;
  }

  @include media-max(md) {
    &__item {
      width: 100%;
      padding: rem(75) rem(20);
      border: none;
      border-top: rem(1) solid $view-portfolio-item_border-color;
      justify-content: flex-start;
      align-items: flex-start;

      &:nth-child(2) {
        border-top: rem(1) solid $view-portfolio-item_border-color;
      }

      &:nth-last-child(-n + 2) {
        padding-bottom: rem(75);
      }
      &:nth-child(5) {
        order: 3;
      }
      &:nth-child(6) {
        .view-portfolio-domaveka-gallery-section__content {
          position: static;
        }
        order: 2;
      }
      &:nth-child(7),
      &:nth-child(8) {
        order: 4;
      }
      &:nth-child(7) {
        .view-portfolio-domaveka-gallery-section__content {
          position: static;
        }
      }
    }
    &__content {
      padding-left: 0;
    }
  }
}
