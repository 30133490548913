@import './vars';
@import './view-portfolio-wildberries-container/view-portfolio-wildberries-container.scss';
@import './view-portfolio-wildberries-section-width/view-portfolio-wildberries-section-width.scss';

.portfolio-item-section {
  //экран3
  &--type-wildberries-3 {
    color: $view-portfolio-item_primary-text-color;
    padding-bottom: rem(295);
    padding-top: rem(159);

    .portfolio-item-section {
      display: grid;
      grid-template-columns: 1fr 54.6%;
      grid-template-rows: min-content 1fr;

      &__img-wraper {
        margin: auto;
        width: 70%;
      }

      &__main-img-wraper {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 2;
      }
      &__text {
        font-weight: 500;
        font-size: rem(14);
        line-height: 1.3;
        font-style: normal;
        position: relative;
        bottom: rem(4);

        &--ru {
          font-family: 'Neue Machina';
        }
        &--en {
          font-family: 'Trap';
        }
      }
    }
  }
  //экран4
  &--type-wildberries-4 {
    padding-bottom: rem(154);
    padding-top: rem(150);
    color: $view-portfolio-item_primary-text-color;

    .portfolio-item-section__text-container {
      justify-content: space-between;
      margin-bottom: rem(37);

      .portfolio-item-section__description {
        max-width: rem(650);
        width: 64%;
        font-style: normal;
        font-weight: 400;
        font-size: rem(20.025);
        line-height: 1;
        text-transform: lowercase;

        &--ru {
          font-family: 'Neue Machina';
        }
        &--en {
          font-family: 'Trap';
        }
      }

      .portfolio-item-section__subtitle {
        font-family: 'Trap';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-transform: lowercase;

        &--en {
          font-size: rem(14);
        }
        &--ru {
          font-size: rem(20);
        }
      }
    }
  }
  //экран5

  //экран6
  &--type-wildberries-6 {
    color: $view-portfolio-item_primary-text-color;

    .portfolio-item-section {
      &__text-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        z-index: 5;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: rem(21);
        line-height: 1.19;
        text-transform: uppercase;
      }
    }
  }
  //экран7
  &--type-wildberries-7 {
    position: relative;
    padding-bottom: rem(300);
    padding-top: rem(150);
    color: $view-portfolio-item_primary-text-color;

    .portfolio-item-section {
      &__item-left {
        width: 19.1%;
        margin-left: 16.2%;
      }
      &__item-right {
        width: 27.1%;
        margin-left: 23.4%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      &__subtitle {
        margin-bottom: rem(55);
        font-style: normal;
        font-weight: 500;
        font-size: rem(14);
        line-height: 1.3;

        &--en {
          font-family: 'Trap';
        }
        &--ru {
          font-family: 'Neue Machina';
        }
      }
      &__description {
        font-style: normal;
        max-width: rem(345);
        font-weight: 400;
        font-size: rem(16);
        line-height: 1;
        text-align: right;
        &--en {
          font-family: 'Inter';
        }
        &--ru {
          font-family: 'Neue Machina';
        }
      }
      &__image-container {
        position: relative;
        flex-grow: 1;
      }
      &__image-1 {
        position: absolute;
        width: 49%;
       bottom: 65%;
      }
      &__image-2 {
        position: absolute;
        width: 121%;
        bottom: 24%;
        left: rem(10);
      }
    }
  }

  //экран8
  &--type-wildberries-8 {
    color: $view-portfolio-item_primary-text-color;
    position: relative;

    .simpleParallax {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-left: 11%;
      padding-right: 11%;
    }

    .portfolio-item-section {
      &__content {
        padding-top: rem(150);
        padding-bottom: rem(300);

        font-style: normal;
        font-weight: 500;
        font-size: rem(14);
        line-height: 1.3;
        &--ru {
          font-family: 'Neue Machina';
        }
        &--en {
          font-family: 'Trap';
        }
      }

      &__text {
        width: 100%;
        text-align: right;
        color: $view-portfolio-item_secondary-text-color;
        margin-bottom: rem(200);
      }
      &__card-container {
        display: flex;
        margin: 0 auto;
        width: 86.7%;
        column-gap: 14%;
      }
      &__card-text {
        margin-top: rem(10);
      }
    }
  }

  //экран9
  &--type-wildberries-9 {
    .portfolio-item-section {
      &__video {
        width: 100%;

        &:nth-of-type(1) {
          aspect-ratio: 1.29/1;
        }
        &:nth-of-type(2) {
          aspect-ratio: 1.112/1;
        }
      }
    }
  }

  //экран10
  &--type-wildberries-10 {
    .portfolio-item-section {
      &__video {
        width: 100%;
        aspect-ratio: 1.2/1;
      }
    }
  }
  //экран11
  &--type-wildberries-11 {
    position: relative;

    .simpleParallax {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding-left: rem(30);
      padding-right: rem(30);
    }

    .portfolio-item-section {
      padding-top: rem(150);
      padding-bottom: rem(300);
      font-style: normal;
      font-weight: 500;
      font-size: rem(14);
      line-height: 1.3;
      color: $view-portfolio-item_primary-text-color;
      &--ru {
        font-family: 'Neue Machina';
      }
      &--en {
        font-family: 'Trap';
      }

      &__text {
        margin-bottom: rem(200);
        text-align: right;
      }
      &__card-container {
        width: 90.7%;
        margin: 0 auto;
        display: flex;
        column-gap: 6.3%;
      }
    }
  }
  //экран12
  &--type-wildberries-12 {
    .portfolio-item-section {
      &__video {
        aspect-ratio: 0.74/1;
      }
    }
  }

  //мобилка
  @include media-max(md) {
    //экран3
    &--type-wildberries-3 {
      padding-bottom: rem(150);
      padding-top: rem(150);

      .portfolio-item-section {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        &__text {
          position: static;
          margin-bottom: rem(98);
        }

        &__img-wraper {
          margin: unset;
          width: 100%;
        }

        &__main-img-wraper {
          margin-bottom: rem(221);
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: unset;
        }
      }
    }
    //экран4
    &--type-wildberries-4 {
      .portfolio-item-section {
        &__img-container {
          overflow-x: scroll;
          img {
            height: 65vh;
            max-width: unset;
          }
        }
      }
      .portfolio-item-section__text-container {
        justify-content: space-between;

        .portfolio-item-section__description {
          margin-bottom: rem(37);
          width: 100%;
          font-size: rem(16);
          text-align: center;
        }

        .portfolio-item-section__subtitle {
          width: 100%;
          text-align: center;
        }
      }
    }

    //экран6
    &--type-wildberries-6 {
      .portfolio-item-section {
        &__text-container {
          font-size: rem(16);
        }
      }
    }
    //экран7
    &--type-wildberries-7 {
      padding-bottom: rem(150);

      .portfolio-item-section {
        &__item-left {
          width: calc(100% - 114px);
          margin-left: unset;
          margin: 0 auto;
          order: 1;
        }
        &__item-right {
          width: calc(100% - 44px);
          margin-left: unset;
          margin: 0 auto;
          margin-bottom: rem(155);
        }
        &__image-container {
          margin-bottom: rem(175);
          overflow: hidden;
        }
        &__image-1 {
          position: static;
        }
        &__image-2 {
          position: static;
          transform: translate(-8%, 23px);
        }
        &__description {
          max-width: unset;
          text-align: center;
          font-family: 'Trap';
          font-size: rem(18);
        }
      }
    }
    //экран8
    &--type-wildberries-8 {
      .portfolio-item-section {
        &__content {
          padding-bottom: rem(150);
        }
        &__text {
          display: none;
        }
        &__card-container {
          column-gap: unset;
          flex-wrap: wrap;
          width: calc(100% - 10px);
          row-gap: rem(200);
        }
        &__card-text {
          margin-top: rem(14);
        }
      }
    }
    //экран11
    &--type-wildberries-11 {
      .portfolio-item-section {
        padding-bottom: rem(150);
        padding-top: rem(137);
        &__text {
          display: none;
        }
        &__card-container {
          column-gap: unset;
          flex-wrap: wrap;
          width: calc(100% - 10px);
          row-gap: rem(200);
        }
      }
    }
  }
}
