// col with portfolio item
$portfolio-showcase-section-col-with-portfolio-item_margin-bottom: rem(105px);
$portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-md: rem(
  120px
);
$portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-xl: rem(
  220px
);

$portfolio-showcase-section-col-with-portfolio-item-narrow_padding-bottom_min-xl: rem(
  45px
);

// portfolio item caption
$portfolio-showcase-section-portfolio-item-caption_margin-top_min-md: rem(15px);

// portfolio item preview
$portfolio-showcase-section-portfolio-item-preview_ratio_min-md: percentage(
  217px / 360px
);
$portfolio-showcase-section-portfolio-item-preview_ratio_min-xl: percentage(
  435px / 660px
);

// portfolio item preview
$portfolio-showcase-section-portfolio-item-preview-inner_padding-x_min-xl: 
// rem(
//   50px
// );
  14%;

// portfolio item
$portfolio-showcase-section-portfolio-item-preview-media_margin-y_min-xl: rem(
  50px
);

// container
$portfolio-showcase-section-container_padding-top: rem(120px);
$portfolio-showcase-section-container_padding-top_min-md: rem(165px);
$portfolio-showcase-section-container_padding-top_min-xl: rem(180px);

$portfolio-showcase-section-container_padding-bottom: (
  rem(185px) - $portfolio-showcase-section-col-with-portfolio-item_margin-bottom
);
$portfolio-showcase-section-container_padding-bottom_min-md: (
  rem(180px) -
    $portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-md
);
$portfolio-showcase-section-container_padding-bottom_min-xl: (
  rem(380px) -
    $portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-xl
);

// header
$portfolio-showcase-section-header_margin-bottom: rem(120px);
$portfolio-showcase-section-header_margin-bottom_min-md: rem(190px);
$portfolio-showcase-section-header_margin-bottom_min-xl: rem(200px);
