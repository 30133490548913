.view-portfolio-item-section {
  @import "../vars";

  $layout-gosapteka-theming-container_padding-top_min-lg: rem(250px);
  $layout-gosapteka-theming-container_padding-top_min-md: $layout-gosapteka-theming-container_padding-top_min-lg /
    $view-portfolio-item-section-size-decrease-ratio_min-md;
  $layout-gosapteka-theming-container_padding-top: $layout-gosapteka-theming-container_padding-top_min-lg /
    $view-portfolio-item-section-size-decrease-ratio;

  $layout-gosapteka-theming-container_padding-bottom_min-lg: rem(360px);
  $layout-gosapteka-theming-container_padding-bottom_min-md: $layout-gosapteka-theming-container_padding-bottom_min-lg /
    $view-portfolio-item-section-size-decrease-ratio_min-md;
  $layout-gosapteka-theming-container_padding-bottom: $layout-gosapteka-theming-container_padding-bottom_min-lg /
    $view-portfolio-item-section-size-decrease-ratio;

  /* pharmacy-list */
  $layout-gosapteka-pharmacy-list-container_padding-top_min-lg: rem(225px);
  $layout-gosapteka-pharmacy-list-container_padding-top_min-md: $layout-gosapteka-pharmacy-list-container_padding-top_min-lg /
    $view-portfolio-item-section-size-decrease-ratio_min-md;
  $layout-gosapteka-pharmacy-list-container_padding-top: $layout-gosapteka-pharmacy-list-container_padding-top_min-lg /
    $view-portfolio-item-section-size-decrease-ratio;

  &--layout-gosapteka-theming {
    transition: all 0.4s;
  }

  &--layout-gosapteka-theming & {
    &__inner {
      align-items: center;
    }

    &__container {
      padding-top: $layout-gosapteka-theming-container_padding-top;
      padding-bottom: $layout-gosapteka-theming-container_padding-bottom;
    }

    &__col-theme-switcher {
      display: flex;
      justify-content: center;
    }

    &__theme-switcher-wrapper {
      display: inline-flex;
      align-items: center;
      font-size: rem(16px);
      text-align: center;
    }

    &__col-theme-preview-image {
      height: vw(668 / 1440);
      min-height: 500px;
      margin-bottom: $layout-gosapteka-theming-container_padding-top / 2;
    }

    &__theme-preview-image {
      margin: 0 auto;
    }

    &__theme-switcher {
      margin: 0 rem(15px);
      vertical-align: middle;
    }

    &__changing-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;

      transition: all 0.4s;
      opacity: 0;

      &.is-active {
        opacity: 1;
      }
    }
  }

  /* manager-monitoring */
  &--layout-gosapteka-manager-monitoring & {
    &__col-image {
      margin-bottom: rem(60px);
    }
  }

  /* pharmacy-list */
  &--layout-gosapteka-pharmacy-list & {
    &__col-caption {
      margin-bottom: $layout-gosapteka-pharmacy-list-container_padding-top;
    }

    &__container {
      padding-top: $layout-gosapteka-pharmacy-list-container_padding-top;
    }
  }

  @include media-min(md) {
    &--layout-gosapteka-theming & {
      &__container {
        padding-top: $layout-gosapteka-theming-container_padding-top_min-md;
        padding-bottom: $layout-gosapteka-theming-container_padding-bottom_min-md;
      }
    }

    /* manager-monitoring */
    &--layout-gosapteka-manager-monitoring & {
      &__description {
        margin: 0 auto;
      }
    }

    /* pharmacy-list */
    &--layout-gosapteka-pharmacy-list & {
      &__container {
        padding-top: $layout-gosapteka-pharmacy-list-container_padding-top_min-md;
      }
    }
  }

  @include media-min(lg) {
    &--layout-gosapteka-theming & {
      &__container {
        padding-top: $layout-gosapteka-theming-container_padding-top_min-lg;
        padding-bottom: $layout-gosapteka-theming-container_padding-bottom_min-lg;
      }

      &__theme-switcher-wrapper {
        text-align: left;
        font-size: inherit;
      }

      &__theme-switcher {
        margin: 0 rem(30px);
      }

      &__col-theme-switcher {
        justify-content: flex-start;
      }

      &__col-theme-preview-image {
        margin-bottom: 0;
      }
    }

    /* manager-monitoring */
    &--layout-gosapteka-manager-monitoring & {
      &__description {
        margin: 0;
      }
    }

    /* pharmacy-list */
    &--layout-gosapteka-pharmacy-list & {
      &__caption {
        margin-top: rem(165px);
      }

      &__container {
        padding-top: $layout-gosapteka-pharmacy-list-container_padding-top_min-lg;
      }
    }
  }
}
