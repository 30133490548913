.view-portfolio-item-lead-section {
  @import "./vars";

  &__header-col-with-year {
    display: none;
  }

  &__header {
    margin-bottom: $view-portfolio-item-lead-section_header_margin-bottom;
    .awards{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 12px -5px 0;
      .awards-item{
        padding: 0 5px 5px;
        img{
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__body-col-with-links {
    display: flex;
    flex-direction: column;
  }

  &__body-top-col-with-works-done-label {
    margin-bottom: $view-portfolio-item-body-top-col-with-works-done-label_margin-bottom;
  }

  &__body-top-col-with-behance-link {
    .label-vc {
      display: flex;
      margin-top: 20px;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      .label{
        margin-bottom: 4px;
      }
    }
  }

  &__body-top-col-with-site-link {
    .label{
      display: block;
      width: max-content;
    }
    .label-vc {
      display: none;
      margin-top: 15px;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      .label{
        margin-bottom: 4px;
      }
    }
  }

  &__body-bottom {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__body-bottom-inner {
    // align-content: space-between;
    height: 100%;
  }

  &__body-bottom-col-with-behance-link {
    display: none;
  }

  &__body-bottom-col-with-company-name,
  &__body-bottom-col-with-arrow {
    display: flex;
    align-items: flex-end;
  }

  &__body-bottom-col-with-arrow {
    justify-content: flex-end;
  }

  &__body-bottom-col-with-year {
    display: flex;
    align-items: center;
  }

  @include media-min(md) {
    &__header {
      margin-bottom: $view-portfolio-item-lead-section-header_margin-bottom_min-md;
      .awards{
        margin-top: 30px;
        .awards-item{
          img{
            width: 70px;
            height: 70px;
          }
        }
      }
    }

    &__body-top {
      // margin-bottom: rem(160px);

      &-col-with-behance-link {
        display: none;
      }

      &-col-with-works-done-label {
        margin-bottom: 0;
      }
    }

    &__body-bottom {
      &-col-with-behance-link {
        display: initial;
      }

      &-col-with-year {
        align-items: flex-end;
      }

      &-col-with-arrow {
        margin-left: auto;
      }

      &-col-with-behance-link {
        display: flex;
        align-items: flex-end;
      }
    }

    &__body-top-col-with-site-link {
      .label-vc {
        display: flex;
      }
    }
  }

  @include media-min(xl) {
    &__header {
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: $view-portfolio-item-lead-section-header_margin-bottom_min-xl;
      .awards{
        margin-top: 20px;
      }
    }

    &__header-col-with-year {
      display: initial;
    }

    &__body-bottom {
      &-inner {
        align-items: flex-end;
      }

      &-col-with-year {
        display: none;
      }
    }
  }
}
