.collapse {
  &--collapsing,
  &--expanding {
    // height: 0;

    overflow: hidden;

    transition: height 0.4s ease;
  }

  &--collapsed &__inner {
    display: none;
  }
}
