.list {
  @import "./vars";

  $this: &;

  margin: 0;
  padding: 0;
  list-style: none;

  &--marked &__item {
    position: relative;
    padding-left: $list-marked-item_padding-left;

    &:not(.list__item--empty):before {
      content: "+";

      position: absolute;

      left: 0;
      // top: 3px;
    }

    &.job-list-item {

      &.list__item--empty {
        margin-bottom: 35px !important;
      }
    }
  }

  @each $gap-name,
  $gap-value in $list-gaps {
    &--gap-#{$gap-name}:not(#{$this}--horizontal)>&__item:not(:last-child) {
      margin-bottom: $gap-value;
    }
  }

  &--horizontal {
    display: flex;

    @each $gap-name,
    $gap-value in $list-gaps {
      &#{$this}--gap-#{$gap-name}>#{$this}__item:not(:last-child) {
        margin-right: $gap-value;
      }
    }
  }
}