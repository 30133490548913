.view-portfolio-item-about-section--type-cryptoro {
  padding-top: rem(160);
  padding-bottom: rem(340);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__middle-image {
    background-color: #fff;
  }
}

.portfolio-item-section {
  &--type-cryptoro-3 {
    padding-top: rem(195);
    padding-bottom: rem(150);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 35%;
        white-space: nowrap;

        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    @include media-max(md) {
      padding-top: rem(80);
      padding-bottom: rem(80);

      .description {
        margin-bottom: rem(50);

        .list {
          span:not(:last-child) {
            margin-right: rem(18);
          }
        }
      }
    }
  }

  &--type-cryptoro-4 {
    padding-top: rem(150);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 35%;
        white-space: nowrap;

        span:not(:last-child) {
          margin-right: rem(22);
        }
      }
    }

    @include media-max(md) {
      padding-top: rem(80);

      .description {
        margin-bottom: rem(50);

        .list {
          span:not(:last-child) {
            margin-right: rem(18);
          }
        }
      }
    }
  }

  &--type-cryptoro-6 {
    padding-top: rem(192);
    padding-bottom: rem(140);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
      }
    }

    @include media-max(md) {
      padding-top: rem(80);
      padding-bottom: rem(80);

      .description {
        margin-bottom: rem(50);
      }
    }
  }

  &--type-cryptoro-8 {
    padding-top: rem(200);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(226);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
      }
    }

    @include media-max(md) {
      padding-top: rem(80);

      .description {
        margin-bottom: rem(40);
      }
    }
  }
}