.portfolio-item-section--type-csd-9 {
  padding-top: rem(465);
  padding-bottom: rem(211);
  .portfolio-item-section {
    &__figure-caption-md{
      display: none;
    }
    &__inner-top {
      margin-bottom: rem(203);
      &--img {
        order: 1;
        img {
          width: 50%;
          &:nth-child(1) {
            padding-right: 12px;
            padding-bottom: 12px;
          }
          &:nth-child(2) {
            padding-left: 12px;
            padding-bottom: 12px;
          }
          &:nth-child(3) {
            padding-right: 12px;
            padding-top: 12px;
          }
          &:nth-child(4) {
            padding-left: 12px;
            padding-top: 12px;
          }
        }
      }
      &--gif {
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .view-portfolio-csd-9 {
          &__block {
            max-width: 360px;
          }
        }
      }
      .figure-caption-item {
        &__text--bottom {
          margin-left: rem(120);
          white-space: nowrap;
        }
      }
    }
        &__inner-bottom {
          &--left {
          order: 1;
          padding-top: rem(400);
          .portfolio-item-section {
            &__arrow {
              margin-bottom: rem(196);
              svg {
              fill: #fff;
            }
          }
        }
      }
          &--right {
          order: 2;
          .portfolio-item-section {
            &__description {
              margin-bottom: rem(38);
              justify-content: space-between;
            }
          }
        }
      }
    }
  @include media-max(xl) {
    padding-top: rem(400);
    padding-bottom: rem(200);
    .portfolio-item-section {
      &__inner-top {
        &__figure-caption {
          display: flex;
          justify-content: center;
        }
    .view-portfolio-csd-9 {
          &__block {
          margin-left: auto;
          }
        }
      }
          &__inner-bottom {
          flex-direction: column;
            &--left {
            order: 2;
            padding-top: rem(220);
            .portfolio-item-section {
              &__arrow {
                margin-bottom: rem(196);
                padding-left: 0;
              }
            }
          }
          &--right {
          order: 1;
          .portfolio-item-section {
            &__description {
              margin-bottom: rem(38);
              justify-content: space-between;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  @include media-max(md) {
    padding-top: rem(160);
    padding-bottom: rem(100);
    .portfolio-item-section {
      flex-direction: column;
      &__figure-caption-md{
        display: block;
        margin-bottom: rem(70);
      }
      &__inner-top {
        margin-bottom: rem(80);
        &--img {
          order: 2;
        }
        &--gif {
          order: 1;
          padding-bottom: rem(50);
          padding-top: rem(90);
          flex-direction: column;
          padding-bottom: rem(50);
          .portfolio-item-section {
            &__figure-caption {
              display: none;
            }
          }
          .view-portfolio-csd-9 {
            &__block {
              margin-left: auto;
              max-width: 360px;
            }
          }
        }
      }
      &__inner-bottom {
        &--left {
          order: 2;
          padding-top: rem(180);
          .portfolio-item-section {
            &__arrow {
              margin-bottom: rem(166);
            }
            &__inner-bottom--img{
              width: 69.5%;
            }
          }
        }
        &--right {
          order: 1;
          .portfolio-item-section {
            &__description {
              margin-bottom: rem(38);
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  @include media-max(sm) {
    .portfolio-item-section {
      &__inner-top {
        margin-bottom: rem(50);
        &--gif {
          padding-top: rem(120);
          padding-bottom: rem(130);
          .portfolio-item-section {
            &__figure-caption {
              margin-bottom: rem(60);
              .figure-caption-item {
                &__text--bottom {
                  margin-left: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}