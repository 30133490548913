// font-sizes

$font-size-base: $browser-context; // functions/convert-pixels

// font-weights

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// line-heights

$line-height-none: 1;
$line-height-xxs: 0.9;
$line-height-xs: 0.99;
$line-height-sm: 1.1;
$line-height-md: 1.14;
$line-height-lg: 1.2;
$line-height-xl: 1.3;
$line-height-xxl: 1.4;

$letter-spacing-sm: 0.01em;
$letter-spacing-md: 0.02em;
$letter-spacing-lg: 0.03em;
$letter-spacing-xl: 0.04em;

// font-families

$font-family-anonymous-pro: AnonymousPro, "Lucida Console", Monaco, monospace;
$font-family-euclid-flex: EuclidFlex, Arial, Helvetica, sans-serif;

$font-family-euclid-flex-indent-fix: em(-1.5px);
$font-family-euclid-flex-line-height-none: 0.75;
