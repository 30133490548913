.image {
  width: 100%;

  &--width-auto {
    width: auto;
  }

  &--full-width {
    width: 100%;
  }

  &--cover {
    object-fit: cover;
  }
}
