.view-portfolio-item-about-section--type-sk10-site {
  padding-top: rem(160);
  padding-bottom: rem(470);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 62.5%;
  }
}

.portfolio-item-section {
  &--type-sk10-3 {
    padding: rem(30) 0;
    column-gap: 14px;

    .col-half {
      width: calc(50% - 7px);
      img {
        object-fit: cover;
      }
      * {
        width: 100%;
        height: 100%;
      }
    }

    @include media-max(md) {
      padding: rem(18) 0;
      column-gap: 8px;

      .col-half {
        width: calc(50% - 4px);
      }
    }
  }

  &--type-sk10-4 {
    padding-bottom: rem(30);

    @include media-max(md) {
      padding: rem(18) 0;
    }
  }

  &--type-sk10-5 {
    .u-ratio {
      padding-top: 151%;
    }
  }

  &--type-sk10-6 {
    padding: rem(30) 0;
    row-gap: rem(30);
    column-gap: 14px;
    .col-half {
      width: calc(50% - 7px);
    }

    @include media-max(md) {
      padding: rem(18) 0;
      column-gap: 8px;
      row-gap: rem(18);

      .col-half {
        width: calc(50% - 4px);
      }
    }
  }

  &--type-sk10-8 {
    position: relative;
    padding: rem(30) 0;
    row-gap: rem(30);
    column-gap: 14px;

    .u-ratio {
      padding-top: 75%;
    }
    .col-half {
      width: calc(50% - 7px);

      img {
        object-fit: cover;
      }
      * {
        width: 100%;
        height: 100%;
      }
    }
    .over-img {
      position: absolute;
      top: 59%;
      left: 7%;
      max-width: 28vw;
      transform: translateY(-50%);
    }
    @include media-max(md) {
      padding: rem(18) 0;
      column-gap: 8px;
      row-gap: rem(18);

      .col-half {
         width: calc(50% - 4px);
      }
    }
  }

  &--type-sk10-10 {
    padding: rem(30) 0;

    @include media-max(md) {
      padding: rem(18) 0;
    }
  }

  &--type-sk10-12 {
    padding: rem(30) 0;

    @include media-max(md) {
      padding: rem(18) 0;
    }
  }

  &--type-sk10-14 {
    padding: rem(30) 0;
    row-gap: rem(30);
    column-gap: 14px;

    .col-half {
      width: calc(50% - 7px);
    }

    .u-ratio {
      padding-top: 143%;
    }

    @include media-max(md) {
      padding: rem(18) 0;
      column-gap: 8px;
      row-gap: rem(18);

      .col-half {
        width: calc(50% - 4px);
      }
    }
  }

  &--type-sk10-15 {
    column-gap: 14px;
    .col-half {
      width: calc(50% - 7px);
      img {
        object-fit: cover;
      }
      * {
        width: 100%;
        height: 100%;
      }
    }

    @include media-max(md) {
      column-gap: 8px;

      .col-half {
        width: calc(50% - 4px);
      }
    }
  }

  &--type-sk10-16 {
    padding: rem(30) 0;

    @include media-max(md) {
      padding: rem(18) 0;
    }
  }
}