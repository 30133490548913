.section-link {
  @import "./vars";

  &__container {
    display: block;

    padding-top: $section-link-container_padding-top;
    padding-bottom: $section-link-container_padding-bottom;

    user-select: none;

    &:hover {
      cursor: none;
    }
  }

  &__main {
    margin-bottom: $section-link-main_margin-bottom;
  }

  &:hover &__col-with-arrow {
    color: $color-white;

    mix-blend-mode: difference;

    z-index: 1;
    opacity: 1;
  }

  &__arrow {
    display: block;

    font-size: $section-link-arrow_size;

    pointer-events: none;

    transform: translateY(-30%);
  }

  &__sliding-text {
    position: relative;
    overflow: hidden;
  }

  &__sliding-text-inner {
    display: inline-block;

    width: auto;

    white-space: nowrap;
    will-change: transform;
    backface-visibility: hidden;
  }

  &__sliding-text-item {
    flex-shrink: 0;
    display: inline-block;
    width: auto;
    white-space: nowrap;
    font-size: $section-link-sliding-text-item_font-size;
  }

  &__sliding-text-item--duplicates {
    position: absolute;

    top: 0;
    right: -100%;
  }

  &__sliding-text-item-dot {
    margin-left: $section-link-sliding-text-item-dot_margin-x;
    margin-right: $section-link-sliding-text-item-dot_margin-x;

    font-size: $section-link-sliding-text-item-dot_font-size;
  }

  @include media-min(md) {
    &__container {
      padding-top: $section-link-container_padding-top_min-md;
      padding-bottom: $section-link-container_padding-bottom_min-md;
    }

    &__main {
      margin-bottom: $section-link-main_margin-bottom_min-md;
    }

    &__arrow {
      font-size: $section-link-arrow_size_min-md;
    }

    &__sliding-text-item {
      flex-shrink: 0;

      font-size: $section-link-sliding-text-item_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $section-link-container_padding-top_min-xl;
      padding-bottom: $section-link-container_padding-bottom_min-xl;
    }

    &__main {
      margin-bottom: $section-link-main_margin-bottom_min-xl;
    }

    &__arrow {
      font-size: $section-link-arrow_size_min-xl;
    }

    &__sliding-text-item {
      flex-shrink: 0;

      font-size: $section-link-sliding-text-item_font-size_min-xl;
    }
  }
}
