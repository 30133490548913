.view-portfolio-item-next-section {
  @import "./vars";

  &__description {
    line-height: $view-portfolio-item-section-link_description_line-height;
  }

  @include media-max(md) {
    &__description {
      font-size: $view-portfolio-item-section-link_description_font-size_max-md;
      margin-top: $view-portfolio-item-section-link_description_margin-top_max-md;
    }
  }
}
