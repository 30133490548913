.space {
  @import "./vars";

  &--header {
    padding-top: $space-header;
  }

  &--lead-section-container-top {
    padding-top: $space-lead-section-container-top_padding-top;
  }

  @include media-min(md) {
    &--header {
      padding-top: $space-header_min-md;
    }

    &--lead-section-container-top {
      padding-top: $space-lead-section-container-top_padding-top_min-md;
    }
  }

  @include media-min(xl) {
    &--header {
      padding-top: $space-header_min-xl;
    }

    &--lead-section-container-top {
      padding-top: $space-lead-section-container-top_padding-top_min-xxl;
    }
  }
}
