.portfolio-item-section--type-git-4 {
    /* background: top center / contain no-repeat url("/assets/portfolio/globe-it-site/view/screen-4-bg.webp"),
    #F3F3F3 35%; */
    padding-bottom: 178px;
    padding-top: 0;
}
.view-portfolio-globe-it-product-card {
    & > .col {
        margin: 0 auto;
        // border: 1px solid #BFBFBF;
    }
}