.view-about-objectives-section {
  @import "./vars";

  &__container {
    padding-top: $view-about-objectives-section-container_padding-top;
    padding-bottom: $view-about-objectives-section-container_padding-bottom;
  }

  &__objectives-list {
    .list {
      &__item:not(:last-child) {
        margin-bottom: $view-about-objectives-section-objectives-list-item_margin-bottom;
      }
    }
  }

  &__header {
    margin-bottom: $view-about-objectives-section-header_margin-bottom;
  }

  &__overline {
    margin-bottom: $view-about-objectives-section-overline_margin-bottom;
  }

  &__heading {
    font-size: $view-about-objectives-section-heading_font-size;
    font-weight: $font-weight-light;
    font-feature-settings: "cpsp" on, "ss02" on;
  }

  &__objectives-item-label {
    margin-bottom: $view-about-objectives-section-objectives-item-label_margin-bottom;
  }

  @include media-min(md) {
    &__container {
      padding-top: $view-about-objectives-section-container_padding-top_min-md;
      padding-bottom: $view-about-objectives-section-container_padding-bottom_min-md;
    }

    &__objectives-list {
      .list {
        &__item:not(:last-child) {
          margin-bottom: $view-about-objectives-section-objectives-list-item_margin-bottom_min-md;
        }
      }
    }

    &__header {
      margin-bottom: $view-about-objectives-section-header_margin-bottom_min-md;
    }

    &__overline {
      margin-bottom: $view-about-objectives-section-overline_margin-bottom_min-md;
    }

    &__heading {
      font-size: $view-about-objectives-section-heading_font-size_min-md;
    }

    &__objectives-item-label {
      margin-bottom: $view-about-objectives-section-objectives-item-label_margin-bottom_min-md;
    }
  }
  @include media-min(lg) {
    &__container {
      padding-top: $view-about-objectives-section-container_padding-top_min-lg;
      padding-bottom: $view-about-objectives-section-container_padding-bottom_min-lg;
    }

    &__header {
      position: relative;
      margin-bottom: $view-about-objectives-section-header_margin-bottom_min-lg;
    }

    &__overline {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(15%);
      margin-bottom: $view-about-objectives-section-overline_margin-bottom_min-lg;
    }
  }

  @include media-min(xl) {
    &__heading {
      font-size: $view-about-objectives-section-heading_font-size_min-xl;
    }
  }
}
