.portfolio-item-section--type-ens-7{
  padding-top: rem(180);
  padding-bottom: rem(135);
  position: relative;
  z-index: 2;
  .portfolio-item-section{
    &__figure-caption{
      margin-top: rem(150);
      &-item--bottom{
        text-indent: 16%;
      }
    }
    &__right-side{
      padding-left: rem(60);
    }
  }
  .view-portfolio-ensemble-7{
    &__img-overlay{
      transform: translateY(22vw);
      width: 83%;
    }
    &__img-center{
      margin-bottom: rem(200);
    }
  }
  @include media-max(xl) {
    padding-bottom: rem(100);
    .portfolio-item-section{
      &__figure-caption{
        margin-top: rem(100);
      }
      &__right-side{
        padding-left: 0;
      }
    }
    .view-portfolio-ensemble-7{
      &__img-overlay{
        transform: translateY(30vw);
      }
      &__img-center{
        margin-bottom: rem(150);
      }
    }
  }
  @include media-max(md) {
    .portfolio-item-section{
      &__figure-caption{
        margin-top: rem(100);
        margin-bottom: rem(100);
        &-item--bottom{
          text-indent: 0;
        }
      }
    }
    .view-portfolio-ensemble-7{
      &__img-overlay{
        transform: translateY(0);
        width: 100%;
      }
      &__img-center{
        margin-bottom: rem(100);
      }
    }
  }
}

