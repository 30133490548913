.side-brackets {
  text-transform: uppercase;
  font-size: rem(15px);
  white-space: nowrap;

  &:before {
    content: "[";
  }

  &:after {
    content: "]";
  }

  &:before,
  &:after {
    vertical-align: inherit;
    pointer-events: none;
  }

  &--hover-expand {
    &::before,
    &::after {
      display: inline-block;
      transition: transform 0.3s ease;
    }

    &:hover:before {
      transform: translateX(-0.3173333333333333em);
    }

    &:hover:after {
      transform: translateX(0.3173333333333333em);
    }
  }

  &--hover {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: all 0.3s ease;
    }

    &:before {
      left: -0.3173333333333333em;
      transform: translateX(-100%);
    }

    &:after {
      right: -0.3173333333333333em;
      transform: translateX(100%);
    }

    &:hover:after,
    &:hover:before {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
