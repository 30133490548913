.svg-title {
  height: vw(47 / 414);

  @include media-min(md) {
    height: vw(83 / 768);
  }

  @include media-min(xl) {
    height: auto;
  }
}
