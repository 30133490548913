.scroll-down-button {
  $offset: rem(10px);

  position: relative;

  &__icon {
    font-size: rem(65px);
    transition: all 0.4s;
  }

  &:hover & {
    &__icon {
      transform: translateY($offset);
    }
  }

  @include media-min(md) {
    &__icon {
      font-size: rem(94px);
    }
  }
}
