.flip-book {
  position: relative;
  min-height: 100vh;

  &__inner {
    position: sticky;

    // убирает зазор в 1px, который появляется на мобильных устройствах
    top: -1px;

    width: 100%;
    height: 100vh;

    overflow: hidden;
  }

  &__canvas,
  &__image {
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
  }

  &__canvas {
    position: absolute;

    top: 0;
  }

  @include media-min(md) {
    &__inner {
      top: 0;
    }
  }
}
