.view-services-branding-section {
  @import "./vars";

  &__container {
    padding-top: $view-services-branding-section-container_padding-top;
    padding-bottom: $view-services-branding-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $view-services-branding-section-header_margin-bottom;
  }

  &__heading {
    margin-bottom: $view-services-branding-section-heading_margin-bottom;
  }

  &__services-types-list {
    margin-bottom: $view-services-branding-section-services-types-list_margin-bottom;

    .list__item:not(:last-child) {
      margin-bottom: $view-services-branding-section-services-types-list-item_margin-bottom;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $view-services-branding-section-container_padding-top_min-md;
      padding-bottom: $view-services-branding-section-container_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $view-services-branding-section-header_margin-bottom_min-md;
    }

    &__heading {
      margin-bottom: $view-services-branding-section-heading_margin-bottom_min-md;
    }

    &__services-types-list {
      margin-bottom: $view-services-branding-section-services-types-list_margin-bottom_min-md;

      .list__item:not(:last-child) {
        margin-bottom: $view-services-branding-section-services-types-list-item_margin-bottom;
      }
    }
  }

  @include media-min(xxl) {
    &__container {
      padding-top: $view-services-branding-section-container_padding-top_min-xxl;
      padding-bottom: $view-services-branding-section-container_padding-bottom_min-xxl;
    }

    &__header {
      margin-bottom: $view-services-branding-section-header_margin-bottom_min-xxl;
    }

    &__heading {
      margin-bottom: $view-services-branding-section-heading_margin-bottom_min-xxl;
    }

    &__services-types-list {
      .list__item:not(:last-child) {
        margin-bottom: $view-services-branding-section-services-types-list-item_margin-bottom;
      }
    }
  }
}
