@import "/src/scss/mixins.scss";

.view-developer-sites-stages-section {
  $p: &;

  padding: 185px 0;

  @include media-max(md) {
    padding: 125px 0;
  }

  @include media-max(sm) {
    padding: 100px 0;
  }

  &__header {
    padding: 0 0 95px 0;

    @include media-max(md) {
      gap: 45px;
    }
  }

  &__sliding-text-item {
    flex-shrink: 0;
    display: inline-block;
    width: auto;
    white-space: nowrap;
    font-size: vw(70px / $mobile-width);
    color: rgba(255, 255, 255, 0.1);
  }

  &__sliding-text-item-dot {
    margin-left: vw(30px / $mobile-width);
    margin-right: vw(30px / $mobile-width);

    font-size: vw(11px / $mobile-width);
  }

  .stage-with-brackets {
    justify-content: space-between;
  }

  .head-in-step {
    @include media-max(md) {
      order: -1;
      margin-bottom: 65px;
      flex: 0 0 100%;
      max-width: 100%;

      p {
        text-align: left;
      }
    }

    @include media-max(xs) {
      margin-bottom: 30px;
    }
  }

  &__ticker {
    margin-bottom: 53px;

    @include media-max(lg) {
      margin-bottom: rem(45px);
    }

    @include media-max(md) {
      margin-bottom: rem(30px);
    }
  }

  &__item-wrapper {
    width: 100%;
    margin-bottom: 107px;

    @include media-max(lg) {
      margin-bottom: 60px;
    }

    @include media-max(md) {
      margin-bottom: 40px;
    }
  }

  &__item-outer {
    &:not(:last-child) {
      padding-bottom: 107px;

      @include media-max(lg) {
        padding-bottom: rem(95px);
      }

      @include media-max(md) {
        padding-bottom: rem(65px);
      }
    }
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
  }

  &__item-paginator-item {
    width: 100%;
    height: rem(4px);
    background: rgba(255, 255, 255, 0.1);

    &.selected {
      background: $color-white;
    }
  }

  &__item-paginator-items {
    display: flex;
    gap: rem(3px);
    width: 100%;
    margin-bottom: rem(30px);

    @include media-max(sm) {
      margin-bottom: rem(20px);
    }
  }

  &__items-header {
    // @include media-max(sm) {
    //   margin-bottom: rem(65px);
    // }
  }

  &__step-number {
    margin: 20px 0 6px;
    text-transform: lowercase !important;

    @include media-max(md) {
      margin: 15px 0;
    }

    @include media-max(sm) {
      margin: 15px 0 6px;
    }
  }

  &__step-text {
    text-transform: lowercase;
  }

  &__item-subtitle {
    margin-top: rem(15px);
    font-weight: 500;
    font-size: rem(15px);
    text-transform: uppercase;
    margin-top: 10px;
  }
}