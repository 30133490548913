.vacancy-bonuses-section {
  @import "./vars";

  &__container {
    padding-top: $vacancy-bonuses-section-container_padding-top;
    padding-bottom: $vacancy-bonuses-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $vacancy-bonuses-section-header_margin-bottom;
  }

  &__heading {
    margin-bottom: $vacancy-bonuses-section-heading_margin-bottom;
    font-size: $vacancy-bonuses-section-heading_font-size;
  }

  &__subheading {
    text-align: right;
  }

  &__bonus:not(:last-child) {
    margin-bottom: $vacancy-bonuses-section-bonus_margin-bottom;
  }

  &__bonus-col-with-label {
    margin-bottom: $vacancy-bonuses-section-bonus-col-with-label_margin-bottom;
  }

  @include media-min(md) {
    &__container {
      padding-top: $vacancy-bonuses-section-container_padding-top_min-md;
      padding-bottom: $vacancy-bonuses-section-container_padding-bottom_min-md;
    }

    &__header {
      position: relative;
      margin-bottom: $vacancy-bonuses-section-header_margin-bottom_min-md;
    }

    &__heading {
      margin-bottom: $vacancy-bonuses-section-heading_margin-bottom_min-md;
      font-size: $vacancy-bonuses-section-heading_font-size_min-md;
    }

    &__subheading {
      position: absolute;

      top: 0;
      left: 0;

      display: flex;
      align-items: center;

      // height: $vacancy-bonuses-section-subheading_height_min-md;

      text-align: left;
    }

    &__bonus-col-with-description {
      max-width: $vacancy-bonuses-section-bonus-col-with-description_max-width_min-md;
    }

    &__bonus:not(:last-child) {
      margin-bottom: $vacancy-bonuses-section-bonus_margin-bottom_min-md;
    }

    &__bonus-col-with-label {
      margin-bottom: $vacancy-bonuses-section-bonus-col-with-label_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $vacancy-bonuses-section-container_padding-top_min-xl;
      padding-bottom: $vacancy-bonuses-section-container_padding-bottom_min-xl;
    }

    &__header {
      margin-bottom: $vacancy-bonuses-section-header_margin-bottom_min-xl;
    }

    &__heading {
      font-size: $vacancy-bonuses-section-heading_font-size_min-xl;
    }

    &__subheading {
      // height: $vacancy-bonuses-section-subheading_height_min-xl;
    }
  }
}
