.dynamic-background {
  position: fixed;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  transition: background-color 0.8s;

  will-change: background-color;

  pointer-events: none;
}
