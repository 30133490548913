@import "/src/scss/mixins.scss";

.view-developer-sites-photo-section {
  height: 100vh;
  &__container {
    height: 100%;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    max-width: none;
    height: 100%;
  }
}
