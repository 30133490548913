$grid-container-xl: 120px;

$grid-config: (
  columns: 11,
  gutter: (
    container: (
      xxs: 48px,
      xl: $grid-container-xl,
    ),
  ),
  breakpoints: $min-breakpoints,
  order: true,
  offset: true,
);
