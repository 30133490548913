@import './vars';

.view-portfolio-item-about-section--type-sk10 {
  .view-portfolio-item-about-section__middle {
    .col-5 {
      flex: 0 0 81.81818%;
      max-width: 81.81818%;
    }
    .offset-3 {
      margin-left: 9.09091%;
    }
  }
}
