.more-btn {
  margin-top: auto;
  padding-top: rem(30px);
  * {
    color: black;
    text-decoration: none !important;
    text-wrap-mode: wrap;
  }

  display: block;
  width: 100%;

  .button {
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__label {
      text-align: left;
    }

    &__right {
      margin-bottom: 0.2em;
      backface-visibility: hidden;
      transform-origin: center;
      transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    }
  }

  .icon {
    &__svg path {
      transition: d 0.15s ease-in-out;
    }
  }

  .icon {
    &__svg > path {
      d: path(
        "M 39.7805 93.6 L 44.4605 93.6 L 44.4605 47.58 L 35.1005 47.58 L 44.4605 76.18 L 44.4605 0 L 35.1005 0 L 35.1005 76.18 L 44.4605 47.58 L 35.1005 47.58 L 35 93.6 L 39.7805 93.6 Z"
      );
    }
  }

  &.is-active .button,
  &:hover .button {
    &__right {
      transform: rotate(-90deg) translate3d(0, rem(10px), 0);
    }
  }

  .button {
    &__label {
      max-width: 40%;
    }

    &__inner {
      align-items: center;
    }

    &__right {
      transform: rotate(-90deg) translateZ(0);
    }
  }

}
