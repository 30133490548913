@import "/src/scss/mixins.scss";

.document-link {
  padding: rem(10px) rem(20px);
  border: 1px solid $color-gray-l77;
  display: flex;
  flex-direction: column;
  width: max-content;

  &__svg {
    height: rem(20px);
    width: rem(20px);
    margin-top: auto;
  }

  .lowercase-title {
    text-transform: lowercase;

    &.document-link__caption-container {
      margin-top: 3px;
    }

    a,
    span {
      text-transform: lowercase;
    }
  }

  &__title {
    color: $color-shark-1;
    font-size: rem(14px);
    margin: auto 0;


    @include media-max(md) {
      white-space: initial;
    }
  }

  &__title-container {
    display: flex;
    flex-wrap: nowrap;
    gap: rem(10px);
  }

  &__caption {
    font-size: rem(13px);
    color: $color-gray-l77;

    @include media-max(md) {
      white-space: initial;
    }
  }

  &__caption-container {
    gap: rem(20px);
    flex-wrap: nowrap;

  }
}