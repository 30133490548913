.section {
  @import "../header/vars";

  position: relative;
  z-index: 1;

  &--height-viewport {
    height: 100vh;
  }

  &--with-scroll-appear-background {
    z-index: 0;
  }

  &__container {
    position: relative;
  }

  &--lead {
    padding-top: $header_height;
    padding-bottom: rem(15px);
  }

  @include media-min(md) {
    &--lead {
      padding-top: $header_height_min-md;
      padding-bottom: rem(40px);
    }
  }

  @include media-min(xl) {
    &--lead {
      padding-top: $header_height_min-xl;
      padding-bottom: rem(30px);
    }
  }
}
