.view-about-portrait-section {
  @import "./vars";

  &__image {
    height: $view-about-portrait-section_height;
    object-fit: cover;
  }

  &__image {
    width: 100%;
  }

  @include media-min(md) {
    &__image {
      height: $view-about-portrait-section_height_min-md;
    }
  }

  @include media-min(lg) {
    display: none;
  }
}
