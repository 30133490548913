.sprite-icon {
  $this: &;

  background-repeat: no-repeat;

  &--arrow-down {
    width: 55px;
    height: 55px;

    background-image: url("../../../assets/images/sprite-icons/arrow-down.svg");

    animation-duration: 0.4s;
    animation-timing-function: steps(24);
    animation-name: sprite-icon-arrow-down-play-from;
    animation-fill-mode: forwards;
    transition: 0.4s transform;

    &.is-active {
      animation-name: sprite-icon-arrow-down-play-to;
      transform: translate(0, 5px);
    }

    @keyframes sprite-icon-arrow-down-play-from {
      0% {
        background-position: -1320px 0px;
      }

      100% {
        background-position: 0px 0px;
      }
    }

    @keyframes sprite-icon-arrow-down-play-to {
      0% {
        background-position: 0px 0px;
      }

      100% {
        background-position: -1320px 0px;
      }
    }
  }

  &--arrow-down-white {
    width: 55px;
    height: 55px;

    background-image: url("../../../assets/images/sprite-icons/arrow-down-white.svg");

    animation-duration: 0.4s;
    animation-timing-function: steps(24);
    animation-name: sprite-icon-arrow-down-play-from;
    animation-fill-mode: forwards;
    transition: 0.4s transform;

    &.is-active {
      animation-name: sprite-icon-arrow-down-play-to;
      transform: translate(0, 5px);
    }

    @keyframes sprite-icon-arrow-down-play-from {
      0% {
        background-position: -1320px 0px;
      }

      100% {
        background-position: 0px 0px;
      }
    }

    @keyframes sprite-icon-arrow-down-play-to {
      0% {
        background-position: 0px 0px;
      }

      100% {
        background-position: -1320px 0px;
      }
    }
  }
}
