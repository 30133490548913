.view-portfolio-aist-section {
  .title {
    font-size: rem(70);
    line-height: 1;
  }
  .number {
    font-size: rem(15);
    line-height: 1;
    margin-top: rem(9);
  }
  .background {
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.portfolio-item-section {
  &--type-aist-2 {
    position: relative;
    width: 100%;
    height: calc(112vw + 50px);
    padding-top: rem(50);

    .title-block {
      margin-bottom: rem(160);
    }

    .description {
      margin-bottom: rem(50);

      .caption,
      .text {
        font-size: rem(20);
      }
    }

    .back {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      object-fit: contain;
      z-index: -1;
    }
  }

  &--type-aist-3 {
    position: relative;
    height: 62vw;
    padding-top: rem(60);
    .title-block {
      .subtitle {
        margin-top: rem(9);
        font-size: rem(15);
      }
    }
    .back {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: contain;
    }
  }

  &--type-aist-4 {
    padding-top: rem(65);
    .title-block {
      margin-bottom: rem(145);
    }
    .description {
      margin-bottom: rem(52);

      .caption,
      .text {
        font-size: rem(20);
      }
    }

    img {
      margin-top: rem(115);
      width: 100%;
    }
  }

  &--type-aist-5 {
    position: relative;
    overflow: hidden;
    padding-top: rem(50);
    padding-bottom: rem(135);

    .title-block {
      margin-bottom: rem(148);
    }

    .person-list {
      margin-bottom: rem(140);
      .item {
        &.small {
          padding-left: 15px;
        }
        .img-wrap {
          margin-bottom: rem(10);
          img {
            width: 100%;
          }
        }
        .caption {
          margin-bottom: rem(18);
          font-size: rem(30);
          line-height: 1;
        }
        .text {
          display: flex;
          font-size: 16px;
          p {
            max-width: rem(370);
            padding-right: 15px;
          }
          ul {
            margin-top: 0;
            margin-left: auto;
            padding-left: 14px;
            li {
              white-space: pre-wrap;
            }
          }
        }
      }
    }

    .job-stories {
      margin-bottom: rem(180);
      .title-block {
        margin-bottom: rem(110);
      }
      .description {
        &:not(:last-child) {
          margin-bottom: rem(52);
        }

        .caption,
        .text {
          font-size: rem(20);
        }
      }
    }

    .customer {
      .title-block {
        margin-bottom: rem(100);
      }

      img {
        width: 100%;
      }
    }
  }

  &--type-aist-6 {
    position: relative;
    padding-top: rem(80);
    .title-block {
      margin-bottom: 160px;
    }
    .description {
      &:not(:last-child) {
        margin-bottom: rem(52);
      }

      .caption,
      .text {
        font-size: rem(20);
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: rem(10);
        svg {
          margin-right: rem(8);
        }
      }
    }
    .img-wrap {
      width: 100%;

      &.scheme {
        margin-top: rem(160);
        img {
          width: 100%;
        }
      }
    }

    .auth-from {
      position: relative;
      padding-top: rem(128);
      padding-bottom: rem(185);
      row-gap: rem(130);

      .item {
        p {
          width: max-content;
          margin-left: auto;
          margin-bottom: rem(64);
          white-space: pre-wrap;
          text-indent: -60%;
          span {
            display: block;
          }
          &.left {
            width: 100%;
            margin-left: 0;
            text-indent: 20% hanging;
          }
        }
        .img-wrap {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  &--type-aist-7 {
    position: relative;
    height: 162vw;
    .title-block {
      padding-top: rem(60);
      padding-bottom: 12vw;
      background-color: #252529;
    }
    .back {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: -1;
    }
  }

  &--type-aist-8 {
    padding-top: rem(60);
    padding-bottom: rem(106);

    .title-block {
      margin-bottom: rem(160);
    }

    .img-wrap {
      padding-right: 0;
      img {
        width: 100%;
      }
    }
  }

  &--type-aist-9 {
    padding-top: rem(60);
    padding-bottom: rem(90);

    .title-block {
      margin-bottom: rem(130);
    }

    .flights {
      row-gap: rem(120);

      .item {
        &.no-caption {
          display: flex;

          .img-wrap {
            margin-top: auto;
          }
        }
        p {
          font-size: rem(20);
          margin-bottom: rem(44);
          white-space: pre-wrap;
          text-indent: 20% hanging;
        }
      }
    }
  }

  &--type-aist-10 {
    position: relative;
    padding-top: rem(64);
    .title-block {
      margin-bottom: rem(160);
    }
    .description {
      .caption,
      .text {
        font-size: rem(20);
      }
    }
    .black-zone {
      height: calc(36.82vw + 70px + 160px + 88px + 158px);
    }
    .banner {
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      img {
        width: 100%;
      }
    }
    .white-zone {
      display: flex;
      align-items: flex-end;
      height: calc(36.82vw + 90px + 33px + 64px + 44px + 45vw);
      background-color: #fff;
      padding-bottom: rem(84);
    }
    .list {
      color: #252529;
      height: max-content;
      .item {
        &.right {
          p {
            padding-left: 20%;
          }
        }
        p {
          margin-bottom: rem(44);
          font-size: rem(20);
          white-space: pre-wrap;
          text-indent: 17% hanging;
        }
      }
    }
  }

  &--type-aist-11 {
    padding-top: rem(64);
    padding-bottom: rem(236);
    .title-block {
      margin-bottom: rem(130);
    }
    .list {
      display: flex;
      flex-direction: column;
      row-gap: rem(180);
      .item {
        display: flex;
        flex-direction: column;
        &.right {
          p {
            text-indent: 34%;
            max-width: 423px;
          }
        }
        p {
          margin-bottom: rem(66);
          font-size: rem(20);
          white-space: pre-wrap;
          text-indent: 15% hanging;
        }
        .img-wrap {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  @include media-max(xl) {
    &--type-aist-2 {
      .title-block {
        margin-bottom: rem(50);
      }

      .description {
        .caption,
        .text {
          font-size: rem(15);
        }
      }
    }

    &--type-aist-3 {
      padding-top: 0;
      .title-block {
        .number {
          margin-top: rem(5);
        }
        .title {
          font-size: rem(44);
        }
        .subtitle {
          margin-top: rem(5);
        }
      }
      .back {
        height: 103%;
        object-fit: cover;
      }
    }

    &--type-aist-4 {
      .title-block {
        margin-bottom: rem(50);
      }

      .description {
        .caption,
        .text {
          font-size: rem(15);
        }
      }
    }

    &--type-aist-5 {
      .title {
        font-size: rem(50);
      }
    }

    &--type-aist-6 {
      .title {
        font-size: rem(50);
      }
      .description {
        .caption,
        .text {
          font-size: rem(15);
        }
      }
      .auth-from {
        .item {
          p {
            text-indent: -30%;
          }
        }
      }
    }

    &--type-aist-7 {
      .title-block {
        padding-bottom: 8vw;
        .title {
          font-size: rem(50);
        }
      }
    }

    &--type-aist-8 {
      .title-block {
        margin-bottom: rem(100);
        .title {
          font-size: rem(50);
        }
      }
    }

    &--type-aist-9 {
      .title-block {
        margin-bottom: rem(100);
        .title {
          font-size: rem(50);
        }
      }

      .flights {
        row-gap: rem(80);

        .item {
          p {
            font-size: rem(15);
            margin-bottom: rem(22);
          }
        }
      }
    }

    &--type-aist-10 {
      .title-block {
        margin-bottom: rem(100);
        .title {
          font-size: rem(50);
        }
      }
      .description {
        .caption,
        .text {
          font-size: rem(15);
        }
      }
      .black-zone {
        height: calc(36.82vw + 70px + 160px + 88px);
      }
      .white-zone {
        padding-bottom: rem(50);
        height: calc(36.82vw + 90px + 33px + 64px + 44px + 38vw);
      }
      .list {
        .item {
          &.right p {
            padding-left: 0;
          }
          p {
            font-size: rem(15);
            margin-bottom: rem(22);
          }
        }
      }
    }
    &--type-aist-11 {
      padding-bottom: rem(150);
      .title-block {
        margin-bottom: rem(100);
        .title {
          font-size: rem(50);
        }
      }
      .list {
        row-gap: rem(140);
        .item {
          &.right p {
            max-width: 318px;
          }
          p {
            font-size: rem(15);
            margin-bottom: rem(44);
          }
        }
      }
    }
  }

  @include media-max(md) {
    &--type-aist-2 {
      height: 824px;
      padding-top: 0;

      .title-block {
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }

        .title {
          font-size: rem(40);
        }
      }

      .description {
        .caption {
          margin-bottom: rem(15);
        }
        .text {
          &:not(:last-child) {
            margin-bottom: rem(15);
          }
        }
      }
    }

    &--type-aist-3 {
      height: 65vw;
      padding-top: 0;
      .title-block {
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
      .back {
        height: 100%;
        object-fit: contain;
      }
    }

    &--type-aist-4 {
      padding-top: 0;
      .title-block {
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }

        .title {
          font-size: rem(40);
        }
      }

      .description {
        .caption {
          margin-bottom: rem(15);
        }
        .text {
          &:not(:last-child) {
            margin-bottom: rem(15);
          }
        }
      }
    }

    &--type-aist-5 {
      padding-bottom: rem(50);
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
      .person-list {
        margin-bottom: rem(100);
        .item {
          &.small {
            margin-top: rem(40);
            padding-left: 0;
          }
          .text {
            flex-wrap: wrap;
            ul {
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }
      .job-stories {
        margin-bottom: rem(100);
        .title-block {
          margin-bottom: rem(50);
        }
        .description {
          .caption {
            margin-bottom: rem(15);
          }
          .text {
            &:not(:last-child) {
              margin-bottom: rem(15);
            }
          }
        }
      }
      .customer {
        .title-block {
          margin-bottom: rem(50);
        }
      }
    }

    &--type-aist-6 {
      padding-top: rem(50);
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
      .description {
        .caption {
          margin-bottom: rem(15);
        }
        .text {
          &:not(:last-child) {
            margin-bottom: rem(15);
          }
        }
      }
      .img-wrap.scheme {
        margin-top: 0;
      }
      .auth-from {
        padding-top: rem(50);
        padding-bottom: rem(50);
        row-gap: rem(50);
        .item {
          p {
            margin-bottom: rem(30);
            margin-left: 0;
            text-indent: 0;
            &.left {
              text-indent: 0;
            }
          }
        }
      }
    }

    &--type-aist-7 {
      height: calc(150vw + 195px);
      .title-block {
        padding-top: rem(50);
        padding-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
    }

    &--type-aist-8 {
      padding-bottom: rem(50);
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
    }

    &--type-aist-9 {
      padding-bottom: rem(50);
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }

      .flights {
        row-gap: rem(50);
        .item p {
          text-indent: 13% hanging;
        }
      }
    }

    &--type-aist-10 {
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
      .description {
        .caption {
          margin-bottom: rem(15);
        }
        .text {
          &:not(:last-child) {
            margin-bottom: rem(15);
          }
        }
      }
      .black-zone {
        height: calc(36.82vw + 70px + 160px + 88px);
      }
      .white-zone {
        height: calc(36.82vw + 66px + 44px + 64vw + 140px + 95vw);
      }
      .list {
        row-gap: rem(50);
      }
    }
    &--type-aist-11 {
      .title-block {
        margin-bottom: rem(50);
        .number {
          margin-top: 0;
          margin-bottom: rem(20);
        }
        .title {
          font-size: rem(40);
        }
      }
      .list {
        row-gap: rem(80);
        .item {
          &.right p {
            max-width: 100%;
            text-indent: 6% hanging;
          }
          p {
            margin-bottom: rem(22);
            text-indent: 10% hanging;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    &--type-aist-3 {
      height: rem(390);

      .title-block {
        .title {
          font-size: rem(30);
        }
      }
    }
    &--type-aist-7 {
      height: calc(160vw + 250px);
      .title-block {
        padding-top: rem(30);
        padding-bottom: rem(20);
      }
    }
  }
  @media (max-width: 413px) {
    &--type-aist-6 {
      .title-block .title {
        font-size: rem(30);
      }
    }
  }
}
