@import "../../layout/body/vars";

$background-colors: (
  light-gray: $color-gray-l95,
  gray: $color-iron,
  dark-gray: $color-shark-1,
  white: $color-white,
  dark-white: #fdfeff,
  dark-white-2: #ededed,
  red: $color-bright-red,
  blue: $color-blue-ribbon-l56,
  dark-blue: #2F44BF,
  body: $body_background-color,
  orange: #DF7B51,
  green: #3B8368,
  light-blue: #3A72C9,
  purple: #8A4BDD,
  dark-purple: #2C29D2
);
