@import '/src/scss/mixins.scss';

.portfolio-showcase-section {
  @import "./vars";

  position: relative;

  &__container {
    padding-top: $portfolio-showcase-section-container_padding-top;
    padding-bottom: $portfolio-showcase-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $portfolio-showcase-section-header_margin-bottom;
  }

  &__col-with-portfolio-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $portfolio-showcase-section-col-with-portfolio-item_margin-bottom;
    z-index: 1;

    &--filtered {
      opacity: 0;
      z-index: 0;
    }
  }

  &__filter-button {
    text-align: left;
    text-transform: unset;
  }

  &__col-with-portfolio-item {
    &--filtered {
      display: none;
    }

    &.enter {
      display: block;
      opacity: 0;
      transform: scale(0.9);
    }
    &.enter-active {
      transform: scale(1);
      opacity: 1;
      transition: all 0.4s 0.2s;
    }
    &.exit {
      opacity: 1;
    }
    &.exit-active {
      transform: scale(0.9);
      opacity: 0;
      transition: all 0.4s 0.2s;
    }
    &.exit-done {
      display: none;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $portfolio-showcase-section-container_padding-top_min-md;
      padding-bottom: $portfolio-showcase-section-container_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $portfolio-showcase-section-header_margin-bottom_min-md;
    }

    &__col-with-portfolio-item {
      margin-bottom: $portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-md;
    }

    &__portfolio-item {
      height: 100%;

      .portfolio-item {
        &__inner {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        &__preview {
          flex-grow: 1;
        }

        &__caption {
          margin-top: $portfolio-showcase-section-portfolio-item-caption_margin-top_min-md;
        }
      }
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $portfolio-showcase-section-container_padding-top_min-xl;
      padding-bottom: $portfolio-showcase-section-container_padding-bottom_min-xl;
    }

    &__header {
      margin-bottom: $portfolio-showcase-section-header_margin-bottom_min-xl;
    }

    &__col-with-portfolio-item {
      margin-bottom: $portfolio-showcase-section-col-with-portfolio-item_margin-bottom_min-xl;
    }

    &__portfolio-item {
      &--narrow {
        padding-bottom: $portfolio-showcase-section-col-with-portfolio-item-narrow_padding-bottom_min-xl;
      }

      .portfolio-item {
        // &__preview:before {
        //   padding-top: $portfolio-showcase-section-portfolio-item-preview_ratio_min-xl;
        // }

        &__preview--video .portfolio-item__preview-inner {
          padding-left: $portfolio-showcase-section-portfolio-item-preview-inner_padding-x_min-xl;
          padding-right: $portfolio-showcase-section-portfolio-item-preview-inner_padding-x_min-xl;
          margin-top: $portfolio-showcase-section-portfolio-item-preview-media_margin-y_min-xl;
          margin-bottom: $portfolio-showcase-section-portfolio-item-preview-media_margin-y_min-xl;
        }

        &__preview--video .portfolio-item__preview-media {
          object-fit: contain;
        }
      }
    }
  }
}
