.view-portfolio-item-section {
  @import "../vars";

  &--layout-wsb-cards & {
    &__header {
      margin-bottom: rem(10px);
    }

    &__body {
      transform: translateY(23%);
    }

    &__image {
      max-width: rem(1440px);
    }
  }

  &--layout-wsb-products & {
    &__container {
      padding-top: rem(275px);
      padding-bottom: rem(200px);
    }

    &__products-item:not(:last-child) {
      // margin-bottom: rem(200px);
      margin-bottom: rem(150px);
    }

    &__products-item {
      &--empty {
        // margin-top: -20%; //rem(-150px);
        // margin-bottom: -20% !important; //rem(-150px) !important;
        // height: 80vw;
        margin-bottom: 0 !important;
        height: 50vh;
      }

      &--overlay-next {
        // margin-bottom: -25% !important;
        transform: translateY(50%);
      }

      &--overlay-prev {
        // margin-top: -25% !important;
        transform: translateY(-50%);
        // margin-top: 0;
      }

      // &--accent {
      //   padding: rem(100px) 0;
      //   margin-bottom: rem(450px) !important;
      // }

      // &--accent &-image {
      //   position: relative;
      //   bottom: -100px;
      //   transform: translateY(50%);
      // }

      // &--overlay-next {
      //   margin-bottom: -45% !important;
      // }
    }

    &__products-item-label {
      margin-bottom: rem(30px);
    }

    // @include media-min(sm) {
    //   &__products-item {
    //     &--overlay-next {
    //       margin-bottom: rem(-250px) !important;
    //     }
    //   }
    // }
    // @include media-min(xxl) {
    //   &__products-item {
    //     &--overlay-next {
    //       margin-bottom: rem(-300px) !important;
    //     }
    //   }
    // }

    @include media-min(md) {
      &__container {
        padding-top: rem(375px);
        padding-bottom: rem(300px);
      }

      &__products-item {
        &:not(:last-child) {
          margin-bottom: rem(200px);
        }

        // &--empty {
        //   // margin-top: -20%;
        //   // margin-bottom: -20%;
        //   height: rem(900px);
        // }

        // &--accent {
        //   padding: rem(100px) 0;
        //   margin-bottom: 30% !important;
        // }

        // &--accent &-image {
        //   position: relative;
        //   bottom: -100px;
        //   transform: translateY(50%);
        // }

        // &--overlay-next {
        //   margin-bottom: -25% !important;
        // }
        // &--overlay-prev {
        //   margin-top: -25% !important;
        // }
      }
    }

    @include media-min(xxl) {
      &__container {
        padding-top: rem(475px);
        padding-bottom: rem(400px);
      }
    }
  }
}
