.view-portfolio-atlantic-articles-page-section {
  &__header {
    margin-bottom: rem(100px);
  }

  @include media-min(sm) {
    &__header {
      justify-content: center;
    }
  }

  @include media-min(md) {
    &__header {
      justify-content: flex-start;
    }

    &__space-top-md {
      margin-top: rem(75px);
    }
  }

  @include media-min(xl) {
    &__header {
      margin-bottom: rem(165px);
    }

    &__space-top-md {
      margin-top: rem(150px);
    }
  }
}
