.portfolio-item-section--type-ens-6{
  padding-top: rem(60);
  padding-bottom: rem(100);
  .portfolio-item-section{
    &__figure-caption{
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-item--top{
        max-width: 333px;
      }
      &-item--bottom{
        margin-top: rem(84);
        text-align: right;
      }
    }
  }
  @include media-max(md) {
    .portfolio-item-section{
      &__figure-content{
        margin-bottom: rem(50);
      }
    }
  }
}

