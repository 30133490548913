@import './vars';

.portfolio-item-section {
  //экран7
  &--type-mynaret-7 {
    padding-top: rem(118);
    padding-bottom: rem(118);
    .mynaret-section-video {
      aspect-ratio: 1.333/1;
    }
  }
  //экран8
  &--type-mynaret-8 {
    .mynaret-section-video {
      aspect-ratio: 1/1;
    }
  }
  //экран10
  &--type-mynaret-10 {
    .mynaret-section {
    }
  }
}
