.cookie-policy {
  @import "./body/vars";

  position: fixed;
  display: flex;
  bottom: rem(15);
  left: 0;
  right: 0;

  margin: 0 auto;

  z-index: 2;

  justify-content: center;

  &__container {
    margin: 0 rem(24);
    padding: rem(15) rem(30);

    border: 1px solid $primary-color;
    background-color: $body_background-color;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-right: rem(30);
    font-size: rem(15);
    font-family: $font-family-anonymous-pro;
  }

  &__accept-button {
    white-space: nowrap;
  }

  &.enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-out;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.2s ease-in;
  }
}
