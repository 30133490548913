.view-portfolio-wildberries-section-width {
  margin-left: rem(30);
  margin-right: rem(30);
  margin-top: rem(20);

  @include media-max(xl) {
    margin-top: rem(15);
    margin-left: rem(15);
    margin-right: rem(15);
  }

  @include media-max(md) {
    margin-left: 0;
    margin-right: 0;
  }
}
