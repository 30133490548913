.u-euclid-flex {
  &-indent-fix {
    text-indent: em(-1.5px);
  }

  &-indent-fix-block {
    margin-left: em(-1.5px);
  }

  &-feature {
    font-feature-settings: "ss02" on, "ss05" on;
  }

  &-line-height-none {
    line-height: 0.75;
  }
}
