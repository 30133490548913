.awards-section {
  background-color: #e5e5e5;
  padding-top: rem(198);
  padding-bottom: rem(8);
  display: flex;
  flex-direction: column;
  .awards-header {
    margin-bottom: rem(128);
    .awards-title {
      h2 {
        font-size: rem(50);
      }
    }
    .awards-subtitle {
      margin-top: rem(14);
    }
  }
  .awards-body {
    margin-top: auto;
    .awards-body-left {
      margin-bottom: rem(60);
      .awards-count {
        font-size: rem(160);
        margin-bottom: rem(50);
        font-feature-settings: "tnum" on, "lnum" on;
      }
      .awards-project-lists {
        font-feature-settings: "cpsp" on, "ss02" on;
        display: flex;
        align-items: flex-end;
        .list-primary {
          margin-right: rem(68);
          font-size: rem(25);
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
          }
          li {
            letter-spacing: -0.02em;
            line-height: 0.99;
            &:not(:last-child) {
              margin-bottom: rem(9);
            }
          }
        }
        .list-secondary {
          max-width: 250px;
          font-size: rem(20);
          p {
            letter-spacing: -0.02em;
            line-height: 1.25;
            color: $color-gray-l31;
          }
        }
      }
    }
    .awards-body-right {
      .awards-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;
        & > div {
          padding-right: 8px;
          padding-left: 8px;
          margin-bottom: rem(60);
        }
        .awards-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 102px;
          .awards-img {
            margin-bottom: rem(7) !important;
            width: 70px;
            height: 70px;
          }
          p {
            text-align: center;
            font-size: rem(15);
            line-height: 1;
            margin-bottom: rem(4);
          }
          span {
            text-align: center;
            font-size: rem(13);
            line-height: 1.2;
            color: #909294;
          }
        }
      }
    }
  }
  @include media-max(xxl) {
    padding-top: rem(136);
    padding-bottom: rem(25);
    .awards-header {
      .awards-title {
        margin-bottom: rem(80);
      }
      .awards-subtitle {
        margin-top: 0;
      }
    }
    .awards-body {
      .awards-body-left {
        margin-bottom: rem(80);
        .awards-count {
          font-size: rem(114);
          margin-bottom: 0;
          line-height: 0.8;
        }
        .awards-project-lists {
          .list-primary {
            width: 50%;
            margin-right: 15px;
          }
        }
      }
      .awards-body-right {
        .awards-list {
          & > div {
            margin-bottom: rem(35);
          }
        }
      }
    }
  }
  @include media-max(md) {
    padding-top: rem(114);
    padding-bottom: rem(10);
    min-height: 1px;
    .awards-header {
      margin-bottom: rem(60);
      .awards-title {
        margin-bottom: rem(35);
        h2 {
          font-size: rem(30);
        }
      }
    }
    .awards-body {
      .awards-body-left {
        .awards-count {
          font-size: rem(65);
        }
        .awards-project-lists {
          flex-direction: column;
          align-items: flex-start;
          .list-primary {
            width: 100%;
            margin-right: 0;
            margin-bottom: rem(10);
            font-size: rem(30);
          }
          .list-secondary {
            font-size: rem(16);
            br {
              display: none;
            }
          }
        }
      }
    }
  }
  @include media-max(xs) {
    .awards-body .awards-body-left .awards-count {
      margin-bottom: rem(30);
    }
  }
}

.custom-scroll {
  .badge {
    left: calc(100vw - 153px);
  }
}
.badge {
  z-index: 10;
  position: fixed;
  width: 90px;
  height: 90px;
  bottom: 35%;
  left: calc(100vw - 145px);
}
#cssda-badge {
  /* monogram position here */
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  /* ––– */
  width: 90px;
  height: 90px;
  border-radius: 90px;
  background: #ededed;
  -webkit-transition: all 0.25s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  -moz-transition: all 0.25s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  transition: all 0.25s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  cursor: pointer;
  will-change: transform;
  
}
#cssda-badge:hover {
  -webkit-animation: bounce 0.75s linear both;
  -moz-animation: bounce 0.75s linear both;
  animation: bounce 0.75s linear both;
  background: #1f1f21;
}
#cssda-badge svg {
  position: absolute;
  width: 90px;
  height: 90px;
  fill: #000000;
}
#cssda-badge:hover svg {
  fill: #ededed;
}
.cssda-badge-content {
  position: absolute;
  width: 90px;
  height: 90px;
  -webkit-transition: all 0.12s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  -moz-transition: all 0.12s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  transition: all 0.12s cubic-bezier(0.615, 0.19, 0.305, 0.91);
}
#cssda-badge:hover .cssda-badge-content {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
#cssda-badge:hover #cssda-badge-logo {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

  @keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  -webkit-transform: scale3d(.9, .9, .9);
  transform: scale3d(.9, .9, .9);
  }
  20% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
  -webkit-transform: scale3d(.9, .9, .9);
  transform: scale3d(.9, .9, .9);
  }
  60% {
  opacity: 1;
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
  transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
  -webkit-transform: scale3d(.97, .97, .97);
  transform: scale3d(.97, .97, .97);
  }
  100% {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  }
  } 

@include media-max(xl) {
  .custom-scroll .badge {
    left: calc(100vw - 117px);
  }
  .badge {
    left: calc(100vw - 109px);
  }
}
@include media-max(md) {
  .custom-scroll .badge {
    left: calc(100vw - 82px);
  }
  .badge {
    left: calc(100vw - 82px);
    width: 60px;
    height: 60px;
    bottom: 32%;
  }
  .cssda-badge-content {
    width: 60px;
    height: 60px;
  }
  #cssda-badge {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    /* ––– */
    width: 60px;
    height: 60px;
  }
  #cssda-badge svg {
    width: 60px;
    height: 60px;
  }
  #cssda-badge-logo {
    -webkit-transform: scale(1.35);
    -moz-transform: scale(1.35);
    transform: scale(1.35);
  }
  #cssda-badge:hover #cssda-badge-logo {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    transform: scale(1.5);
  }
  #cssda-badge-title {
    display: none;
  }
  #cssda-badge-award {
    display: none;
  }
}
