.view-portfolio-item-mobile-version-section {
  @import "./vars";

  position: relative;

  &__container {
    padding-top: $view-portfolio-item-mobile-version-section-container_padding-top;
    padding-bottom: $view-portfolio-item-mobile-version-section-container_padding-bottom;
  }

  &__preview {
    margin-bottom: $view-portfolio-item-mobile-version-section-container-preview_margin-bottom;
  }

  &__header {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    z-index: 1;

    pointer-events: none;
  }

  &__title-svg {
    height: vw(48 / 414);
    width: auto;

    &--md {
      display: none;
    }
  }

  &__title-first-word {
    transform: translateY(percentage(-1 / 48));
  }

  &__title-second-word {
    text-align: right;
    transform: translateY(percentage(-15 / 48));
  }

  &__col-description {
    align-self: flex-end;
  }

  @include media-min(md) {
    &__container {
      min-height: rem(1200px);

      padding-top: $view-portfolio-item-mobile-version-section-container_padding-top_min-md;
      padding-bottom: $view-portfolio-item-mobile-version-section-container_padding-bottom_min-md;
    }

    &__preview {
      margin-bottom: $view-portfolio-item-mobile-version-section-container-preview_margin-bottom_min-md;
    }

    &__title {
      display: flex;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      overflow: hidden;
      height: 100vh;
      max-height: 100vh;
    }

    &__title-svg {
      display: none;

      &--md {
        width: vw(102 / 768);
        height: auto;
        display: block;
      }
    }

    &__title-first-word {
      transform: translate3d(percentage(52 / 102), 0, 0);
      padding-bottom: percentage(190 / 800);
    }

    &__title-second-word {
      transform: translate3d(percentage(28 / 102), 0, 0);
      margin-top: auto;
    }
  }

  @include media-min(lg) {
    &__container {
      min-height: rem(1050px);
      padding-top: $view-portfolio-item-mobile-version-section-container_padding-top_min-lg;
      padding-bottom: $view-portfolio-item-mobile-version-section-container_padding-bottom_min-lg;
    }

    &__preview {
      margin-bottom: $view-portfolio-item-mobile-version-section-container-preview_margin-bottom_min-lg;
    }

    &__preview,
    &__description {
      justify-content: flex-start;
    }

    &__title-first-word {
      padding-bottom: 0;
    }

    &__title-second-word {
      transform: translate3d(percentage(28 / 102), 0, 0);
      margin-top: auto;
    }

    &__title-svg {
      &--md {
        width: rem(122px);
      }
    }

    &__col-description {
      height: 100vh;
      align-items: center;
      display: flex;
    }
  }
}
