@import '/src/scss/mixins.scss';

.view-not-found {
  height: 100vh;

  &__inner,
  &__container,
  &__terminal {
    height: 100%;
  }

  &__inner {
    padding-top: rem(20px);
  }

  &__terminal {
    display: flex;
    flex-direction: column;

    font-size: rem(13px);
  }

  &__terminal-content {
    margin-bottom: rem(30px);
  }

  &__terminal-banner {
    font-size: vw(13 / 1440);
    margin-top: auto;
    white-space: pre;
  }

  &__terminal-link-indicator {
    margin-right: rem(15px);
  }

  // &__terminal-nav-list:hover
  //   &__terminal-link.active
  //   &__terminal-link-indicator-mark {
  //   opacity: 0;
  // }

  &__terminal-link.selected &__terminal-link-indicator-mark,
  &__terminal-link:hover &__terminal-link-indicator-mark {
    opacity: 0.3;
  }
  &__terminal-link.active &__terminal-link-indicator-mark {
    // &__terminal-nav-list:hover
    //   &__terminal-link:hover
    //   &__terminal-link-indicator-mark {
    opacity: 1;
  }

  &__terminal-nav-list {
    display: inline-block;
  }

  &__terminal-link {
    display: block;
  }

  &__terminal-loading {
    display: inline-block;
  }
  &__terminal-loading-letter {
    display: inline-block;
    opacity: 0;
  }

  &__terminal-nav,
  &__terminal-footer-divider {
    margin-bottom: rem(15px);
  }

  &__terminal-link-indicator-mark {
    display: inline-block;

    width: rem(5px);
    height: rem(5px);

    margin: 0 rem(1px) rem(1px) 0;

    vertical-align: middle;

    opacity: 0;

    background-color: currentColor;
    transition: opacity 0.2s;
  }

  @include media-min(md) {
    &__inner {
      padding-top: rem(45px);
    }

    &__terminal {
      font-size: rem(17px);
    }
  }
}
