.portfolio-item-section--type-git-9 {
    padding: 0;
}

.view-portfolio-globe-it-logos {
    & > .col {
        width: 50%;
        padding-top: 310px;
        padding-bottom: 53px;
        @media (max-width: 991px) {
            padding-top: 150px;
        }
        @media (max-width: 575px) {
            padding-top: 100px;
        }
        &:first-child {
            border-right: 1px solid #e1e1e1;
        }
        & > div {
            margin: auto;
            text-align: center;
            display: inline-block;
            
        }
        .info {
            margin-top: 214px;
            font-size: 18px;
            line-height: 1.3;
            color: #424242;
            display: inline-block;
            @media (max-width: 991px) {
                margin-top: 100px;
            }
            @media (max-width: 575px) {
                margin-top: 75px;
            }
        }
    }
}