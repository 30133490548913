$z-index_cursor: 9999;

.cursor {
  $size: 15px;
  $transition-duration: 0.2s;

  position: fixed;

  top: 0;
  left: 0;

  pointer-events: none;
  will-change: transform;

  z-index: $z-index_cursor;

  color: $color-white;

  mix-blend-mode: difference;

  &__inner {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    left: 50%;
    top: 50%;

    transform: translate3d(-50%, -50%, 0);

    transition: all $transition-duration;
  }

  &__body {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $size;
    height: $size;
  }

  &__tray {
    position: absolute;

    width: 100%;
    height: 100%;

    margin: auto;

    background-color: currentColor;
    transition: $transition-duration all;
  }

  &__label {
    position: absolute;
    opacity: 0;
    transform: translateY(10px);
    z-index: 1;

    color: $primary-color;

    transition: all $transition-duration 0s;
  }

  &__morph {
    position: absolute;
  }

  &__svg {
    transition: $transition-duration all;
  }

  &--type-none & {
    &__inner {
      opacity: 0;
    }
  }

  &--type-arrow-left &,
  &--type-arrow-right &,
  &--type-cross & {
    &__tray {
      visibility: hidden;
      transition: none;
    }
  }

  &--type-button & {
    &__tray {
      transform: scale(8.666666666666666, 2);
      transition-delay: 0s;
    }

    &__label {
      transition-delay: $transition-duration;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.cursor-outline {
  position: fixed;

  top: 0;
  left: 0;

  border: 1px solid currentColor;

  opacity: 0;

  z-index: $z-index_cursor;

  pointer-events: none;
}
