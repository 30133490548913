.gaps {
  @import "./vars";

  @each $breakpoint-name in $main-modifiers-breakpoints {
    $breakpoint-size: map-get($min-breakpoints, $breakpoint-name);
    $breakpoint-class-infix: make-breakpoint-infix($breakpoint-name, $breakpoint-size);

    @include media-breakpoint($breakpoint-size) {
      @each $gap-name, $gap-value in $gaps-list {
        $gap-value-half: $gap-value / 2;

        &--#{$gap-name}#{$breakpoint-class-infix},
        &--horizontal-#{$gap-name}#{$breakpoint-class-infix} {
          margin-left: -$gap-value-half;
          margin-right: -$gap-value-half;

          & > * {
            padding-left: $gap-value-half;
            padding-right: $gap-value-half;
          }
        }

        // .gaps--vertical-medium-
        &--#{$gap-name}#{$breakpoint-class-infix},
        &--vertical-#{$gap-name}#{$breakpoint-class-infix} {
          margin-top: -$gap-value-half;
          margin-bottom: -$gap-value-half;

          & > * {
            padding-top: $gap-value-half;
            padding-bottom: $gap-value-half;
          }
        }
      }
    }
  }
}
