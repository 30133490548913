.vimeo-player {
  &--size-fill {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &--controls-off {
    iframe {
      pointer-events: none;
    }
  }

  iframe {
    display: block;
  }
}
