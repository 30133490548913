.view-portfolio-atlantic-prices-cards-section {
  height: rem(600px);

  &__background {
    height: 100%;
    // margin-top: rem(100px);
    // margin-bottom: rem(105px);

    background-size: 768px auto;
    background-repeat: repeat-x;

    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: atlantic-move-cards;

    will-change: background-position-x;
    backface-visibility: hidden;
    transform: translateZ(0) rotate(0.001deg);
  }

  @keyframes atlantic-move-cards {
    to {
      background-position-x: 768px;
    }
  }

  @keyframes atlantic-move-cards-lg {
    to {
      background-position-x: 1440px;
    }
  }

  @include media-min(lg) {
    height: rem(1000px);

    &__background {
      animation-name: atlantic-move-cards-lg;
      background-size: 1440px auto;
    }
  }
}
