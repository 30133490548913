.title {
  @import "./vars";

  letter-spacing: $title_letter-spacing;
  font-size: $title_font-size;
  text-transform: uppercase;
  font-weight: $title_font-weight;
  line-height: $title_line-height;

  &--line-height-none {
    line-height: $title-line-height-none_line-height;
  }

  &--adaptive {
    font-size: vw(65px / 414px);
  }

  @include media-min(sm) {
    &--adaptive {
      font-size: $title_font-size;
    }
  }

  @include media-min(md) {
    font-size: $title_font-size_min-md;

    &--adaptive {
      font-size: $title_font-size_min-md;
    }
  }

  @include media-min(xxl) {
    font-size: $title_font-size_min-xxl;

    &--adaptive {
      font-size: $title_font-size_min-xxl;
    }
  }
}
