@import "/src/scss/mixins.scss";

.services-item-lead-section {

  &.technical-support {
    .title {
      font-size: font-to-vw(167px, 1440px);
      br {
        display: none;
      }

      @media (min-width: 1440px) {
        font-size: rem(167px);
      }

      @include media-max(lg) {
        font-size: font-to-vw(114px, 768px);
        br {
          display: block;
        }
      }
    }

    @media (max-width: 500px) {
      .title-list {
        p {
          font-size: rem(13px);
        }

        li {
          font-size: rem(13px);
        }
      }
    }
  }

  &.outstaffing {
    .title {
      font-size: font-to-vw(169px, 1440px);
      br {
        display: none;
      }

      @media (min-width: 1440px) {
        font-size: rem(169px);
      }

      @include media-max(lg) {
        font-size: font-to-vw(114px, 768px);
        br {
          display: block;
        }
      }
    }
  }

  &__container {
    padding-top: rem(52px);
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 4.375rem);

    @include media-min(md) {
      min-height: calc(100vh - 5.9375rem);
    }

    @include media-min(xl) {
      min-height: calc(100vh - 5.625rem);
    }

    @include media-max(md) {
      padding-top: rem(40px);
    }
  }

  .title {
    font-size: font-to-vw(169px, 1440px);
    color: #1f1f21;
    font-feature-settings: "cpsp"on, "ss02"on;
    font-weight: 400;
    line-height: 104%;
    letter-spacing: -4.64px;
    text-transform: uppercase;
    white-space: pre-wrap;

    @media (min-width: 1440px) {
      font-size: rem(169px);
    }

    @media (max-width: 1439px) {
      // font-size: 11.7vw;
      font-size: font-to-vw(169px, 1440px);
    }

    @include media-max(lg) {
      font-size: font-to-vw(114px, 768px);
    }

    @include media-max(md) {
      font-size: font-to-vw(58px, 414px);
    }
  }

  .title-list {
    ul>li {
      font-size: rem(15px);
      line-height: 100%;
      font-weight: 500;
      font-style: normal;
      line-height: 1.2;
    }

    @include media-max(lg) {
      margin-bottom: rem(20px);
    }

    &:first-child {
      margin-top: auto;
    }

    height: fit-content;
    max-height: min-content;

    >* {
      height: min-content;
    }
  }

  .list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(12px);

    @include media-max(xl) {
      margin-bottom: 150px;
    }

    @include media-max(md) {
      margin-bottom: 86px;
    }
  }

  &__body {
    row-gap: rem(20px);
    margin-top: 70px;

    @include media-max(xl) {
      row-gap: rem(80px);
      margin-top: 60px;
      margin-bottom: 158px;
    }

    @include media-max(md) {
      margin-top: 50px;
      margin-bottom: 100px;
    }
  }

  .service-heading {
    font-size: 25px;

    @media (max-width: 1439px) {
      font-size: 21px;
    }

    @include media-max(md) {
      font-size: 16px;
    }
  }

  &__subdescription {
    span {
      white-space: pre-wrap;
    }
  }

  &__awards {
    gap: rem(17px);
  }

  .weight-500 {
    font-weight: 500;
  }

  &__footer {
    margin-top: auto;
    align-items: flex-end;
    min-height: rem(200px);
  }

  &__works-company {
    margin-top: auto;
    width: 100%;
    column-gap: rem(30px);
    row-gap: rem(10px);

    @include media-max(xl) {
      margin-top: 0;
      margin-bottom: rem(25px);
    }

    @include media-max(md) {
      >div:last-child {
        margin-top: 15px;
      }
    }

    >div {
      flex-wrap: nowrap;

    }

    // img:not(:first-child){
    //   transform: translateX(-10px);
    // }
  }

  .aunchor-button {
    display: flex;

    >div {
      margin-top: auto;
    }

    @include media-max(md) {
      display: none;
    }
  }

  .our-clients-img {
    @include media-max(md) {
      width: rem(63px);
      height: rem(63px);
    }

    @include media-max(sm) {
      width: rem(50px);
      height: rem(50px);
    }
  }
}