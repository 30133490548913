/*! AnonymousPro  */

@include font-face("AnonymousPro",
  "/assets/fonts/AnonymousPro/AnonymousPro-Regular",
  $font-weight-regular);
// @include font-face("AnonymousPro", "/assets/fonts/AnonymousPro/AnonymousPro-Italic", $font-weight-regular, italic);
// @include font-face("AnonymousPro", "/assets/fonts/AnonymousPro/AnonymousPro-Bold", $font-weight-bold);
// @include font-face("AnonymousPro", "/assets/fonts/AnonymousPro/AnonymousPro-BoldItalic", $font-weight-bold, italic);

/*! EuclidFlex */

// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-Thin", $font-weight-thin);
// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-ThinItalic", $font-weight-thin, italic);

// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-UltraThin", $font-weight-thin);
// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-UltraThinItalic", $font-weight-thin, italic);

@include font-face("EuclidFlex",
  "/assets/fonts/EuclidFlex/EuclidFlex-Light",
  $font-weight-light);

@include font-face("EuclidFlex",
  "/assets/fonts/EuclidFlex/EuclidFlex-LightItalic",
  $font-weight-light,
  italic);

@include font-face("EuclidFlex",
  "/assets/fonts/EuclidFlex/EuclidFlex-Regular",
  $font-weight-regular);
// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-RegularItalic", $font-weight-regular, italic);

@include font-face("EuclidFlex",
  "/assets/fonts/EuclidFlex/EuclidFlex-Medium",
  $font-weight-medium);
// @include font-face("EuclidFlex", "/assets/fonts/EuclidFlex/EuclidFlex-RegularItalic", $font-weight-regular, italic);

/////////
@include font-face("Trap",
  "/assets/fonts/Trap/Trap-Regular",
  $font-weight-regular);

@include font-face("Trap",
  "/assets/fonts/Trap/Trap-Medium",
  $font-weight-medium);

@include font-face("Neue Machina",
  "/assets/fonts/NeueMachina/NeueMachina-Regular",
  $font-weight-regular);

@include font-face("Neue Machina",
  "/assets/fonts/NeueMachina/NeueMachina-Medium",
  $font-weight-medium);

@include font-face("Inter",
  "/assets/fonts/Inter/Inter-Regular",
  $font-weight-regular);

@include font-face("Inter",
  "/assets/fonts/Inter/Inter-Medium",
  $font-weight-medium);

.subheading {
  font-size: rem(50px);
  font-feature-settings: "ss02"on, "cpsp"on;
  font-style: normal;
  font-weight: 400;
  line-height: 99%;
  letter-spacing: -1px;
  color: $color-gray-l4;
  text-decoration: none;

  @include media-max(md) {
    font-size: rem(38px);
  }

  @include media-max(sm) {
    font-size: rem(27px);
  }
}

.italic-text {
  font-style: italic !important;
}

.text-left {
  text-align: left;
}

.heading-2 {
  font-size: rem(35px);
  font-style: normal;
  font-weight: 400;
  line-height: 99%;
  letter-spacing: -0.7px;

  @include media-max(md) {
    font-size: rem(30px);
  }
}

.heading-3 {
  font-size: rem(15px);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.heading-4 {
  font-size: rem(25px);
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-transform: uppercase;

  @include media-max(md) {
    font-size: rem(20px);
  }

  @include media-max(sm) {
    font-size: rem(16px);
  }
}

.heading-6 {
  font-size: rem(16px);
  font-style: normal;
  line-height: 110%;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none !important;
  color: $color-shark-1;


  @include media-max(sm) {
    font-size: rem(14px);
  }
}

.caption-black {
  font-size: rem(15px);
  color: $color-shark-1;
}

.caption {
  font-size: rem(15px);
  color: $color-gray-7;
}

.footnote {
  font-size: rem(14px);
  color: $color-gray-7;
  text-decoration: none;
}

.simple-parallax-initialized {
  height: 100%;

  picture {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
  }
}

.heading {
  text-transform: uppercase;
}

.scroll-down-button-with-text {
  gap: rem(10px);

  p {
    font-style: italic;
    text-align: left;
    text-transform: uppercase;
  }

  i {
    width: rem(30px);
    height: auto;
  }
}

.m-t-auto {
  margin: auto 0;
}

.m-top-auto {
  margin-top: auto;
}