@import './vars';

.portfolio-item-section {
  //экран4
  &--type-opzero-4 {
    padding-top: rem(313);
    padding-bottom: rem(150);
  }

  //экран5
  &--type-opzero-5 {
    padding-top: rem(163);
    padding-bottom: rem(150);
  }

  //экран6
  &--type-opzero-6 {
    padding-top: rem(194);
    padding-bottom: rem(150);
    .simpleParallax {
      padding-left: 11%;
      padding-right: 11%;
    }
  }

  //экран7
  &--type-opzero-7 {
    padding-top: rem(163);
    padding-bottom: rem(150);
  }

  //экран8
  &--type-opzero-8 {
    padding-top: rem(78);
    padding-bottom: rem(343);
  }

  //экран10
  &--type-opzero-10 {
    .opzero-section-video {
      aspect-ratio: 1.458/1;
    }
  }
}
