.view-portfolio-atlantic-inner-pages-section {
  @import "./vars";

  &__header {
    margin-bottom: $view-portfolio-atlantic-inner-pages-section-header_margin-bottom;
  }

  &__header-list {
    display: flex;
    flex-wrap: wrap;

    .list__item:not(:last-child) {
      margin-right: rem(25px);
    }
  }

  @include media-min(md) {
    &__header {
      margin-bottom: $view-portfolio-atlantic-inner-pages-section-header_margin-bottom_min-md;

      justify-content: center;
    }

    &__header-list .list {
      &__item:not(:last-child) {
        margin-bottom: rem(2px);
      }
    }
  }

  @include media-min(xxl) {
    &__header {
      margin-bottom: $view-portfolio-atlantic-inner-pages-section-header_margin-bottom_min-xxl;

      justify-content: flex-start;
    }
  }
}
