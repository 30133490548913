.view-portfolio-item-about-section--type-clt {
  padding-top: rem(160);
  padding-bottom: rem(220);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 52%;
  }
}

.view-portfolio-clt-section {
  .description {
    row-gap: rem(20);
    margin-bottom: rem(200);
    @include media-max(md) {
      margin-bottom: rem(100);
    }
  }
}

.portfolio-item-section {

  &--type-clt-3 {
    padding-top: rem(220);
    padding-bottom: rem(310);
    @include media-max(md) {
      padding-top: rem(100);
      margin-bottom: rem(100);
    }
  }

  &--type-clt-4 {
    .u-ratio {
      padding-top: 85.4%;
    }
  }

  &--type-clt-5 {
    padding-top: rem(235);
    padding-bottom: rem(246);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);
    }

    .img-wrap {
      margin-bottom: rem(214);
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }
  }

  &--type-clt-6 {
    .u-ratio {
      padding-top: 73%;
    }
  }

  &--type-clt-7{
    padding-top: rem(235);
    padding-bottom: rem(266);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(100);
    }

    .img-wrap {
      margin-bottom: rem(260);
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }
  }

  &--type-clt-9 {
    padding-top: rem(235);
    padding-bottom: rem(340);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }

    .img-wrap {
      margin-bottom: rem(350);
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }
  }

  &--type-clt-10 {
    .u-ratio {
      padding-top: 73.2%;
    }
  }

  &--type-clt-11 {
    padding-top: rem(235);
    padding-bottom: rem(310);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }

    .img-wrap {
      margin-bottom: rem(260);
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }
  }
}