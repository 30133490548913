.portfolio-item-section--type-ens-11 {
  padding-top: rem(206);
  padding-bottom: rem(286);
  .portfolio-item-section {
    &__figure-caption {
      transform: translateY(100%);
      &-item--bottom {
        text-indent: 16%;
      }
    }
  }
  @include media-max(xl) {
    padding-top: rem(150);
    padding-bottom: rem(200);
    .portfolio-item-section {
      &__figure-caption {
        &-item--bottom {
          text-indent: 40%;
        }
      }
    }
  }
  @include media-max(md) {
    padding: rem(100) rem(24) rem(150);
    .portfolio-item-section {
      &__figure-caption {
        transform: translateY(0);
        margin-bottom: rem(60);
        &-item--bottom {
          text-indent: 0;
        }
      }
    }
  }
}
