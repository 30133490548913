@import "/src/scss/mixins.scss";

.services-item-swiper-section {
  background-color: #F3F4F4;

  &__slide-container {
    margin: rem(55px);
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include media-max(lg) {
      margin: rem(55px) rem(24px);
    }
  }

  &__slide {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }

  &__swiper {
    height: 100%;
  }

  &__list {
    margin-top: 133px;
    font-size: 14px;
    line-height: 1.1;

    &>div {
      padding-top: 5px;
      font-size: 15px;
      line-height: 1.2;
    }

    @include media-max(lg) {
      margin-top: 120px;
    }

    @include media-max(md) {
      margin-top: 30px;
      font-size: 14px;
    }
  }

  .slider-head {
    &>div {
      width: 100%;
    }
  }

  &__right-top-list {
    text-align: right;

    >span {
      font-size: 14px;
      line-height: 1.3;
    }

    @include media-max(md) {
      text-align: left;
      margin-bottom: 40px;
      gap: 8px;
    }

    @include media-max(sm) {
      >span {
        font-size: rem(12px);
      }
    }
  }

  &__title {
    margin-bottom: rem(20px);
  }

  &__footer-logo {
    >div {
      margin-top: auto;
    }
  }

  &__next-slide-button {

    &:hover {
      span>div {
        transform: translateX(rem(15px)) rotate(270deg);
      }
    }

    span>div {
      transform: rotate(270deg);
      margin-left: rem(14px);
      width: rem(30px);
      height: rem(30px);
      background-size: cover;

      @include media-max(lg) {
        margin-top: -6px;
      }
    }

    span.button__label {
      width: fit-content;
    }

    span {
      margin: auto 0;
      text-align: left;

      @include media-max(lg) {
        margin: 0 0;
      }
    }

    p {
      @include media-max(lg) {
        width: min-content;
        white-space: initial;
      }
    }
  }
}