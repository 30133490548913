$text_font-size: rem(18px);
$text_line-height: $line-height-sm;

$text-accent_letter-spacing: -$letter-spacing-sm;
$text-accent_line-height: 1.14;

$text-light_color: $color-white;
$text-muted_color: $color-jumbo;

$text-medium_font-size: rem(18px);
$text-big_font-size: rem(21px);

$text-indent-breakpoints: xxs, md, xxl;

$text-indents: (
  half: percentage(1 / 2),
  third: percentage(1 / 3),
  quarter: percentage(1 / 4),
);

$text-font-sizes: (
  small: 14px,
  medium: 16px,
);
