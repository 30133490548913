.smooth-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__scroller {
    will-change: transition;
    backface-visibility: hidden;
  }
}
