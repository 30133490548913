.portfolio-item-section{
  &--type-mumu-5{
    padding-bottom: rem(520);
    padding-top: rem(520);
    .poster-version{
      &:not(:last-child){
        margin-bottom: rem(700);
      }
      img{
        margin-bottom: rem(20);
      }
      p{
        text-align: right;
        font-weight: 400;
        font-size: rem(30);
        text-transform: uppercase;
      }
    }
  }
  &--type-mumu-8{
    padding-bottom: rem(284);
    padding-top: rem(340);
  }
  &--type-mumu-9{
    padding-bottom: rem(480);
  }
  @include media-max(xl) {
    &--type-mumu-5{
      padding-bottom: rem(320);
      padding-top: rem(320);
      .poster-version{
        &:not(:last-child){
          margin-bottom: rem(400);
        }
      }
    }
    &--type-mumu-8{
      padding-bottom: rem(210);
      padding-top: rem(210);
    }
    &--type-mumu-9{
      padding-bottom: rem(340);
    }
  }
  @include media-max(md) {
    &--type-mumu-5{
      padding-bottom: rem(200);
      padding-top: rem(200);
      .poster-version{
        &:not(:last-child){
          margin-bottom: rem(250);
        }
        p{
          font-size: rem(20);
        }
      }
    }
    &--type-mumu-9{
      padding-bottom: rem(200);
    }
  }

}