.label {
  @import "./vars";

  font-size: $label_font-size;
  font-weight: $label_font-weight;
  line-height: $label_line-height;
  text-transform: uppercase;
  white-space: pre-line;
  letter-spacing: normal;

  &--uppercase-none {
    text-transform: none;
  }
}
