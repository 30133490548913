.view-portfolio-item-about-section {
  @import "./vars";
  &.csd-site{
    padding-top: rem(160);
    .view-portfolio-item-about-section__top {
      margin-bottom: rem(77);
    }
    .u-ratio{
      padding-top: 56.15%;
      border: 1px solid #D7D7D7;
    }
  }
  // &__container {
  //   padding-top: $view-portfolio-item-about-section-container_padding-top;
  //   padding-bottom: $view-portfolio-item-about-section-container_padding-bottom;
  // }

  &__top {
    margin-bottom: $view-portfolio-item-about-section-top_margin-bottom;
  }

  &__middle {
    margin-bottom: $view-portfolio-item-about-section-middle_margin-bottom;
  }

  &__middle-image {
    &--logo {
      margin: rem(100px) auto rem(50px) auto;
      width: auto;
    }
  }

  &__video {
    pointer-events: none;
  }

  &__bottom .definition__description {
    font-feature-settings: "cpsp" on, "ss02" on;
  }

  @include media-min(md) {
    // &__container {
    //   padding-top: $view-portfolio-item-about-section-container_padding-top_min-md;
    //   padding-bottom: $view-portfolio-item-about-section-container_padding-bottom_min-md;
    // }

    &.csd-site{
      padding-top: rem(250);
    }

    &__top {
      margin-bottom: $view-portfolio-item-about-section-top_margin-bottom_min-md;
    }

    &__top-container {
      display: flex;
      justify-content: center;
    }

    &__top-inner {
      max-width: $view-portfolio-item-about-section-top-inner_max-width_min-md;
    }

    &__middle {
      margin-bottom: $view-portfolio-item-about-section-middle_margin-bottom_min-md;
    }

    &__middle-image {
      &--logo {
        margin: rem(160px) auto rem(80px) auto;
      }
    }
  }

  @include media-min(xl) {
    // &__container {
    //   padding-top: $view-portfolio-item-about-section-container_padding-top_min-xxl;
    //   padding-bottom: $view-portfolio-item-about-section-container_padding-bottom_min-xxl;
    // }

    &.csd-site{
      padding-top: rem(305);
    }

    &__top {
      margin-bottom: $view-portfolio-item-about-section-top_margin-bottom_min-xl;
    }

    &__middle {
      margin-bottom: $view-portfolio-item-about-section-middle_margin-bottom_min-xl;
    }

    &__top-container {
      justify-content: flex-end;
    }

    &__middle-image {
      &--logo {
        margin: rem(260px) auto rem(100px) auto;
      }
    }
  }
}
