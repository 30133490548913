.footer {
  @import "./vars";

  position: relative;
  overflow: hidden;

  min-height: rem(734px);
  height: 100vh;

  background-color: $footer_background-color;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    padding-top: $footer-container_padding-top;
    padding-bottom: $footer-container_padding-bottom;

    @include media-max(md) {
      padding-top: 9vh;
    }
  }

  &__main {
    // margin-bottom: $footer-main_margin-bottom;
  }

  &__heading {
    font-feature-settings: "cpsp" on, "ss02" on;
    font-size: rem(27px);
    white-space: pre-line;
  }

  &__header {
    margin-bottom: $footer-header_margin-bottom;
  }

  &__contacts {
    margin-bottom: $footer-contacts_margin-bottom;
  }

  &__contacts-group:not(:last-child) {
    margin-bottom: $footer-contacts-group-not-last_margin-bottom;
  }

  &__socials-item:not(:last-child) {
    margin-bottom: $footer-socials-item-not-last_margin-bottom;
  }

  &__col-with-copyright {
    margin-bottom: $footer-col-with-copyright_margin-bottom;
  }

  @include media-max(xl) {
    &__copyright,
    &__policy-link {
      font-size: $footer-copyright-and-policy-link_font-size_max-xl;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $footer-container_padding-top_min-md;
    }

    &__main {
      // margin-bottom: $footer-main_margin-bottom_min-md;
    }

    &__header {
      margin-bottom: $footer-header_margin-bottom_min-md;
    }

    &__heading {
      margin-top: -0.28em;
    }

    &__contacts {
      margin-bottom: $footer-contacts_margin-bottom_min-md;
    }

    &__col-with-copyright {
      margin-bottom: $footer-col-with-copyright_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $footer-container_padding-top_min-xl;
    }

    &__main {
      // margin-bottom: $footer-main_margin-bottom_min-xxl;
    }

    &__heading {
      white-space: pre-line;
    }

    &__contacts {
      margin-bottom: $footer-contacts_margin-bottom_min-xl;
    }

    &__heading {
      font-feature-settings: "cpsp" on, "ss02" on;
      font-size: rem(41px);
    }
  }

  // @include media-min(xxl) {
  //   &__heading {
  //     font-size: vw(50 / 1440);
  //   }
  // }
}
