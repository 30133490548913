.definition {
  @import "./vars";

  position: relative;
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: $definition-label_margin-bottom;
  }

  &__description,
  &__term {
    font-weight: $definition-term-and-description_font-weight;
    line-height: $definition-term-and-description_line-height;
    letter-spacing: $definition-term-and-description_letter-spacing;
  }

  &__description {
    font-size: $definition-description_font-size;
  }

  &__description--item {
    display: block;
  }

  &__description-item:not(:last-child) {
    margin-bottom: 1em;
  }

  &__description--item:not(:last-child) {
    margin-bottom: $definition-term-and-description_line-height * 1em;
  }

  &__term {
    margin-bottom: $definition-term_margin-bottom;

    font-size: $definition-term_font-size;
    font-style: italic;
    text-transform: uppercase;
  }

  @include media-min(md) {
    &__description {
      font-size: $definition-description_font-size_min-md;
    }

    &__term {
      font-size: $definition-term_font-size_min-md;
    }

    &__label {
      margin-bottom: $definition-label_margin-bottom_min-md;
    }
  }

  @include media-min(lg) {
    &__label {
      position: absolute;

      margin-bottom: 0;
    }

    &__term {
      margin-top: $definition-term_margin-top;

      text-align: right;
    }

    &__description--item:not(:first-child) {
      text-indent: 66%;
    }
  }
}
