.view-portfolio-item-section.cfps-site {
  padding-top: rem(160);
}

.portfolio-item-section {
  &--type-cfps-3 {
    height: 1000px;
    .left-wrap {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-top: rem(50);
      padding-bottom: rem(50);

      .img-wrap {
        max-width: 300px;
        margin: auto;
      }
    }

    .right-wrap {
      display: flex;
      flex-direction: column;
      width: 50%;

      .top-wrap {
        padding-top: rem(50);
        padding-bottom: rem(85);
        background-color: #1F504B;

        .description {
          margin-bottom: rem(17);
          text-align: right;
        }

        .img-wrap {
          max-width: rem(204);
          margin: auto;
        }
      }

      .bottom-wrap {
        display: flex;
        height: 100%;
        background-color: #fff;

        .img-wrap {
          max-width: rem(246);
          margin: auto;
        }
      }
    }

    .description {
      white-space: pre-line;
      font-size: rem(18);
      line-height: 1;
    }
  }

  &--type-cfps-4 {
    .left-wrap {
      width: 50%;

      &.img-wrap {
        div {
          height: 100%;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .right-wrap {
      width: 50%;
      padding-top: rem(50);
      padding-bottom: rem(95);
      .description {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(26);
      }
      .img-wrap {
        max-width: rem(370);
        margin: auto;
      }
    }
  }

  &--type-cfps-5 {
    padding-top: rem(205);
    padding-bottom: rem(160);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(240);

      p {
        font-size: rem(18);
      }

      span {
        display: inline-block;
        max-width: rem(360);
      }
    }

    .u-ratio {
      padding-top: 47%;
    }
  }

  &--type-cfps-6 {
    padding-top: rem(207);
    padding-bottom: rem(250);
    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .about-company{
      margin-bottom: rem(150);
      .menu-company {
        display: flex;
        gap: 8px;
        margin-bottom: rem(225);
      }
      .img-wrap {
        &:not(:last-child){
          margin-bottom: rem(20);
        }
      }
    }
  }

  &--type-cfps-7 {
    padding-top: rem(50);
    .caption {
      margin-bottom: rem(108);
      white-space: pre-line;
    }
    .left-wrap {
      margin-top: rem(110);
      margin-bottom: rem(200);
      padding-left: rem(60);
    }
    .right-wrap {
      display: flex;
      flex-direction: column;
      .description {
        max-width: rem(384);
        margin-bottom: rem(90);
        padding-right: rem(24);
      }
      .img-wrap {
        margin-top: auto;
      }
    }
  }

  &--type-cfps-8 {
    position: relative;
    .top-wrap {
      padding-top: rem(60);
      height: 35vw;

      .caption {
        font-size: 4.17vw;
        line-height: 1;
        white-space: pre-line;
      }
    }
    .bottom-wrap {
      height: 35vw;
      padding-top: 40px;
      background-color: #fff;
      .description {
        max-width: rem(360);
        color: #1F504B;
      }
    }
    .img-wrap {
      position: absolute;
      right: 0;
      bottom: 0;

      img {
        width: 100%;
      }
    }
  }

  @include media-max(md) {
    &--type-cfps-3 {
      height: auto;
      .left-wrap {
        width: 100%;
        .description {
          margin-bottom: rem(15);
        }
      }
      .right-wrap {
        width: 100%;
      }

      .bottom-wrap {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    &--type-cfps-4 {
      .left-wrap {
        width: 100%;
      }
      .right-wrap {
        width: 100%;
        padding-bottom: rem(50);
      }
    }
    &--type-cfps-5 {
      padding-top: rem(50);
      padding-bottom: rem(10);

      .description {
        flex-direction: column;
        margin-bottom: rem(50);

        p {
          margin-bottom: rem(30);
        }

        span {
          max-width: 100%;
        }
      }
    }
    &--type-cfps-6 {
      padding-top: rem(50);
      padding-bottom: rem(50);

      .description {
        margin-bottom: rem(20);
        align-items: flex-end;
        p {
          max-width: rem(150);
        }
      }

      .about-company {
        margin-bottom: rem(50);

        .menu-company {
          flex-direction: column;
          margin-bottom: rem(50);
        }
      }
    }
    &--type-cfps-7 {
      .caption {
        margin-bottom: rem(30);
      }
      .left-wrap {
        margin-top: 0;
        margin-bottom: rem(90);
        padding-left: rem(24);
        padding-right: rem(24);
      }
      .right-wrap {
        .description {
          padding-left: rem(24);
          font-size: rem(16);
        }
        .img-wrap {
          padding-left: rem(24);
        }
      }
    }
    &--type-cfps-8 {
      .top-wrap {
        height: auto;
        padding-top: rem(50);
        padding-bottom: rem(50);
        .caption {
          font-size: rem(20);
        }
      }
      .bottom-wrap {
        height: auto;
        padding-top: rem(50);
        padding-bottom: rem(50);
        .description {
          font-size: rem(16);
        }
      }
      .img-wrap {
        position: relative;
        background: #fff;
        padding-left: rem(24);
      }
    }
  }
}