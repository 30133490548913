.portfolio-item-section--type-csd-5 {
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding-top: rem(100);
  padding-bottom: rem(240);
  .portfolio-item-section {
    &__btn {
      display: flex;
      flex-direction: column;
      margin-bottom: rem(164);
      align-items: self-start;
      gap: rem(21);
      svg {
        fill: #2F6AF2;
      }
    }
    &__img {
      width: 23.5%;
    &-item--left {
        position: absolute;
        top: calc(340px + 11%);
      }
    &-item--center {
        margin-top: rem(50);
        margin-left: 39.2%;
        .video-screen5{
          .u-ratio{
            padding-top: 171.8%;
          }
        }
      }
    }
  }
  @include media-max(xl) {
    padding-bottom: rem(100);
    .portfolio-item-section {
      &__btn {
        margin-bottom: rem(64);
      }
      &__img {
        width: 27%;
        &-item--left {
          top: calc(200px + 18%);
        }
        &-item--center {
          margin-left: 37.2%;
        }
      }
    }
  }
  @include media-max(md) {
    .portfolio-item-section {
      &__btn {
        .icon__svg {
          height: 80%;
        }
      }
      &__img {
        width: 47%;
        &-item--left {
          position: static;
          width: 70%;
          margin-bottom: 100px;
        }
        &-item--right {
          margin-left: auto;
        }
        &-item--center {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }
}