.view-portfolio-nova-group-gallery-section {
  @import "./vars";

  &__first-group {
    &-col-with-image {
      // display: flex;
      // justify-content: center;

      &:not(:last-child) {
        margin-bottom: $view-portfolio-nova-group-gallery-section-first-group-col-with-image_margin-bottom;
      }
    }

    &-col-with-image-inner {
      max-width: $view-portfolio-nova-group-gallery-section-first-group-col-with-image-inner_max-width;
      // width: 100%;
    }

    &-col-with-image-group {
      display: none;
    }

    &-image {
      width: 100%;
    }

    &-col-with-shown-pages {
      display: none;
    }

    &-col-with-info {
      margin-bottom: $view-portfolio-nova-group-gallery-section-first-group-col-with-info_margin-bottom;
    }

    &-info-size-list {
      display: none;
    }
  }

  // second-group
  &__second-group {
    // &-container {
    //   padding-top: $view-portfolio-nova-group-gallery-section-second-group-container_padding-top;
    //   padding-bottom: $view-portfolio-nova-group-gallery-section-second-group-container_padding-bottom;
    // }

    &-col-with-first-image {
      margin-bottom: $view-portfolio-nova-group-gallery-section-second-group-col-with-first-image_margin-bottom;
    }

    &-col-with-second-image {
      margin-bottom: $view-portfolio-nova-group-gallery-section-second-group-col-with-second-image_margin-bottom;
    }

    &-image {
      margin-left: auto;
      margin-right: auto;
    }

    &-col-with-info--first {
      display: none;
    }
  }

  // third-group
  &__third-group {
    // &-container {
    //   padding-top: $view-portfolio-nova-group-gallery-section-third-group-container_padding-top;
    //   padding-bottom: $view-portfolio-nova-group-gallery-section-third-group-container_padding-bottom;
    // }

    &-col-with-info {
      margin-bottom: $view-portfolio-nova-group-gallery-section-third-group-col-with-info_margin-bottom;
    }

    &-image {
      width: 100%;
    }
  }

  @include media-min(sm) {
    &__first-group {
      &-col-with-info {
        margin: 0 auto
          $view-portfolio-nova-group-gallery-section-first-group-col-with-info_margin-bottom_min-md
          auto;

        max-width: $view-portfolio-nova-group-gallery-section-first-group-col-with-info_max-width;
      }
    }
  }

  @include media-min(md) {
    &__first-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-first-group-container_padding-top_min-md;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-first-group-container_padding-bottom_min-md;
      // }

      &-col-with-image:not(:last-child) {
        margin-bottom: $view-portfolio-nova-group-gallery-section-first-group-col-with-image_margin-bottom_min-md;
      }

      &-col-with-image-group {
        display: flex;
      }
    }

    &__second-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-second-group-container_padding-top_min-md;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-second-group-container_padding-bottom_min-md;
      // }

      &-col-with-first-image {
        margin-bottom: $view-portfolio-nova-group-gallery-section-second-group-col-with-first-image_margin-bottom_min-md;
      }

      &-col-with-second-image {
        margin-bottom: $view-portfolio-nova-group-gallery-section-second-group-col-with-second-image_margin-bottom_min-md;
      }

      &-info {
        width: 100%;
        max-width: $view-portfolio-nova-group-gallery-section-second-group-info_max-width_min-md;
      }
    }

    //
    &__third-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-third-group-container_padding-top_min-md;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-third-group-container_padding-bottom_min-md;
      // }

      &-col-with-info {
        margin-bottom: $view-portfolio-nova-group-gallery-section-third-group-col-with-info_margin-bottom_min-md;
      }

      &-info {
        max-width: $view-portfolio-nova-group-gallery-section-third-group-info_max-width_min-md;
      }
    }
  }

  @include media-min(xl) {
    &__first-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-first-group-container_padding-top_min-xxl;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-first-group-container_padding-bottom_min-xxl;
      // }

      &-col-with-image:not(:last-child) {
        margin-bottom: 0;
      }

      &-col-with-image-inner {
        max-width: none;
        width: 100%;
      }

      &-col-with-image-group {
        padding-left: 0;
        padding-right: 0;
      }

      &-col-with-image.move-below-xxl {
        align-items: flex-end;
      }

      &-col-with-shown-pages {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }

      &-col--move-below-xl {
        margin-top: $view-portfolio-nova-group-gallery-section-first-group-col-move-below-xxl_margin-top_min-xxl;
      }

      &-col-with-info {
        align-content: flex-start;
        margin: 0;
      }

      &-info-size-list {
        display: block;

        margin-top: $view-portfolio-nova-group-gallery-section-first-group-info-size-list_margin-top_min-xxl;
      }
    }

    &__second-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-second-group-container_padding-top_min-xxl;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-second-group-container_padding-bottom_min-xxl;
      // }

      &-col-with-first-image {
        display: flex;
        flex-direction: column;

        margin-bottom: 0;
      }

      &-col-with-second-image {
        margin-bottom: 0;
      }

      &-second-image {
        margin-top: $view-portfolio-nova-group-gallery-section-second-group-second-image_margin-top_min-xxl;
      }

      &-image {
        width: 100%;
      }

      &-col-with-info--first {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-col-with-info--second {
        display: none;
      }
    }

    &__third-group {
      // &-container {
      //   padding-top: $view-portfolio-nova-group-gallery-section-third-group-container_padding-top_min-xxl;
      //   padding-bottom: $view-portfolio-nova-group-gallery-section-third-group-container_padding-bottom_min-xxl;
      // }

      &-col-with-info {
        margin-bottom: $view-portfolio-nova-group-gallery-section-third-group-col-with-info_margin-bottom_min-xxl;
      }
    }
  }
}
