.view-portfolio-skess-gallery-section {
  @import "./vars";

  padding: rem(150px) 0;

  &__group-col-with-list,
  &__group:not(:last-child) {
    margin-bottom: rem(150px);
  }

  @include media-min(md) {
    padding: rem(200px) 0;

    &__group-col-with-list,
    &__group:not(:last-child) {
      margin-bottom: rem(200px);
    }
  }

  @include media-min(lg) {
    padding: rem(250px) 0;

    &__group:not(:last-child) {
      margin-bottom: rem(250px);
    }

    &__group-col-with-list {
      padding-top: rem(200px);
    }
  }
}
