@import './vars';
@import './view-portfolio-doma-veka-gallery-section/view-portfolio-doma-veka-gallery-section.scss';

.portfolio-item-section {
  //экран3
  &--type-doma-veka-site-3 {
    overflow: hidden;
    .doma-veka-section {
      position: relative;

      &__image {
        width: 50%;

        &--1 {
        }
        &--2 {
        }
      }
    }
  }

  //экран4
  &--type-doma-veka-site-4 {
    overflow: hidden;
    position: relative;
    .doma-veka-section {
      &__background {
        position: absolute;
        top: rem(-26);
        left: 19.6%;
        width: 93.5%;
      }

      &__image {
        width: 18.6%;

        &--1 {
          margin-left: 12.5%;
          margin-top: rem(726);
          margin-bottom: rem(330);
        }
        &--2 {
          margin-left: 31.4%;
          margin-top: rem(394);
        }
      }
    }
  }
  //экран5
  &--type-doma-veka-site-5 {
    padding-top: rem(140);
    color: $view-portfolio-item_primary-text-color;
    text-align: center;
    font-style: normal;
    .doma-veka-section {
      &__title {
        margin: 0 auto;
        font-size: rem(125);
        line-height: 0.9;
        letter-spacing: -0.03em;
        text-transform: uppercase;
      }
      &__subtitle {
        margin-bottom: rem(142);
        text-transform: lowercase;
        font-size: rem(20);
        line-height: 1;
      }
      &__info-text {
        position: relative;
        width: 100vw;
        margin-left: rem(-60);
        height: rem(120);
        display: grid;
        place-items: center;
        border-top: rem(1) solid $view-portfolio-item_border-color;
        border-bottom: rem(1) solid $view-portfolio-item_border-color;
        color: $view-portfolio-item_tertiary-text-color;
        font-size: rem(16);
        line-height: 1;
      }
      &__text {
        display: inline-flex;
        align-items: center;

        &::after {
          content: '';
          display: inline-block;

          margin: 0 rem(15);
          width: rem(177);
          height: rem(1);
          background-color: $view-portfolio-item_tertiary-text-color;
        }
      }
      &__img {
        margin: 0 auto;
        padding: rem(103) rem(91) rem(170) rem(91);
        border-left: rem(1) solid $view-portfolio-item_border-color;
        border-right: rem(1) solid $view-portfolio-item_border-color;
      }
      &--ru {
        .doma-veka-section__title {
          margin-bottom: rem(25);
        }
      }
      &--en {
        .doma-veka-section__title {
          margin-bottom: rem(15);
        }
      }
    }
  }
  //экран6
  &--type-doma-veka-site-6 {
    .doma-veka-section {
      &__video {
        width: 100%;
        aspect-ratio: 0.9079/1;
      }
    }
  }
  //экран7
  &--type-doma-veka-site-7 {
    color: #383838;
    font-size: rem(16);
    line-height: 1;
    text-transform: lowercase;

    .doma-veka-section {
      &__video {
        width: 100%;
        aspect-ratio: 1.466/1;
      }
      &__background {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        width: 100%;
        aspect-ratio: 6.89/1;
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
      &__text-container {
        width: 100%;
        padding: 0 15.5%;
        text-align: end;
        margin-bottom: 40px;
      }
      &__text {
        display: inline-flex;
        align-items: center;

        &::after {
          content: '';
          display: inline-block;

          margin: 0 rem(6);
          width: rem(160);
          height: rem(1);
          background-color: $view-portfolio-item_primary-text-color;
        }
      }
    }
  }
  //экран8
  &--type-doma-veka-site-8 {
    .doma-veka-section {
      &__video {
        width: 100%;
        aspect-ratio: 0.8/1;
      }
    }
  }
  //экран9
  &--type-doma-veka-site-9 {
    .doma-veka-section {
      &__video {
        width: 100%;
        aspect-ratio: 0.684/1;
      }
      &__info-text {
        position: relative;
        margin-top: rem(67);
        width: 100%;
        height: rem(120);
        display: grid;
        place-items: center;
        border-top: rem(1) solid $view-portfolio-item_tertiary-text-color;
        border-bottom: rem(1) solid $view-portfolio-item_tertiary-text-color;
        color: $view-portfolio-item_quatenary-text-color;
        font-size: rem(16);
        line-height: 1;
      }
      &__text {
        display: inline-flex;
        align-items: center;

        &::after {
          content: '';
          display: inline-block;
          margin: 0 rem(6);
          width: rem(159);
          height: rem(1);
          background-color: $view-portfolio-item_secondary-border-color;
        }
      }
      &__images {
        padding: 0;
        display: flex;
        column-gap: rem(13.5);
      }
      &__image-wrapper {
        flex-grow: 1;
        &:first-of-type {
          margin-left: rem(-20);
        }
        &:last-of-type {
          margin-right: rem(-20);
        }
      }
      &__image {
        max-width: unset;
      }
    }
  }
  //экран10
  &--type-doma-veka-site-10 {
    padding-top: rem(115);
    color: $view-portfolio-item_primary-text-color;
    font-size: rem(114);
    line-height: 1.1;
    text-transform: uppercase;
    .doma-veka-section {
      &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(179);
        &::after {
          content: '';
          background-color: $view-portfolio-item_tertiary-border-color;
          width: 100%;
          height: rem(1);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      &__description {
        margin-top: rem(17);
        font-size: rem(18);
        text-transform: none;
        line-height: 1;
        &--ru {
          max-width: rem(235);
        }
        &--en {
          max-width: rem(202);
        }
      }

      &__video {
        width: 100%;
        aspect-ratio: 1.33/1;
      }
    }
  }
  //экран11
  &--type-doma-veka-site-11 {
    color: $view-portfolio-item_primary-text-color;
    font-size: rem(14);
    line-height: 1;
    text-transform: lowercase;

    padding-top: rem(135);
    padding-bottom: rem(230);
    position: relative;
    .doma-veka-section {
      &__image {
        width: 62.9%;
        margin: 0 auto;
        margin-top: rem(111);
      }
      &__text-container {
        width: 62.9%;
        margin: 0 auto;
        justify-content: flex-end;
        column-gap: rem(216);
      }

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  //экран12
  &--type-doma-veka-site-12 {
    padding-top: rem(135);
    padding-bottom: rem(320);
    color: $view-portfolio-item_primary-text-color;
    font-size: rem(14);
    line-height: 1;
    text-transform: lowercase;
    .row {
      margin-bottom: rem(165);
    }
  }

  @include media-max(xl) {
    //экран5
    &--type-doma-veka-site-5 {
      .doma-veka-section {
        &__info-text {
          margin-left: rem(-24);
        }
        &__title {
          font-size: rem(100);
        }
        &__subtitle {
          font-size: rem(16);
        }
      }
    }
    &--type-doma-veka-site-11 {
      .doma-veka-section {
        &__text-container {
          column-gap: 23.7%;
        }
      }
    }
  }

  //мобилка
  @include media-max(md) {
    //экран4
    &--type-doma-veka-site-4 {
      padding-top: rem(150);
      padding-bottom: rem(150);
      .doma-veka-section {
        flex-direction: column;
        row-gap: rem(150);
        &__background {
          top: rem(-13);
          left: rem(10);
          width: 196%;
        }

        &__image {
          width: 71.6%;
          margin: 0 auto;
          &--1 {
            order: 1;
          }
        }
      }
    }
    //экран5
    &--type-doma-veka-site-5 {
      .doma-veka-section {
        &__title {
          font-size: rem(43);
          max-width: rem(375);
        }
        &__text {
          &::after {
            width: rem(60);
          }
        }
        &__img {
          padding-left: rem(60);
          padding-right: rem(60);
        }
      }
    }
    //экран7
    &--type-doma-veka-site-7 {
      font-size: rem(11);

      .doma-veka-section {
        &__text-container {
          margin-bottom: 0;
        }
        &__text {
          &::after {
            width: rem(30);
          }
        }
      }
    }
    //экран9
    &--type-doma-veka-site-9 {
      .doma-veka-section {
        &__info-text {
          margin-top: rem(33);
        }
        &__images {
          padding: 0 rem(24);
          column-gap: rem(38);
          overflow-x: scroll;
        }
        &__image-wrapper {
          flex-grow: unset;
          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
        &__image {
          width: rem(226);
        }
      }
    }
    //экран10
    &--type-doma-veka-site-10 {
      font-size: rem(40);
      padding-top: rem(75);
      .doma-veka-section {
        &__header {
          padding-bottom: rem(75);
          flex-direction: column;
          &::after {
          }
        }
        &__description {
          order: 1;
          margin-top: rem(30);
          &--en {
            max-width: rem(240);
          }
        }
      }
    }
    //экран11
    &--type-doma-veka-site-11 {
      padding-top: rem(75);
      padding-bottom: rem(126);
      .doma-veka-section {
        &__image {
          margin-top: rem(61);
        }
      }
    }
    //экран12
    &--type-doma-veka-site-12 {
      padding-top: rem(75);
      padding-bottom: rem(176);
      .row {
        margin-bottom: rem(90);
      }
    }
  }
}
