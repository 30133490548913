.view-about-link-section {
  @import "./vars";

  .section-link {
    &__col-with-additional {
      margin-top: $view-about-link-section-col-with-additional_margin-top;
      margin-bottom: $view-about-link-section-col-with-additional_margin-bottom;
    }
  }

  @include media-min(md) {
    .section-link {
      &__col-with-additional {
        margin-top: $view-about-link-section-col-with-additional_margin-top_min-md;
        margin-bottom: $view-about-link-section-col-with-additional_margin-bottom_min-md;
      }
    }
  }
}
