.custom-scroll {
  $custom-scroll_width: 8px;
  $custom-scroll_background-color: transparent;
  $custom-scroll_thumb-color: $primary-color;

  scrollbar-width: thin;

  * {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: $custom-scroll_width;
    background-color: $custom-scroll_background-color;
  }

  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    background-color: $custom-scroll_thumb-color;

    &:hover {
      background-color: transparentize($custom-scroll_thumb-color, 0.5);
    }
  }
}
