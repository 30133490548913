.view-portfolio-icr-section{
    img{
        width: 100%;
    }
    &.portfolio-item-section{
        &--icr-1{
            padding-top: rem(200);
        }
        &--icr-2{
            padding-bottom: rem(150);
        }
        &--icr-3{
            padding-top: rem(150);
            padding-bottom: rem(200);
        }
        &--icr-4{
            padding-top: rem(200);
            padding-bottom: rem(260);
        }
        &--icr-5{
            padding-bottom: rem(150);
        }
        &--icr-6{
            padding-top: rem(150);
            padding-bottom: rem(200);
        }
        &--icr-7{
            padding-top: rem(200);
            padding-bottom: rem(260);
        }
        &--icr-8{
            padding-top: rem(80);
            padding-bottom: rem(80);
            .portfolio-item-section__inner{
                align-items: center;
                .text{
                    margin-bottom: rem(84);
                    span{
                        display: block;
                        max-width: 385px;
                    }
                }
            }
        }
        @include media-max(xl) {
            &--icr-1{
                padding-top: rem(150);
            }
            &--icr-2{
                padding-bottom: rem(100);
            }
            &--icr-3{
                padding-top: rem(100);
                padding-bottom: rem(150);
            }
            &--icr-4{
                padding-top: rem(150);
                padding-bottom: rem(200);
            }
            &--icr-5{
                padding-bottom: rem(100);
            }
            &--icr-6{
                padding-top: rem(100);
                padding-bottom: rem(150);
            }
            &--icr-7{
                padding-top: rem(150);
                padding-bottom: rem(200);
            }
            &--icr-8{
                padding-top: rem(80);
                padding-bottom: rem(150);
                .portfolio-item-section__inner{
                    align-items: center;
                    .text{
                        margin-bottom: rem(50);
                        margin-top: rem(80);
                    }
                }
            }

        }
        @include media-max(md) {
            &--icr-1{
                padding-top: rem(20);
            }
            &--icr-2{
                padding-bottom: rem(40);
            }
            &--icr-3{
                padding-top: rem(40);
                padding-bottom: rem(40);
            }
            &--icr-4{
                padding-top: rem(40);
                padding-bottom: rem(50);
            }
            &--icr-5{
                padding-bottom: rem(40);
            }
            &--icr-6{
                padding-top: rem(40);
                padding-bottom: rem(50);
            }
            &--icr-7{
                padding-top: rem(50);
                padding-bottom: rem(50);
            }
            &--icr-8{
                padding-top: rem(40);
                padding-bottom: rem(50);
                .portfolio-item-section__inner{
                    align-items: center;
                    .text{
                        margin-bottom: rem(30);
                        margin-top: rem(40);
                    }
                }
            }
        }
    }
}