.view-services-section {
  @import "./vars";

  &__container {
    position: relative;

    padding-top: $view-services-section-container_padding-top;
    padding-bottom: $view-services-section-container_padding-bottom;
  }

  &__header {
    margin-bottom: $view-services-section-header_margin-bottom;
  }

  &__heading {
    margin-bottom: $view-services-section-heading_margin-bottom;
    white-space: pre-line;
  }

  &__col-with-services-types {
    margin-bottom: $view-services-section-col-with-services-types_margin-bottom;

  }

  &__col-with-services {
    margin-bottom: $view-services-section-col-with-services_margin-bottom;
  }

  &__col-with-integrations {
    margin-bottom: $view-services-section-col-with-integrations_margin-bottom;
    width: 100%;
  }

  &__col-with-cms {
    margin-bottom: rem(65px);
    width: 100%;
  }

  &__col-with-services &__col-with-label {
    display: none;
  }

  &__col-with-label {
    margin-bottom: $view-services-section-col-with-label_margin-bottom;
    padding-right: rem(30px);
  }

  &__col-with-technologies-label {
    margin-bottom: $view-services-section-col-with-technologies-label_margin-bottom;
  }

  &__services-types-list .list__item:not(:last-child) {
    margin-bottom: $view-services-section-services-types-list-item_margin-bottom;
  }

  &__technologies-list .list__item:not(:last-child) {
    margin-bottom: $view-services-section-technologies-list-item_margin-bottom;
  }

  @include media-max(md) {
    &__technologies-label,
    &__services-types-list,
    &__technologies-list {
      font-size: $view-services-section-technologies-and-services-types-lists_font-size_max-md;
    }

    &__technologies-list {
      display: flex;
      flex-wrap: wrap;
      .list__item {
        width: 50%;
        max-width: 50%;
        padding-right: 10px;
      }
    }

    &__col-with-integrations {
      width: 50%;
    }

    &__col-with-cms {
      width: 50%;
    }

    &__col-with-services-types {
      .list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        &__item{
          width: 50%;
          max-width: 50%;
          padding: 0 5px;
        }
      }
    }
  }

  @include media-max(413px) {
    &__col-with-integrations {
      width: 100%;
    }

    &__col-with-cms {
      width: 100%;
    }

    &__col-with-services-types {
      .list {
        &__item{
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &__technologies-list {
      .list__item {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $view-services-section-container_padding-top_min-md;
      padding-bottom: $view-services-section-container_padding-bottom_min-md;
    }

    &__header {
      margin-bottom: $view-services-section-header_margin-bottom_min-md;
    }

    &__heading {
      margin-bottom: $view-services-section-heading_margin-bottom_min-md;
    }

    &__col-with-services-types-label {
      display: none;
    }

    &__col-with-services &__col-with-label {
      display: block;
    }

    &__col-with-services {
      margin-bottom: $view-services-section-col-with-services_margin-bottom_min-md;
    }

    &__col-with-integrations {
      margin-bottom: $view-services-section-col-with-integrations_margin-bottom_min-md;
    }

    &__col-with-cms {
      margin-bottom: 0;
    }

    &__col-with-technologies {
      margin-bottom: $view-services-section-col-with-technologies_margin-bottom_min-md;
    }

    &__col-with-label {
      margin-bottom: $view-services-section-col-with-label_margin-bottom_min-md;
    }

    &__col-with-technologies-label {
      margin-bottom: $view-services-section-col-with-technologies-label_margin-bottom_min-md;
    }

    &__col-with-services-types {
      padding-top: $view-services-section-col-with-services-types_padding-top_min-md;
    }

    &__services-types-list .list__item:not(:last-child) {
      margin-bottom: $view-services-section-services-types-list-item_margin-bottom_min-md;
    }

    &__technologies-list .list__item:not(:last-child) {
      margin-bottom: $view-services-section-technologies-list-item_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $view-services-section-container_padding-top_min-xl;
      padding-bottom: $view-services-section-container_padding-bottom_min-xl;
    }

    &__header {
      margin-bottom: $view-services-section-header_margin-bottom_min-xl;
    }

    &__heading {
      margin-bottom: $view-services-section-heading_margin-bottom_min-xl;
    }

    &__col-with-services {
      margin-bottom: $view-services-section-col-with-services_margin-bottom_min-xl;
    }

    &__col-with-technologies {
      margin-bottom: $view-services-section-col-with-technologies_margin-bottom_min-xl;
    }

    &__col-with-services-types {
      padding-top: $view-services-section-col-with-services-types_padding-top_min-xl;
    }

    &__col-with-integrations {
      align-content: flex-start;
      margin-bottom: $view-services-section-col-with-integrations_margin-bottom_min-xl;
    }
  }
}
