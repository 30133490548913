.view-portfolio-skess-equipment-card-section {
  @import "./vars";

  &__col-with-description {
    margin-top: rem(50px);

    &--desktop {
      display: none;
    }
  }

  @include media-min(md) {
    &__col-with-description {
      margin-top: rem(100px);
    }
  }

  @include media-min(lg) {
    &__col-with-image {
      &--lg-press-down {
        display: flex;
        align-items: flex-end;
      }
    }

    &__col-with-description {
      margin-top: 0;

      &--desktop {
        display: block;

        padding-top: rem(180px);
        padding-bottom: rem(110px);
      }

      &--mobile {
        display: none;
      }
    }
  }
}
