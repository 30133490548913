@mixin generate-utility($name, $property, $values, $prefix: "u-", $breakpoints: $min-breakpoints) {
  $prefix: if(type-of($prefix) == "string", $prefix, "u-");

  @each $breakpoint-name, $util-values in $values {
    $breakpoint-size: map-get($breakpoints, $breakpoint-name);

    @if $breakpoint-size != null {
      $breakpoint-class-infix: make-breakpoint-infix($breakpoint-name, $breakpoint-size);

      @include media-breakpoint($breakpoint-size) {
        @each $util-suffix, $util-value in $util-values {
          .#{$prefix + $name + $breakpoint-class-infix}-#{$util-suffix} {
            #{$property}: $util-value;
          }
        }
        // @each $util-value in $util-values {
        //   $util-suffix: if(type-of($unit-value) == "map")

        //   .#{$prefix + $name + $breakpoint-class-infix}-#{$util-suffix} {
        //     #{$property}: $util-value;
        //   }
        // }
      }
    }
  }
}
