.portfolio-item-section--type-csd-15 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: rem(240);
  padding-bottom: rem(265);
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  .portfolio-item-section__figure-caption{
    margin-bottom: 100px;
    p{
      white-space: pre-wrap;
    }
  }
  #model-3d-container{
    width: 50%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  #arch,
  #arch-pointer {
    position: absolute;
    bottom: -60px;
    left: 0;
    z-index: 1;
    overflow: visible;
  }
  #arch-dash {
    width: 90.63%;
    position: absolute;
    bottom: -35px;
    left: calc((100% - 90.63%) / 2);
    overflow: visible;
  }
  $bg-1: #C6C5E1;
  $bg-2: #686688;


  $white-1: #fbfbfb;
  $white-2: #f2f2f2;
  $white-3: #d8dde8;
  $white-4: #675D6E;

  $water-1: #7095c9;
  $water-2: #6187be;
  $water-3: #4d6d98;
  $water-4: #234062;

  $light-1: #deecf5;
  $light-2: #a7c0db;

  $cta-1: #90AACE;
  $cta-2: darken($cta-1, 10);
  $cta-3: darken($cta-2, 10);


  /***********************/
  /***********************/
  @mixin cube($width, $height, $depth) {
    &__front{ @include cube-front($width, $height, $depth); }
    &__back{ @include cube-back($width, $height, $depth); }
    &__right{ @include cube-right($width, $height, $depth); }
    &__left{ @include cube-left($width, $height, $depth); }
    &__top{ @include cube-top($width, $height, $depth);}
    &__bottom{ @include cube-bottom($width, $height, $depth); }
  }
  /************************/
  /* Mixin para crear cubo ( Caras siempre al frente ) */
  /************************/
  @mixin cube-front($width, $height, $depth) {
    width: $width;
    height: $height;
    transform-origin: bottom left;
    transform: rotateX(-90deg) translateZ( -($height - ($depth * 2) ) );
  }
  @mixin cube-back($width, $height, $depth) {
    width: $width;
    height: $height;
    transform-origin: top left;
    transform: rotateX(-90deg) rotateY(180deg) translateX(-$width) translateY(-$height) ;
  }
  @mixin cube-right($width, $height, $depth) {
    width: $depth * 2;
    height: $height;
    transform-origin: top left;
    transform: rotateY(90deg) rotateZ(-90deg) translateZ($width) translateX(-$depth * 2) translateY(-$height);
  }
  @mixin cube-left($width, $height, $depth) {
    width: $depth * 2;
    height: $height;
    transform-origin: top left;
    transform: rotateY(-90deg) rotateZ(90deg) translateY(-$height);
  }
  @mixin cube-top($width, $height, $depth) {
    width: $width;
    height: $depth * 2;
    transform-origin: top left;
    transform: translateZ($height) ;
  }
  @mixin cube-bottom($width, $height, $depth) {
    width: $width;
    height: $depth * 2;
    transform-origin: top left;
    transform: rotateY(180deg) translateX(-$width);
  }
  /***********************/
  /***********************/
  @mixin face-ani($animation, $time, $side, $width-el, $height-el, $depth-el) {

    animation: #{$animation} #{$time} infinite ease;

    @keyframes #{$animation} {
      0%, 15%, 45%, 60%, 62%, 68%{
        @if $side == "cube-front" { @include cube-front($width-el, $height-el, $depth-el); }
        @else if $side == "cube-back" { @include cube-back($width-el, $height-el, $depth-el); }
        @else if $side == "cube-right" { @include cube-right($width-el, $height-el, $depth-el); }
        @else if $side == "cube-left" { @include cube-left($width-el, $height-el, $depth-el); }
        @else if $side == "cube-top" { @include cube-top($width-el, $height-el, $depth-el); }
      }
      20%, 40%, 58%, 64%, 70%, 100%{
        @if $side == "cube-front" { @include cube-front($width-el, $height-finish, $depth-el); }
        @else if $side == "cube-back" { @include cube-back($width-el, $height-finish, $depth-el); }
        @else if $side == "cube-right" { @include cube-right($width-el, $height-finish, $depth-el); }
        @else if $side == "cube-left" { @include cube-left($width-el, $height-finish, $depth-el); }
        @else if $side == "cube-top" { @include cube-top($width-el, $height-finish, $depth-el); }
      }
    }
  }
  /**/

  @keyframes show-house{
    0% {
      transform: perspective(160px) rotateX(90deg) rotateZ(90deg) translateZ(-16vw) scale3d(3, 3, 3) translateY(6vw) translateX(12vw);
    }
    50% {
      transform: perspective(600px) rotateX(75deg) rotateZ(90deg) translateZ(-10vw) scale3d(3,3,3) translateY(3vw);
    }
    100% {
      transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(0.9, 0.9, 0.9);
    }
  }

  @keyframes show-house-tablet{
    0% {
      transform: perspective(160px) rotateX(90deg) rotateZ(90deg) translateZ(-16vw) scale3d(3, 3, 3) translateY(6vw) translateX(12vw);
    }
    50% {
      transform: perspective(600px) rotateX(75deg) rotateZ(90deg) translateZ(-10vw) scale3d(3,3,3) translateY(3vw);
    }
    100% {
      transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(1.2, 1.2, 1.2);
    }
  }

  @keyframes show-house-mobile{
    0% {
      transform: perspective(160px) rotateX(90deg) rotateZ(90deg) translateZ(-16vw) scale3d(3, 3, 3) translateY(6vw) translateX(12vw);
    }
    50% {
      transform: perspective(600px) rotateX(75deg) rotateZ(90deg) translateZ(-10vw) scale3d(3,3,3) translateY(3vw);
    }
    100% {
      transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(1.7, 1.7, 1.7);
    }
  }

  .face{ position: absolute; }
  .opacity-animation{
    .house{
      //transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(0.9, 0.9, 0.9);
      // animation: show-house 3s 0.2s ease-in-out forwards;
    }
  }
  /***************/
  .house{
    position: relative;
    width: 36vw;
    height: 24vw;
    margin: 8vw auto 2vw;
    z-index: 2;
    box-sizing: border-box;
    user-select: none;
    transform-style: preserve-3d;
    -webkit-tap-highlight-color: transparent;
    -webkit-backface-visibility: hidden;
    transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(1, 1, 1);
    // transform: perspective(160px) rotateX(90deg) rotateZ(90deg) translateZ(-16vw) scale3d(3, 3, 3) translateY(6vw) translateX(12vw);
    //transition: transform 2s 0.4s ease-in-out;
    *, *::after, *::before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      user-select: none;
      transform-style: preserve-3d;
      -webkit-tap-highlight-color: transparent;
      -webkit-backface-visibility: hidden;
      transition: width 0.6s ease-in, height 0.6s ease-in, transform 0.6s ease-in-out;
    }
    &:hover{
      .eb__back{
        height: 0;
      }
      .ec__front{
        width: 0;
      }
      .er__right{
        transform: rotateY(139deg) rotateZ(-90deg) translateZ(0.1vw) translateX(-16.6vw) translateY(-11vw)
      }
      .el__front{
        transform: rotateX(-142deg) translateZ(-14vw);
      }
    }
  }
  .shadows{
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }
  .shadow-a{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 130%;
    height: 103%;
    transform-origin: top right;
    transform: skewY(-3deg);
    background-color: $bg-2;
    box-shadow: -1vw 1vw 1.5vw $bg-2;
    filter: blur(.5vw);
  }
  .shadow-b{
    position: absolute;
    top: 0;
    width: 100%;
    height: 101.5%;
    background-color: darken($bg-2, 4);
  }
  /***************/
  /***************/
  .al{
    $width-el: 30vw;
    $height-el: .4vw;
    $depth-el: 12vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 3vw;
    height: 24vw;
    position: absolute;
    left: 0;
    top: 0;

    &__front{ background-image: linear-gradient(to right, darken($white-3, 20), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: darken($white-3, 25); }
    &__top{
      background-image: linear-gradient(to right, darken($white-3, 10) 40%, darken($white-2, 5) , $white-2  );
      &::before{
        //content: '';
        position: absolute;
        bottom: 0;
        width: 13vw;
        height: 7vw;

        background-image: linear-gradient(-135deg, transparent 20%, rgba( darken($light-2, 5), .75));
      }
      &::after{
        //content: '';
        position: absolute;
        bottom: 1vw;
        left: 6vw;
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        background-image: radial-gradient(rgba($white-1, .25), transparent);
        filter: blur(.7vw);

      }
    }
    &__bottom{  background-color: $white-3; }
  }
  .ab{
    $width-el: 8vw;
    $height-el: .4vw;
    $depth-el: 4vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 8vw;
    height: 8vw;
    position: absolute;
    right: 0;
    bottom: 0;

    &__front{ background-color: $white-3; }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-1; }
    &__top{ background-color: $white-2; }
    &__bottom{ background-color: $white-3; }
  }
  .at{
    $width-el: 8vw;
    $height-el: .4vw;
    $depth-el: 2vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 8vw;
    height: 4vw;
    position: absolute;
    right: 0;
    top: 0;

    &__front{ background-color: $white-3; }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-1; }
    &__top{ background-color: $white-2; }
    &__bottom{  background-color: $white-3; }
  }
  .ac{
    $width-el: 6vw;
    $height-el: 1vw;
    $depth-el: 6vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 6vw;
    height: 12vw;
    position: absolute;
    right: 0;
    top: 4vw;

    .face{ opacity: .9; }

    &__front{ background-color: $water-3; opacity: .75; }
    &__back{ background-color: $water-3; }
    &__right{ background-image: linear-gradient(to top, $water-2, $water-1 ); }
    &__left{ background-color: darken($water-4, 10); }
    &__top{
      opacity: .75;
      background-image:
              linear-gradient(
                              to right,
                              $water-1 0,
                              $water-1 5%,
                              $water-2 20%,
                              $water-2 30%,
                              $water-1 40%,
                              $water-1 65%,
                              darken($water-1, 3) 70%,
                              darken($water-1, 3) 72%,
                              $water-1 85%
              );

      box-shadow: inset .5vw .5vw 2vw rgba($white-1, .5);

      &::before{
        //content: '';
        position: absolute;
        width: 40%;
        height: 100%;
        overflow: hidden;

        background-image:
                linear-gradient(
                                to bottom,
                                transparent 0,
                                transparent 10%,
                                rgba($water-2, .9) 15%,
                                rgba($water-2, .9) 40%,
                                rgba($white-1, .2) 40%,
                                rgba($white-1, .2) 50%,
                                transparent 60%
                );

        filter: blur(.15vw);
      }
    }
    &__bottom{
      background-color: $water-3;
      display: none;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: -.25vw .25vw 1vw $water-4;
      }
    }
  }
  /***************/
  /***************/
  .bce{
    $width-el: 17vw;
    $height-el: .5vw;
    $depth-el: 11vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 17vw;
    height: 20.5vw;
    position: absolute;
    right: 6vw;
    top: 0;
    transform: translateZ(.41vw);

    &__front{ background-image: linear-gradient(to right, darken($white-3, 10), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-3; }
    &__top{ background-image: linear-gradient(135deg, darken($white-3, 10) 60%, $white-2  ); }
    &__bottom{
      background-color: $white-3;

      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 101%;
        background-color: $white-4;
        filter: blur(.35vw);
      }
    }
  }
  .bci{
    $width-el: 16vw;
    $height-el: .5vw;
    $depth-el: 10.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 16vw;
    height: 21vw;
    position: absolute;
    right: 6vw;
    top: 0;
    transform: translateZ(.92vw);

    &__front{ background-image: linear-gradient(to right, darken($white-3, 10), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-3; }
    &__top{
      background-image: linear-gradient(135deg, darken($white-3, 10) 60%, $white-2  );
      display: none;
      &::before{
        content: '';
        position: absolute;
        width: 16vw;
        height: 16.4vw;
        background-color: #d7d6ea;
        left: 0;
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 1.5vw;
        left: 1vw;
        width: 10vw;
        height: 3.5vw;
        border-radius: 50%;
        background-image: radial-gradient($light-2, transparent);
        filter: blur(.5vw);
      }
    }
    &__bottom{
      background-color: $white-3;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 101%;
        background-color: $white-4;
        filter: blur(.35vw);
      }
    }
  }
  .bl{
    display: none;
    $width-el: 13.1vw;
    $height-el: .5vw;
    $depth-el: 8.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 13vw;
    height: 17vw;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(.41vw);

    &__front{ background-image: linear-gradient(to right, darken($white-3, 30), darken($white-3, 10) ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-1; }
    &__top{
      background-color: lighten($bg-1, 5);
      &::before{
        content: '';
        position: absolute;
        top: 2vw;
        left: 3.5vw;
        width: 12vw;
        height: 12vw;
        border-radius: 50%;
        background-color: rgba($light-1, .35);
        filter: blur(.95vw);
      }
    }
    &__bottom{
      background-color: $white-3;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 102%;
        background-color: darken($white-3, 20);
        box-shadow: 0vw .25vw 1vw darken($white-3, 20);
      }
    }
  }
  .bt{
    $width-el: 4vw;
    $height-el: 1.1vw;
    $depth-el: 2vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 4vw;
    height: 4vw;
    position: absolute;
    right: 2vw;
    top: 0;
    transform: translateZ(.41vw);

    &__front{ background-image: linear-gradient(to top, darken($white-3, 15) 50%, lighten($white-3, 2) ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-1; }
    &__top{ background-color: darken($white-2, 5); }
    &__bottom{ background-color: $white-3; }
  }
  .bbe{
    $width-el: 6vw;
    $height-el: .5vw;
    $depth-el: 3vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 6vw;
    height: 6vw;
    position: absolute;
    right: 0;
    bottom: 2vw;
    transform: translateZ(.41vw);

    &__front{ background-color: $white-3; }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-1; }
    &__top{ background-color: darken($white-1, 2); }
    &__bottom{
      background-color: $white-1;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 95%;
        height: 104%;
        background-color: $white-4;
        filter: blur(.35vw);
      }
    }
  }
  .bbi{
    $width-el: 4vw;
    $height-el: .5vw;
    $depth-el: 2.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 4vw;
    height: 5vw;
    position: absolute;
    right: 2vw;
    bottom: 3vw;
    transform: translateZ(.92vw);

    &__front{ background-color: $white-3; }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-2; }
    &__left{ background-color: $white-1; }
    &__top{ background-color: darken($white-2, 2); }
    &__bottom{
      background-color: $white-1;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 95%;
        height: 104%;
        background-color: $white-4;
        filter: blur(.5vw);
      }
    }
  }
  /***************/
  /***************/
  .cl{
    $width-el: 3.5vw;
    $height-el: 12vw;
    $depth-el: 9.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 5vw;
    height: 3vw;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(.41vw);

    &__front{
      display: flex;
      justify-content: center;
      background-image: linear-gradient(to bottom, $white-3, darken($white-3, 15));
      overflow: hidden;

      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: -1.1vw;
        width: 200%;
        height: 3vw;
        background-image: linear-gradient(to right, rgba($light-1, .3), rgba($white-3, .25));
        filter: blur(.175vw);
        transform: rotateZ(-30deg);
      }
    }
    &__back{ background-color: $white-3; }
    &__right{
      background-image: linear-gradient(to top, darken($white-3, 10), darken($white-3, 20), lighten($light-2, 5)  70%);
      transform: rotateY(90deg) rotateZ(-90deg) translateZ(3.5vw) translateX(-19vw) translateY(-12vw);
      &::before{
        //content: '';
        position: absolute;
        right: 0;
        width: calc(100% - 2.5vw);
        height: 100%;
        background-image: linear-gradient(to bottom, $white-1, lighten($light-2, 5) 15%, darken($white-2, 10) 80%, darken($white-3, 10) );
        filter: blur(.5vw);
      }
      .lamp:nth-of-type(1){ left: 25%;}
      .lamp:nth-of-type(2){ left: 50%;}
      .lamp:nth-of-type(3){ left: 75%;}
    }
    &__left{ background-image: linear-gradient(to top, darken($white-3, 20),darken($white-3, 30)); }
    &__top{ background-color: darken($white-3, 5); display: none;}
    &__bottom{
      background-color: $white-3;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 115%;
        height: 103%;
        background-color: rgba($white-4, .75);
        filter: blur(.5vw);
      }
    }
  }

  .cc{
    $width-el: 1vw;
    $height-el: 12.5vw;
    $depth-el: 9.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 1vw;
    height: 19vw;
    position: absolute;
    left: 15vw;
    top: 0;
    transform: translateZ(12.5vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 5) , $white-3 10% ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-image: linear-gradient(to top, darken($white-3, 20),darken($white-3, 30)); display: none;}
    &__top{ background-color: $white-2; }
    &__bottom{  background-color: $white-3; display: none}
  }
  .ccl{
    $width-el: 1vw;
    $height-el: 11.5vw;
    $depth-el: 1.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 1vw;
    height: 3vw;
    position: absolute;
    left: 15vw;
    top: 16vw;
    transform: translateZ(1.43vw);

    &__front{ background-image: linear-gradient(to top, darken($white-3, 10), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{
      background-image: linear-gradient(to bottom, darken($white-3, 30), darken($white-3, 20) 70%, $light-2);
      height: 11vw;
      transform: rotateY(90deg) rotateZ(-90deg) translateZ(1vw) translateX(-3vw) translateY(-11vw);
      &::before{
        //content: '';
        position: absolute;
        top: -.5vw;
        left: 0;
        width: 100%;
        height: 2vw;
        transform-origin: bottom left;
        transform: translateZ(.05vw);
        background-image: linear-gradient(-45deg,darken($white-3, 20) ,darken($white-3, 30) 80%, transparent 80%);

      }
    }
    &__left{ background-image: linear-gradient(to top, darken($white-3, 20),darken($white-3, 30)); }
    &__top{ background-color: $white-2; }
    &__bottom{
      background-color: $white-3;
      display: none;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 102%;
        height: 101%;
        background-color: $white-4;
        filter: blur(.35vw);
      }
    }
  }
  .cml{
    $width-el: 16vw;
    $height-el: 1vw;
    $depth-el: 9.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 16vw;
    height: 19vw;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(12.41vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 3), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; display: none;}
    &__left{ background-color: darken($white-3, 30);}
    &__top{
      background-image: linear-gradient(to right, darken($white-3, 20), darken($white-3, 10) 30%, darken($light-2, 10) );
      box-shadow:
              inset .3vw .3vw 2vw rgba($white-4, .1),
              inset -.3vw .3vw 2vw rgba($white-4, .1),
              inset .3vw -.3vw 2vw rgba($white-4, .1),
              inset -.3vw -.3vw 2vw rgba($white-4, .1)
    ;
    }
    &__bottom{  background-color: $white-3; width: 13vw;}
  }
  .cmr{
    $width-el: 16vw;
    $height-el: 1vw;
    $depth-el: 9vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 16vw;
    height: 18vw;
    position: absolute;
    left: calc(16vw + 1px);
    top: 0;
    transform: translateZ(12.41vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 5), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; display: none}
    &__top{
      background-image: linear-gradient(to right, $white-2, darken($white-2, 2));

      &::before{
        //content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(48.5deg, lighten($white-2, 2) 50%, transparent 50%);
        opacity: .35;
      }
    }
    &__bottom{  background-color: $white-3; }
  }
  .cmt{
    $width-el: 12vw;
    $height-el: 1vw;
    $depth-el: 9.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 12vw;
    height: 19vw;
    position: absolute;
    left: 3vw;
    top: 0;
    transform: translateZ(24vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 5), $white-3 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: darken($white-3, 10); }
    &__top{ background-color: $white-2; }
    &__bottom{  background-color: $white-3; }
  }

  /***************/
  /***************/
  .dl{
    $width-el: 2vw;
    $height-el: 11vw;
    $depth-el: .5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 2vw;
    height: 1vw;
    position: absolute;
    left: 21.9vw;
    bottom: 7vw;
    transform: translateZ(1.5vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 20), darken($white-3, 15) 35%, darken($white-3, 10) 72%, $light-2 ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-3; }
    &__left{ background-color:  darken($white-3, 15); display: none;}
    &__top{ background-color: $white-2; }
    &__bottom{
      background-color: $white-3;
      display: none;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 102%;
        height: 101%;
        background-color: $white-4;
        filter: blur(.35vw);
      }
    }
  }
  .dm{
    $width-el: 6vw;
    $height-el: 4vw;
    $depth-el: .5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 6vw;
    height: 1vw;
    position: absolute;
    left: 16vw;
    bottom: 7vw;
    transform: translateZ(8.5vw);

    &__front{ background-image: linear-gradient(to bottom, darken($white-3, 20), darken($white-3, 15) ); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; }
    &__top{ background-color: $white-2; }
    &__bottom{  background-color: $white-3; }
  }
  .dt{
    $width-el: 8vw;
    $height-el: 11vw;
    $depth-el: .5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 8vw;
    height: 1vw;
    position: absolute;
    left: 14vw;
    top: 0;
    transform: translateZ(1.5vw);

    &__front{ background-color: $white-2; }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-2; }
    &__top{ background-color: $white-2; }
    &__bottom{  background-color: $white-3; }
  }
  /***************/
  /***************/
  .el{
    $width-el: 11.4vw;
    $height-el: 11.5vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 11.4vw;
    height: 1vw;
    position: absolute;
    left: 3.5vw;
    bottom: 7vw;
    transform: translateZ(1vw);

    &__front{
      border: .1vw solid rgba($water-3, .5);
      overflow: hidden;
      background-image: repeating-linear-gradient(-45deg, rgba($water-2, .1) 1vw, rgba($water-1, .1) 1.5vw);
    }

  }
  .ec{
    $width-el: 14vw;
    $height-el: 11vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 14vw;
    height: 1vw;
    position: absolute;
    left: 16vw;
    bottom: 7vw;
    transform: translateZ(1.5vw);

    &__front{
      border: .1vw solid rgba($water-3, .5);
      overflow: hidden;
      background-image: linear-gradient(to right, rgba($water-2, .1), rgba($water-1, .1));
    }
  }
  .er{
    $width-el: .1vw;
    $height-el: 11vw;
    $depth-el: 8.3vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 14vw;
    height: 1vw;
    position: absolute;
    left: 29.8vw;
    top: 0;
    transform: translateZ(1.5vw);

    &__right{
      border: .1vw solid rgba($water-3, .5);
      overflow: hidden;
      background-image: linear-gradient(to right, rgba($water-2, .1), rgba($water-1, .1));
    }
  }
  .eb{
    $width-el: 26.4vw;
    $height-el: 11.4vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 26.4vw;
    height: 1vw;
    position: absolute;
    left: 3.5vw;
    top: 0;
    transform: translateZ(1vw);

    &__back{
      border: .1vw solid rgba($water-3, .5);
      overflow: hidden;
      background-image: linear-gradient(to right, rgba($water-2, .1), rgba($water-1, .1));
    }
  }
  /***************/
  /***************/
  .fl{
    $width-el: .25vw;
    $height-el: .25vw;
    $depth-el: 8.45vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 1vw;
    height: 8.45vw;
    position: absolute;
    left: 1.5vw;
    top: 1vw;
    transform: translateZ(16.75vw);

    &__front{ background-color: darken($white-3, 10); }
    &__back{ background-color: $white-3; }
    &__right{ background-image: linear-gradient(to bottom, darken($white-3, 10), darken($white-3, 16) 50%); }
    &__left{
      background-color:  darken($white-3, 20);
      &::before{
        content: '';
        position: absolute;
        top: .25vw;
        right: 0;
        width: 100%;
        height: 3.25vw;
        border: .1vw solid rgba($water-3, .5);
        transform: rotateY(180deg);
        background-image: linear-gradient(to right, rgba(97, 135, 190, 0.15), rgba(112, 149, 201, 0.15));
      }
    }
    &__top{ background-image: linear-gradient(to bottom, darken($white-3, 10), darken($white-2, 5)); }
    &__bottom{  background-color: $white-3; }


  }
  .fb,
  .ft{
    $width-el: 14vw;
    $height-el: .25vw;
    $depth-el: .125vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 5vw;
    height: .25vw;
    position: absolute;
    left: 1.5vw;
    bottom: 6.1vw;
    transform: translateZ(16.75vw);
    &__front{
      background-color: darken($white-3, 10);

      &::before{
        content: '';
        position: absolute;
        top: .25vw;
        left: .1vw;
        width: calc(100% - .25vw);
        height: 3.25vw;
        border: .1vw solid rgba($water-3, .5);
        background-image: linear-gradient(to right, rgba($water-2, .15), rgba($water-1, .15));
      }
    }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; }
    &__top{ background-image: linear-gradient(to bottom, darken($light-2, 10), darken($white-2, 5)); }
    &__bottom{  background-color: $white-3; }
  }
  .ft{ top: 1vw; }
  /***************/
  /***************/
  .table{
    $width-el: 3vw;
    $height-el: .25vw;
    $depth-el: 2.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 3vw;
    height: 5vw;
    right: 10.5vw;
    bottom: 13vw;
    position: absolute;
    transform: translateZ(2.25vw);

    &__front{ background-color: lighten($white-3, 3); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; }
    &__top{ background-color: darken($white-2, 3); }
    &__bottom{  background-color: $white-3; }


    &::before{
      content: '';
      position: absolute;
      left: -10%;
      top: -10%;
      width: 120%;
      height: 120%;
      background-color: darken($white-3, 5);
      filter: blur(.5vw);
      transform: translateZ(-.81vw);
    }
  }
  .foot{
    $width-el: .25vw;
    $height-el: 1vw;
    $depth-el: .125vw;

    @include cube($width-el, $height-el, $depth-el);
    width: .25vw;
    height: .25vw;

    position: absolute;
    &__front{ background-color: lighten($white-3, 3); }
    &__back{ background-color: $white-2; }
    &__right{ background-color: $white-3; }
    &__left{ background-color: $white-2; }
    &__top{ background-color: $white-3; }
    &__bottom{ background-color: $white-3;}

    &:nth-of-type(1){ bottom: 0; right: 0; }
    &:nth-of-type(2){ bottom: 0; left: 0; }
    &:nth-of-type(3){ top: 0; right: 0; }
    &:nth-of-type(4){ top: 0; left: 0; }

  }

  /***************/
  /***************/
  .lamp{
    position: absolute;
    top: 2.5vw;
    width: .75vw;
    height: 2.5vw;
    border-radius: 5vw;
    opacity: .95;
    background-color: $light-1;
    box-shadow:
            .15vw .15vw .75vw rgba($light-2, .5),
            .15vw -.15vw .75vw rgba($light-2, .5),
            -.15vw .15vw .75vw rgba($light-2, .5),
            -.15vw -.15vw .75vw rgba($light-2, .5)
  ;
    filter: blur(.15vw);
  }
  /***************/
  /***************/
  .kitchen{
    $width-el: 2vw;
    $height-el: .25vw;
    $depth-el: 6vw;

    @include cube($width-el, $height-el, $depth-el);
    width: 2vw;
    height: 12vw;
    top: 3vw;
    left: 3.5vw;
    transform: translateZ(3vw);
    position: absolute;

    &__front{ background-color: darken($white-3, 10); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{
      background-color: $white-3;

      &::before{
        content: '';
        position: absolute;
        width: 100%;
        top: .25vw;
        height: 1.25vw;
        background-color: rgba(lighten($white-4, 20), .75);
        filter: blur(.5vw);
      }
    }
    &__top{ background-image: linear-gradient(to right, lighten($light-1, 3), lighten($light-1, 5) 60% ); }
    &__bottom{  background-color: $white-3; }
  }
  /***************/
  /***************/
  .couch{
    $width-el: 5vw;
    $height-el: 2vw;
    $depth-el: 1.5vw;

    @include cube($width-el, $height-el, $depth-el);
    width: 5vw;
    height: 3vw;
    top: .5vw;
    left: 16vw;
    transform: translateZ(1.5vw);
    position: absolute;

    &__front{ background-color: lighten($white-3, 6); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; }
    &__top{ background-color: darken($white-2, 3); }
    &__bottom{
      background-color: $white-3;

      &::before{
        content: '';
        position: absolute;
        left: -10%;
        top: -10%;
        width: 120%;
        height: 120%;
        background-color: rgba(darken($white-3, 10), .5 );
        filter: blur(.25vw);
      }
    }
  }
  .couchb{
    $width-el: 7vw;
    $height-el: .75vw;
    $depth-el: 2.5vw;

    @include cube($width-el, $height-el, $depth-el);
    width: 7vw;
    height: 1vw;
    bottom: 17vw;
    left: 6vw;
    transform: translateZ(1vw);
    position: absolute;

    &__front{ background-color: lighten($white-3, 6); }
    &__back{ background-color: $white-3; }
    &__right{ background-color: $white-1; }
    &__left{ background-color: $white-3; }
    &__top{ background-image: linear-gradient(to right, lighten($light-1, 3), lighten($light-1, 5) 60% ); }
    &__bottom{
      background-color: $white-3;

      &::before{
        content: '';
        position: absolute;
        left: -10%;
        top: -10%;
        width: 120%;
        height: 120%;
        background-color: rgba(darken($white-3, 10), .5 );
        filter: blur(.25vw);
      }
    }
  }
  .couchc,
  .couchd{
    $width-el: 5vw;
    $height-el: 1.5vw;
    $depth-el: .75vw;

    @include cube($width-el, $height-el, $depth-el);
    width: 5vw;
    height: 1.5vw;
    bottom: 6.5vw;
    left: 9vw;
    transform: translateZ(13.5vw);
    position: absolute;

    &__front{ background-color: darken($white-3, 0); }
    &__back{ background-color: $white-3; }
    &__right{ background-image: linear-gradient(to bottom, darken($light-1, 5), $light-1, $white-2 ); display: none}
    &__left{ background-color: darken($white-3, 15); }
    &__top{ background-image: linear-gradient(to left, darken($light-1, 5), $light-1, $white-2 ); }
    &__bottom{
      background-color: $white-3;

      &::before{
        content: '';
        position: absolute;
        left: -10%;
        top: -10%;
        width: 120%;
        height: 120%;
        background-color: rgba(darken($white-3, 30), .5 );
        filter: blur(.25vw);
      }
    }
  }

  .couchd{ top: 1.5vw; }
  /***************/
  /***************/
  .picture{
    $width-el: .35vw;
    $height-el: 4vw;
    $depth-el: 1.5vw;

    @include cube($width-el, $height-el, $depth-el);

    width: .5vw;
    height: 3vw;
    bottom: 13.5vw;
    left: 3.35vw;
    transform: translateZ(6vw);
    position: absolute;

    &__front{ background-color: darken($white-3, 0);  }
    &__back{ background-color: $white-3; }
    &__right{
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to bottom, $light-1, $white-1);
      &::before,
      &::after{
        content: '';
        position: absolute;
        width: 1.1vw;
        height: 1.1vw;
        border: .125vw solid rgba($white-3, .5);
      }
      &::before{
        top: 40%;
        left: 25%;
      }
      &::after{
        top: 55%;
        left: 45%;
      }
    }
    &__left{ background-color: $white-3; }
    &__top{ background-color: darken($white-2, 3); }
    &__bottom{ background-color: $white-3; }
  }
  /***************/
  /***************/
  .door-l{
    $width-el: .125vw;
    $height-el: 7vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: .125vw;
    height: 1vw;
    left: 17vw;
    bottom: 6.75vw;
    transform: translateZ(1.5vw);

    position: absolute;

    &__front{ background-color: darken($white-3, 50); }
    &__back{ background-color: darken($white-3, 28); }
    &__right{ background-color: darken($white-3, 28); }
    &__left{ background-color: darken($white-3, 50); }
    &__top{ background-color: darken($white-3, 28); }
    &__bottom{  background-color: darken($white-3, 28); }
  }
  .door-r{
    $width-el: 2vw;
    $height-el: 7vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 2vw;
    height: 1vw;
    left: 19.55vw;
    bottom: 6.75vw;
    transform: translateZ(1.5vw);

    position: absolute;

    &__front{ background-color: darken($white-3, 50); }
    &__back{ background-color: darken($white-3, 28); }
    &__right{ background-color: darken($white-3, 28); }
    &__left{ background-color: darken($white-3, 50); }
    &__top{ background-color: darken($white-3, 28); }
    &__bottom{  background-color: darken($white-3, 28); }
  }
  .door-b,
  .door-t{
    $width-el: 6vw;
    $height-el: .125vw;
    $depth-el: .25vw;

    @include cube($width-el, $height-el, $depth-el);

    width: 2vw;
    height: 1vw;
    left: 16.3vw;
    bottom: 6.75vw;
    transform: translateZ(1.5vw);

    position: absolute;

    &__front{ background-color: darken($white-3, 50); }
    &__back{ background-color: darken($white-3, 28); }
    &__right{ background-color: darken($white-3, 28); display: none;}
    &__left{ background-color: darken($white-3, 28); display: none;}
    &__top{ background-color: darken($white-3, 28); }
    &__bottom{  background-color: darken($white-3, 28); }
  }

  .door-t{ transform: translateZ(8.4vw); }

  @media (max-width: 1199px) {
    padding-bottom: rem(150);
    #model-3d-container{
      width: 75%;
      margin: 0 auto;
      position: relative;
    }
    .house {
      transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(1.3, 1.3, 1.3);
      margin: 16vw auto 4vw;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: rem(100);
    padding-top: rem(100);
    .portfolio-item-section__figure-caption {
      margin-bottom: 50px;
    }
    #model-3d-container {
      width: calc(100% - 48px);
    }
    #arch-pointer, #arch {
      bottom: -35px;
    }
    #arch-dash {
      bottom: -15px;
    }
    .house {
      transform: perspective(20000px) rotateX(75deg) rotateZ(35deg) translateZ(-9vw) scale3d(1.7, 1.7, 1.7);
      margin: 27vw auto 6vw;
    }
  }
}