.view-portfolio-item-about-section--type-banners {
  padding-top: rem(160);
  padding-bottom: rem(172);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 80%;
  }

}

.view-portfolio-banners-section {
  padding-top: rem(60);
  padding-bottom: rem(60);

  .img-wrap {
    &:not(:last-child) {
      margin-bottom: rem(60);
    }
  }
}

.portfolio-item-section {

  &--type-banners-3 {
    padding-top: 0;
    img {
      width: 100%;
    }
  }
}