.menu {
  @import "./vars";

  position: fixed;

  left: 0;
  top: 0;

  display: none;

  width: 100%;
  height: 100%;

  background-color: $menu_background-color;

  z-index: $menu_z-index;
  backface-visibility: hidden;
  transform: translateZ(0);
  will-change: height;
  overflow: hidden;

  &__container {
    height: 100%;
    overflow: auto;
  }

  &__scroll,
  &__container,
  &__inner,
  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__scroll {
    overflow-y: auto;
    height: 100%;
    overflow: auto;
  }

  &__container {
    padding-bottom: $menu-container_padding-bottom;
  }

  &__body {
    padding: rem(30px) 0;
  }

  &__body-inner {
    align-content: center;
    margin: auto 0;
  }

  &__nav {
    margin-bottom: $menu-nav_margin-bottom;
  }

  &__nav-link {
    font-size: $menu-nav-link_font-size;
  }

  &__contacts-col-with-label {
    display: none;
  }

  &__contacts-link {
    vertical-align: bottom;
  }

  &__footer-inner {
    align-items: flex-end;
  }

  &__title {
    // предотвращаем появление скролла из за line-height
    overflow: hidden;
  }

  &__copyright {
    margin-left: auto;
    transform-origin: 0;
    transform: translate(100%, 50%) rotate(-90deg);
    white-space: nowrap;
  }

  @include media-max(xl) {
    &__contacts {
      color: $color-abbey;
    }
  }

  @include media-min(sm) {
    &__nav-link {
      font-size: $menu-nav-link_font-size_min-sm;
    }
  }

  @include media-min(md) {
    &__container {
      padding-bottom: $menu-container_padding-bottom_min-md;
    }

    &__body-inner {
      height: 100%;
      flex: 1 1 auto;
      align-content: center;
    }

    &__contacts-col-with-label {
      display: block;
    }

    &__nav {
      margin-bottom: $menu-nav_margin-bottom_min-md;
    }

    &__copyright {
      transform: none;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-bottom: $menu-container_padding-bottom_min-xl;
    }

    &__copyright {
      text-align: left;
    }

    &__body {
      padding-top: $menu-container_padding-top_min-xl;
    }

    &__body-inner {
      margin-top: 0;
      align-content: flex-start;
    }

    &__nav {
      margin-bottom: $menu-nav_margin-bottom_min-xl;
    }

    &__nav,
    &__contacts {
      align-self: flex-start;
    }

    &__contacts {
      margin-top: 0;
    }
  }
}
