.heading {
  @import "./vars";
  line-height: $heading_line-height;
  letter-spacing: $heading_letter-spacing;
  font-weight: $heading_font-weight;
  font-size: $heading_font-size;


  &--adaptive {
    font-size: $heading-adaptive_font-size;
  }

  @include media-min(md) {
    font-size: $heading_font-size_min-md;

    &--adaptive {
      font-size: $heading-adaptive_font-size_min-md;
    }
  }

  // генерирует модификаторы размеров шрифтов по брейкпойнтам
  $heading-font-sizes-length: length($heading-font-sizes);

  @include generate-modifiers-by-breakpoints("h") {
    @each $heading-font-size in $heading-font-sizes {
      $h: $heading-font-sizes-length - index($heading-font-sizes, $heading-font-size) + 1;

      &-#{$h} {
        font-size: $heading-font-size;
      }
    }
  }
}

.service-heading2 {
  font-size: 69px;

  @include media-max(md) {
    font-size: 40px;
  }
}