@import "/src/scss/mixins.scss";

.services-item-our-experience-section {
  &__title {
    font-feature-settings: "cpsp"on, "ss02"on;
    text-indent: 40%;
    text-transform: initial;

    span {
      font-size: 55px;
    }

    i {
      display: block;
      text-align: right;
      white-space: nowrap;
      font-size: rem(69px);
      text-indent: 0;
      text-transform: uppercase;
      font-feature-settings: normal;
    }

    * {
      text-align: left;
    }

    @include media-max(xl) {
      i {
        font-size: rem(50px);
      }

      span {
        font-size: 55px;
      }
    }

    @include media-max(lg) {
      text-indent: 0;
    }

    @include media-max(md) {
      font-size: rem(10px);

      i {
        font-size: rem(30px);
        text-align: left;
        white-space: pre-wrap;
      }

      span {
        font-size: 33px;
      }
    }
  }

  &.black {
    * {
      color: #000;
    }
  }

  &.white {
    * {
      color: #fff;
    }
  }

  padding: rem(185px) 0;

  @include media-max(md) {
    padding: 148px 0 182px;
  }

  @include media-max(sm) {
    padding: 103px 0 130px;
  }

  &__body {
    height: 100%;

    * {
      text-wrap-mode: wrap !important;
    }

    @include media-max(md) {
      gap: 92px;
      //flex-direction: column-reverse;
    }

    @include media-max(sm) {}

    .description {
      //font-family: AnonymousPro, "Lucida Console", Monaco, monospace !important;
      margin-top: auto;
      font-family: $font-family-anonymous-pro;

      button {
        p {
          font-family: $font-family-euclid-flex;
        }
      }

      @include media-max(lg) {
        margin-top: 136px;
      }

      @include media-max(md) {
        margin-top: 0;
      }
    }
  }

  .items-container {
    gap: 48px;

    @include media-max(md) {
      gap: 74px;

      & > div {
        gap: rem(15px);
      }
    }
  }

  .service-about {
    margin-bottom: 120px;

    @include media-max(lg) {
      margin-bottom: 125px;
    }

    @include media-max(md) {
      margin-bottom: 92px;
    }
  }

  .side-brackets {
    @include media-max(md) {
      margin-bottom: 15px;
    }
  }

  &__job-types-list {
    flex-direction: column;
  }

  &__caption-list-title {
    margin-bottom: 12px;
  }
}