$browser-context: 16px;

@function rem-or-em($one-rem-or-em, $pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @return $pixels / $context * $one-rem-or-em;
}

@function rem($pixels, $context: $browser-context) {
  @return rem-or-em(1rem, $pixels, $context);
}

@function em($pixels, $context: $browser-context) {
  @return rem-or-em(1em, $pixels, $context);
}

@function px($rem-or-em, $context: $browser-context) {
  @if (type-of($rem-or-em)=="number"and $rem-or-em !=0) {
    $unit: unit($rem-or-em);
    $value: strip-unit($rem-or-em);

    @if ($unit=="rem"or $unit=="em") {
      @return $value * $context;
    }

    @error "unit of the '$rem-or-em' is not rem or em";
  }

  @error "'$rem-or-em is not' is not a number";
}

@function vh-or-vw($one-vh-or-vw, $number) {
  @if (unitless($number) or unit($number)=="px") {
    $number: if(unit($number)=="px", strip-unit($number), $number);

    @return $number * 100 * $one-vh-or-vw;
  }

  @error "'$number' is not unitless or doesn't have px unit";
}

@function vh($number) {
  @return vh-or-vw(1vh, $number);
}

@function vw($number) {
  @return vh-or-vw(1vw, $number);
}


@function font-to-vw($font-size, $window) {
  $new-size: 100vw * $font-size / $window;

  @return $new-size;
}