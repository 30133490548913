.button {
  @import "./vars";

  width: auto;

  margin: 0;
  padding: 0;

  border: none;

  font-family: inherit;
  // font-size: $button_font-size;
  // font-weight: $button_font-weight;
  // line-height: $button_line-height;
  // text-transform: uppercase;
  color: inherit;
  white-space: nowrap;

  background-color: transparent;
  cursor: pointer;

  &__inner {
    display: inline-flex;
  }

  &--link {
    text-decoration: underline;
  }

  &__left,
  &__right {
    display: inline-block;
  }

  &:focus {
    outline: none;
  }
}
