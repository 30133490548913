.u-ratio {
  position: relative;
  padding-top: 100%;

  width: 100%;

  & > * {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &--10_752\/16 {
    padding-top: percentage(10.752 / 16);
  }

  &--10\/16 {
    padding-top: percentage(10 / 16);
  }

  &--9\/16 {
    padding-top: percentage(9 / 16);
  }

  &--9\/21 {
    padding-top: percentage(9 / 21);
  }

  &--3\/5 {
    padding-top: percentage(3 / 5);
  }
}
