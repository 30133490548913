@import "/src/scss/components/footnote/vars";

// lead section
$portfolio-lead-section_min-height: rem(568);

// container
$portfolio-lead-section-container_padding-top: rem(50px);
$portfolio-lead-section-container_padding-top_min-md: rem(40px);
$portfolio-lead-section-container_padding-top_min-xl: rem(70px);

$portfolio-lead-section-container_padding-bottom: rem(35px);

// header
$portfolio-lead-section-header_margin-bottom: rem(105px);
$portfolio-lead-section-header_margin-bottom_min-md: rem(170px);
$portfolio-lead-section-header_margin-bottom_min-xl: 0;

// title
$portfolio-lead-section-title_font-size_max-md: vw(65px / 414px);

// col with display
$portfolio-lead-section-col-with-display_margin-bottom: rem(12px);
$portfolio-lead-section-col-with-display_margin-bottom_min-md: 0;

// col with label

// text
$portfolio-lead-section-text_max-width: rem(490px);

// stat counter
$portfolio-lead-section-stat-counter_font-size: rem(60px);
$portfolio-lead-section-stat-counter_font-size_min-sm: rem(87.2px);
$portfolio-lead-section-stat-counter_font-size_min-xl: rem(169px);

$portfolio-lead-section-stat-counter_margin-right: rem(11px);
$portfolio-lead-section-stat-counter_margin-right_min-xl: rem(16px);

// stat not last
$portfolio-lead-section-stat-not-last_margin-right: rem(30px);
$portfolio-lead-section-stat-not-last_margin-right_min-sm: rem(55px);
$portfolio-lead-section-stat-not-last_margin-right_min-md: rem(50px);
$portfolio-lead-section-stat-not-last_margin-right_min-xl: rem(60px);

// stat label
$portfolio-lead-section-stat-label_font-size: rem(12px);
$portfolio-lead-section-stat-label_font-size_min-sm: rem(16px);

$portfolio-lead-section-stat-label_margin-bottom: em(
  (px($footnote_font-size) - $footnote_line-height * px($footnote_font-size))
);

// arrow
$portfolio-lead-section-arrow_font-size: rem(94px);
