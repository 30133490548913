.view {
  @import "./vars";

  &--hidden {
    visibility: hidden;
  }

  &--services {
    background-color: #F8F9F9;
  }
}
