.view-contacts {
  @import "./vars";

  &__header {
    margin-bottom: $view-contacts-header_margin-bottom;
  }

  &__body {
    align-content: flex-start;
  }

  &__contacts-list {
    margin-bottom: $view-contacts-contacts-list_margin-bottom;
  }

  &__contacts-list-item:first-child {
    margin-bottom: rem(20px) !important;
  }

  &__col-with-footer-title {
    display: none;
  }

  &__copyright {
    white-space: nowrap;
  }

  // &__address {
  //   height: 100%;
  // }

  &__socials-list {
    margin-bottom: $view-contacts-copyright_socials-list_margin-bottom;
  }

  &__title {
    height: vw(56 / 414);
  }

  @include media-min(md) {
    &__container {
      // padding-top: $view-contacts-container_padding-top_min-md;
      // padding-bottom: $view-contacts-container_padding-bottom_min-md;
    }

    &__header {
      display: none;
    }

    &__contacts-list {
      // margin-bottom: $view-contacts-contacts-list_margin-bottom_min-md;
      // align-self: flex-end;
      margin-bottom: 0;
    }

    &__socials-list {
      margin-bottom: 0;
    }

    &__footer {
      position: relative;
      align-items: flex-end;
    }

    &__socials-list {
      margin: auto 0;
    }

    &__copyright {
      transform-origin: 0;
      transform: translate(100%, 50%) rotate(-90deg);
    }

    &__col-with-footer-title {
      display: block;
    }

    &__address {
      height: 100%;
      align-content: center;
    }

    &__contacts-list {
      margin-bottom: vh(140 / 1024);
    }

    &__title {
      height: vw(98 / 768);

      &--footer {
        margin-bottom: vw(-17 / 768);
      }
    }
  }

  @include media-min(xl) {
    &__container {
      // padding-top: $view-contacts-container_padding-top_min-xl;
      // padding-bottom: $view-contacts-container_padding-bottom_min-xl;

      // убираем скролл, который появляется из-за шрифта euclid flex и заданного ему line-height: 1.1
      overflow: hidden;
    }

    &__address {
      justify-content: space-between;
      align-content: flex-start;
      margin-top: 0;
    }

    &__socials-list {
      text-align: right;
      margin: 0;
    }

    &__col-with-copyright {
      padding-bottom: $view-contacts-col-with-copyright_padding-bottom_min-xl;
    }

    &__col-with-contacts-list-item-label {
      max-width: $view-contacts-col-with-contacts-list-item-label_max-width_min-xl;
    }

    &__contacts-list {
      align-self: flex-start;

      max-width: $view-contacts-contacts-list_max-width_min-xl;
      margin-bottom: $view-contacts-contacts-list_margin-bottom_min-xl;
    }

    &__copyright {
      position: initial;
      transform: none;
    }

    &__title--footer {
      height: auto;
      margin-bottom: 0;
    }
  }
}
