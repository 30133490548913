.view-portfolio-item-about-section--type-v6 {
  padding-top: rem(160);
  padding-bottom: rem(262);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 54%;
  }
}

.portfolio-item-section {

  &--type-v6-4 {
    .u-ratio {
      padding-top: 75%;
    }
  }

  &--type-v6-6 {
    padding-top: rem(238);
    padding-bottom: rem(350);

    .description {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(225);

      .list {
        display: flex;
        margin-left: 40%;
        white-space: nowrap;
      }
    }

    @include media-max(md) {
      padding-top: rem(80);
      padding-bottom: rem(80);

      .description {
        flex-direction: column;
        gap: 10px;
        margin-bottom: rem(50);

        .list {
          display: flex;
          margin-left: 0;
          margin-top: 10px;
          white-space: normal;
        }
      }
    }
  }

  &--type-v6-7 {
    margin: 0 rem(-4);
    .col-half {
      width: 50%;
      padding: 0 rem(4);
      img {
        object-fit: cover;
      }
      * {
        width: 100%;
        height: 100%;
      }
    }
    .col-11 {
      padding: 0 rem(4);
    }
  }

  &--type-v6-8 {
    margin: 0 rem(-3);
    .col-half {
      width: 50%;
      padding: 0 rem(3);
      img {
        object-fit: cover;
      }
      * {
        width: 100%;
        height: 100%;
      }
    }
    .col-11 {
      padding: 0 rem(3);
    }
    .u-ratio {
      padding-top: 64%;
    }
  }
}