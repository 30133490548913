@import "/src/scss/mixins.scss";

.view-developer-faqs-section {
  padding: rem(185px) 0;

  @include media-max(md) {
    padding: rem(125px) 0;
  }

  @include media-max(sm) {
    padding: rem(100px) 0;
  }

  &__header {
    h2 {
      font-size: rem(69px);
    }
    @include media-max(md) {
      padding-bottom: rem(60px);

      h2 {
        font-size: rem(40px);
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: rem(46px);
    }
  }

  &__text-container {
    margin-top: rem(36px);
  }

  &__plus {
    margin-right: rem(42px);

    @include media-max(md) {
      margin-right: rem(30px);
    }

    @include media-max(sm) {
      margin-right: rem(15px);
    }
  }

  &__text {
    flex: 1;

    p {
      color: var(--text-additional, #8a8a8b);
      white-space: break-lines;

      @include media-max(md){
        font-size: rem(15px);
      }
    }
  }

  .aunchor {
    margin-top: rem(-40px);

    @include media-max(md) {
      display: none;
    }
  }
}
