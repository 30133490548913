@import "/src/scss/mixins.scss";

.view-developer-sites-jobs-section {
  padding: rem(185px) 0;

  @include media-max(md) {
    padding: rem(125px) 0;
  }

  @include media-max(sm) {
    padding: rem(100px) 0;
  }

  &__items-block {
    &:not(:first-child) {
      margin-top: rem(110px);
    }

    @include media-max(xl) {
      &:not(:first-child) {
        margin-top: rem(88px);
      }
    }

    @include media-max(xl) {
      &:not(:first-child) {
        margin-top: rem(60px);
      }
    }

    .list__item--empty {
      margin-bottom: 40px !important;
    }
  }

  &__header {
    gap: 45px;
    margin-bottom: rem(140px);

    @include media-max(xl) {
      margin-bottom: rem(115px);
    }

    @include media-max(md) {
      margin-bottom: rem(60px);
    }

    h2 {
      font-size: rem(69px);

      @include media-max(md) {
        font-size: rem(40px);
      }

      @media (max-width: 374px) {
        font-size: rem(36px);
      }
    }
  }

  .subtitles-container {
    margin-bottom: rem(30px);

    @include media-max(xl) {
      margin-bottom: rem(20px);
    }

    span {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &__items-block-head {
    margin-bottom: rem(65px);
    padding-right: 15px;

    @include media-max(md) {
      padding-right: 0;
    }

    .caption-container {
      margin-top: rem(25px);
      font-family: $font-family-anonymous-pro;
      font-size: 14px;
      // margin-top: rem(25px);
      gap: rem(10px) rem(30px);
    }

    .caption {
      font-family: $font-family-anonymous-pro;
      font-size: 14px;
    }
  }

  &__item-block {
    &:not(.g-0) {
      &:not(:last-child) {
        margin-bottom: rem(100px);
      }

      @include media-max(xl) {
        &:not(:last-child) {
          margin-bottom: rem(85px);
        }

        .title {
          margin-bottom: rem(35px);
        }
      }

      @include media-max(md) {
        &:not(:last-child) {
          margin-bottom: rem(60px);
        }

        .title {
          margin-bottom: rem(30px);
        }
      }
    }

    .text-item {
      &:not(:last-child) {
        margin-bottom: rem(13px);
      }
    }

    .cursive-text-item {
      margin-bottom: rem(8px);
      white-space: nowrap;

      .plus {
        margin-right: rem(42px);

        @include media-max(sm) {
          margin-right: rem(20px);
        }
      }

      >span {
        white-space: nowrap;
      }

      @include media-max(xl) {
        >span {
          white-space: initial;
        }
      }
    }

    .list-item {
      margin-bottom: rem(13px);
    }

    .heading-titled-list__title {
      margin-bottom: rem(30px);
    }
  }

  .job-list-item {
    padding-left: rem(51px) !important;

    @include media-max(sm) {
      padding-left: rem(30px) !important;
    }
  }
}

