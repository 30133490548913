$view-portfolio-item-section-size-decrease-ratio: 2;
$view-portfolio-item-section-size-decrease-ratio_min-md: 1.8;

// $view-portfolio-item-section-sizes_xxs: rem(40px);
// $view-portfolio-item-section-sizes_xs: rem(80px);
// $view-portfolio-item-section-sizes_sm: rem(100px);
// $view-portfolio-item-section-sizes_md: rem(120px);
// $view-portfolio-item-section-sizes_lg: rem(160px);
// $view-portfolio-item-section-sizes_xl: rem(200px);
// $view-portfolio-item-section-sizes_xxl: rem(240px);
$view-portfolio-item-section-sizes_xxs: rem(50px);
$view-portfolio-item-section-sizes_xs: rem(100px);
$view-portfolio-item-section-sizes_sm: rem(150px);
$view-portfolio-item-section-sizes_md: rem(200px);
$view-portfolio-item-section-sizes_lg: rem(250px);
$view-portfolio-item-section-sizes_xl: rem(300px);
$view-portfolio-item-section-sizes_xxl: rem(350px);

$view-portfolio-item-section-sizes: (
  xxs: $view-portfolio-item-section-sizes_xxs,
  xs: $view-portfolio-item-section-sizes_xs /
    $view-portfolio-item-section-size-decrease-ratio,
  sm: $view-portfolio-item-section-sizes_sm /
    $view-portfolio-item-section-size-decrease-ratio,
  md: $view-portfolio-item-section-sizes_md /
    $view-portfolio-item-section-size-decrease-ratio,
  lg: $view-portfolio-item-section-sizes_lg /
    $view-portfolio-item-section-size-decrease-ratio,
  xl: $view-portfolio-item-section-sizes_xl /
    $view-portfolio-item-section-size-decrease-ratio,
  xxl: $view-portfolio-item-section-sizes_xxl /
    $view-portfolio-item-section-size-decrease-ratio,
);

$view-portfolio-item-section-sizes_min-md: (
  xs: $view-portfolio-item-section-sizes_xs /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
  sm: $view-portfolio-item-section-sizes_sm /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
  md: $view-portfolio-item-section-sizes_md /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
  lg: $view-portfolio-item-section-sizes_lg /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
  xl: $view-portfolio-item-section-sizes_xl /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
  xxl: $view-portfolio-item-section-sizes_xxl /
    $view-portfolio-item-section-size-decrease-ratio_min-md,
);

$view-portfolio-item-section-sizes_min-xxl: (
  // 50  100 150 200 250 300 350
  // xxs xs  sm  md  lg  xl  xxl
  xs: $view-portfolio-item-section-sizes_xs,
  sm: $view-portfolio-item-section-sizes_sm,
  md: $view-portfolio-item-section-sizes_md,
  lg: $view-portfolio-item-section-sizes_lg,
  xl: $view-portfolio-item-section-sizes_xl,
  xxl: $view-portfolio-item-section-sizes_xxl
);
