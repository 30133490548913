@import './vars';

.portfolio-item-section {
  //экран6
  &--type-rutube-6 {
    .rutube-section-video {
      aspect-ratio: 1.6166/1;
    }
  }
  //экран8
  &--type-rutube-8 {
    .rutube-section-video {
      aspect-ratio: 1.65/1;
    }
  }
  //экран10
  &--type-rutube-10 {
    .rutube-section-video {
      aspect-ratio: 1.84/1;
    }
  }
  //экран13
  &--type-rutube-13 {
    .rutube-section-video {
      aspect-ratio: 1.67/1;
    }
  }
}
