@import "/src/scss/mixins.scss";

.view-developer-sites {
  .italic-text {
    font-style: italic !important;
  }

  .heading-2 {
    font-size: rem(35px);
    font-style: normal;
    font-weight: 400;
    line-height: 99%;
    letter-spacing: -0.7px;
    
  }
  .heading-3 {
    font-size: rem(15px);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  .heading-4 {
    font-size: rem(25px);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-transform: uppercase;

    @include media-max(sm) {
      font-size: rem(16px);
    }
  }

  .caption {
    font-size: rem(15px);
    color: $color-gray-7;
  }

  .simple-parallax-initialized {
    height: 100%;

    picture {
      height: 100%;
      width: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  .heading {
    text-transform: uppercase;
  }

  .scroll-down-button-with-text {
    gap: rem(10px);
    p {
      font-style: italic;
    }
    i {
      width: rem(30px);
      height: auto;
    }
  }

  .m-t-auto{
    margin: auto 0;
  }

  .m-top-auto{
    margin-top: auto;
  }

}
