.view-portfolio-woman-sport-products-section {
  padding: rem(100px) 0;

  &__products-item:not(:last-child) {
    // margin-bottom: rem(200px);
    margin-bottom: rem(150px);
  }

  &__products-item {
    &--empty {
      // margin-top: -20%; //rem(-150px);
      // margin-bottom: -20% !important; //rem(-150px) !important;
      // height: 80vw;
      margin-bottom: 0 !important;
      height: rem(600px);
    }

    &--overlay-next {
      // margin-bottom: -25% !important;
      margin-bottom: 0 !important;
      transform: translateY(50%);
    }

    &--overlay-prev {
      // margin-top: -25% !important;
      transform: translateY(-50%);

      // margin-top: 0;
    }

    // &--accent {
    //   padding: rem(100px) 0;
    //   margin-bottom: rem(450px) !important;
    // }

    // &--accent &-image {
    //   position: relative;
    //   bottom: -100px;
    //   transform: translateY(50%);
    // }

    // &--overlay-next {
    //   margin-bottom: -45% !important;
    // }
  }

  &__products-item-label {
    margin-bottom: rem(30px);
  }

  @include media-min(md) {
    &__products-item {
      &:not(:last-child) {
        margin-bottom: rem(200px);
      }

      &--empty {
        height: rem(700px);
      }
    }
  }

  @include media-min(xl) {
    padding: rem(200px) 0;

    &__products-item {
      &--empty {
        height: rem(1100px);
      }
    }
  }
}
