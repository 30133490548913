$blue-bg: #0625C8;
$yellow-bg: #DEBF02;

.portfolio-item-section--type-git-3 {
    padding-bottom: 0;
}

.view-portfolio-globe-it-about {
    align-content: stretch;
    &__block {
        display: flex;
        flex: 50%;
        background-color: #fff;
        @media (max-width: 767px) {
            flex: 100%;
        }
        &.blue {
            background-color: $blue-bg;
            align-content: flex-end;
        }
        &.yellow {
            background-color: $yellow-bg;
            align-content: flex-end;
        }
        &.white {
            padding-top: 200px;
            padding-bottom: 270px;
            @media (max-width: 1200px) {
                padding-top: 100px;
                padding-bottom: 170px;
            }
            @media (max-width: 767px) {
                padding-top: 0px;
            }
        }
        & > div {
            display: flex;
        }
    }
    &__img-cont {
        margin: auto auto 0;
        max-width: 500px;
        @media (max-width: 1200px) {
            max-width: 300px;
        }
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }
    &__color-block {
        text-align: center;
        & > span {
            width: 33%;
            height: auto;
        }
        
    }
}