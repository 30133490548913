.circular-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: pre;

  &__letter-wrapper {
    position: absolute;
  }

  &__letter {
    display: block;

    transform-origin: center center;
    // transform: translateY(-100%);
  }
}
