.view-portfolio-nova-group-parallax-section {
  @import "./vars";

  height: 100vh;

  display: none;

  @include media-min(xxl) {
    display: block;
  }
}
