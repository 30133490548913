.portfolio-item-section--type-csd-8 {
  position: relative;
  z-index: 2;
  .portfolio-item-section {
    &__figure-caption {
      transform: translateY(100%);
      white-space: nowrap;
    }
    &__figure-caption-item {
      &--top {
        margin-bottom: rem(47);
      }
    }
  }
  .view-portfolio-csd-8 {
    &__block {
      justify-content: space-between;
    }
    &__img {
      padding-top: rem(532);
      transform: translateY(18.5vw);
    }
  }
  @include media-max(xl) {
    .portfolio-item-section {
      &__figure-caption {
        margin-top: rem(20);
        transform: none;
        margin-bottom: 50px;
      }
      &__figure-caption-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .view-portfolio-csd-8 {
      &__img {
        padding-top: rem(200);
        transform: translateY(28.5vw);
      }
    }
  }
  @include media-max(md) {
    .portfolio-item-section {
      &__figure-caption {
        margin-top: rem(20);
      }
      &__figure-caption-item {
        flex-direction: column;
        justify-content: space-between;
        &--top {
          margin-bottom: rem(30);
        }
      }
    }
    .view-portfolio-csd-8 {
      &__img {
        padding-top: rem(100);
        transform: translateY(0vw);
        width: 70%;
        margin-right: rem(20);
      }
    }
  }
}