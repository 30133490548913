.icon {
  @import "./vars";

  $this: &;

  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;

  &__svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &--circle-caret-right {
    background-color: currentColor;
    border-radius: 1em;

    #{$this}__svg {
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: $icon-circle-caret-right-svg_left;

      margin: auto;

      width: $icon-circle-caret-right-svg_width;
      height: $icon-circle-caret-right-svg_height;

      color: $icon-circle-caret-right-svg_color;
    }
  }

  &--arrow-down {
    width: $icon-arrow-down_width;
  }

  &--arrow-right {
    height: $icon-arrow-right_height;
  }
}
