.toggle-switch {
  $slider_width: em(60px);
  $slider_height: em(36px);
  $slider_padding: em(3px);

  $toggler_size: $slider_height - $slider_padding * 2;
  $checked-toggler_translate-x: $slider_width - $toggler_size - $slider_padding *
    2;

  $transition: 0.4s ease;

  // $slider_width:
  display: inline-block;

  cursor: pointer;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: relative;

    display: inline-block;

    width: $slider_width;
    height: $slider_height;

    border-radius: $slider_height;

    background: #c3c3c3;

    font-size: inherit;

    transition: $transition;

    &:before {
      content: "";

      position: absolute;

      left: $slider_padding;
      bottom: $slider_padding;

      width: $toggler_size;
      height: $toggler_size;
      border-radius: 100%;

      background-color: #fff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
        0px 3px 1px rgba(0, 0, 0, 0.06);

      transition: $transition;
    }
  }

  &__input:checked + &__slider {
    background-color: #3665fa;

    &:before {
      transform: translateX($checked-toggler_translate-x);
    }
  }
}
