.link {
  display: inline-block;

  color: inherit;

  text-decoration: none;

  &--normal {
    text-decoration: underline;
  }

  &--highlighted {
    background-color: $primary-color;
    color: $color-white;
    text-decoration: none;
  }

  &--normal {
    $weight: 0.09em;
    // text-decoration: none;

    // &:after {
    //   content: "";

    //   bottom: -2px;
    //   left: 0;
    //   width: 100%;
    //   height: $weight;
    //   background-color: currentColor;
    // }

    // &:hover {}

    text-decoration: none;
    background: linear-gradient(
      to right,
      currentcolor,
      currentColor,
      currentColor
    );
    background-size: 100% $weight;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    transition: background 0.4s;

    &:hover {
      background-size: 0 $weight;
    }
  }
}
