@import "/src/scss/mixins.scss";

.services-item-price-section {
  $p: &;
  background-color: $color-gray-l93;
  padding: 200px 0;

  @include media-max(md) {
    padding: rem(125px) 0;
  }

  @include media-max(sm) {
    padding: rem(100px) 0;
  }

  .title {
    font-size: 69px;

    @include media-max(md) {
      font-size: 40px;
    }
  }

  .job-caption {
    font-size: 15px;
    letter-spacing: 0.01em;
    line-height: 1.1;
  }

  .middle-col {
    position: relative;

    @include media-between(md, lg) {
      flex: 0 0 50%;
      max-width: 50%;
    }

  }

  .servicepage-v2 {
    .job-docs {
      text-transform: lowercase;
    }
  }

  &__price-per-hour-container {
    @include media-max(md) {
      gap: 32px;
    }

    .subitem:not(:last-child) {
      margin-bottom: 30px;

      @include media-max(lg) {
        margin-bottom: 100px;
      }

      @include media-max(md) {
        margin-bottom: 70px;
      }

      &.lowmargin {
        margin-bottom: rem(30px);

        &:first-child {
          //margin-bottom: rem(5px);
        }
      }
    }

    .heading-2-margin {
      margin-bottom: 7px;
      font-feature-settings: "cpsp"on, "ss02"on;
    }

    .heading-3-margin {
      margin-bottom: 5px;
    }
  }

  .button-container-subitem {
    margin-top: 46px;

    @include media-min(md) {
      display: none;
    }
  }

  &__header {
    // gap: 45px;
    margin-bottom: 115px;

    @include media-max(lg) {
      margin-bottom: 95px;
    }

    @include media-max(md) {
      margin-bottom: 75px;
    }
  }

  .price-subtitle {
    align-content: flex-end;

    @include media-max(lg) {
      margin-top: 45px;
    }
  }

  .aunchor {
    margin-top: auto;
    flex: 0;

    * {
      text-align: left;
      font-style: italic;
      text-transform: uppercase;
      margin-top: 1px;
    }

    button {
      @include media-max(sm) {
        width: 100%;
        justify-content: space-between;
      }
    }

    @include media-max(md) {
      padding-top: rem(20px);
    }
  }

  .aunchor-desc {
    @include media-max(md) {
      display: none;
    }
  }

  .more-btn {
    margin-top: 32px;
  }

  .title-container-subitem {
    display: flex;
    align-content: space-between;
    flex-direction: column;

    @include media-max(md) {
      //display: none;
    }
  }

  .nowrap-price {
    white-space: nowrap;
    margin-bottom: 5px;
  }

  .price-title {
    padding-right: 20px;

    @include media-max(lg) {
      margin-bottom: 20px;
      padding-right: 0;
    }
  }

  .price-cross {
    .price-title {
      padding-right: 0;
    }

    @include media-max(lg) {
      .price-title {
        font-size: rem(25px);
        margin-bottom: 0;
      }

      .services-item-price-section__heading-2-adaptive {
        font-size: rem(25px);
      }
    }

    @media (max-width: 413px) {
      .services-item-price-section__heading-2-adaptive {
        font-size: rem(19px);
      }
    }
  }

  .cross {
    margin-top: 8px;
    font-size: rem(17px);

    @include media-max(lg) {
      margin-top: 5px;
    }

    @media (max-width: 413px) {
      margin-top: 3px;
      font-size: rem(14px);
    }
  }

  .caption-cross {
    position: absolute;
    max-width: rem(190px);
    font-size: rem(15px);

    @media (max-width: 413px) {
      font-size: rem(13px);
    }
  }

  &__heading-2-adaptive {
    // white-space: nowrap;
    font-size: 30px;
    font-feature-settings: "cpsp"on, "ss02"on;

    @include media-max(sm) {
      font-size: 25px;
    }
  }

  &__heading-3-adaptive {
    font-size: rem(25px);

    @include media-max(md) {
      font-size: rem(18px) !important;
    }
  }


  .body-item {
    &:not(:last-child) {
      margin-bottom: 80px;
      //padding-top: 110px;

      @include media-max(sm) {
        margin-bottom: 65px;
        //padding-top: rem(65px);
      }
    }
  }

  .heading-2-margin {
    flex: 0;
  }

  &__payment-steps-item {
    &:first-child {
      @include media-max(sm) {
        //margin-top: rem(45px);
      }
    }

    &:not(:last-child) {
      margin-bottom: rem(30px);
    }

    .itemprice-title {
      text-transform: uppercase;
      margin-bottom: rem(8px);
    }

    .description {
      width: 100%;
      font-size: rem(15px);
    }
  }

  &__document-link-container {
    height: max-content;
    margin-top: rem(-70px);

    @include media-max(md) {
      margin-top: rem(35px);
    }
  }

  &.outstaffing {
    .services-item-price-section__price-per-hour-container{

      .subitem:not(:last-child) {
        margin-bottom: 30px;

        @include media-max(lg) {
          margin-bottom: 50px;
        }

        @include media-max(md) {
          margin-bottom: 60px;
        }
      }
    }
  }
}