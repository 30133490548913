@import "/src/scss/mixins.scss";

.view-services-services-section {
  *{
    text-decoration: none !important;
  }
  &__link-item {
    padding: rem(73px) rem(60px);

    &:nth-child(odd){
      background-color: $color-black-haze;
    }

    @include media-max(xl) {
      padding: rem(73px) rem(24px);
    }

    @include media-max(md) {
      padding: rem(25px) rem(24px);
    }

    @include media-max(sm) {
      padding: rem(15px) rem(24px);
    }

    .heading-6{
        margin-top: 0;
        margin-bottom: rem(8px);
      
    }

    .subheading{
      @include media-max(xl) {
        font-size: rem(38px);
      }

      @include media-max(md) {
        margin-bottom: rem(20px);
      }

      @include media-max(sm) {
        font-size: rem(27px);
      }
    }

    .subitem-container {
      flex-wrap: wrap;
      column-gap: rem(34px);

      .subitem{
        font-style: normal;
        font-family: AnonymousPro;
        text-transform: lowercase;
      }
      .subitem:not(:first-child) {
        //margin-left: rem(34px);
      }

      .subitem:last-child::after{
        content: "...";
      }
    }

    .arrow-link {
      color: $color-shark-1;
      display: flex;
      i {
        height: rem(43px);
        width: rem(37px);
        transform: rotate(-135deg);
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        transition: 0.3s transform;
        will-change: transform;
      }
    }

    &:hover {
      .arrow-link {
        i {
          transform: rotate(-135deg) translate(0, 15px);
        }
      }
    }
  }
}
