.lead-section {
  @import "./vars";

  height: 100vh;
  min-height: $lead-section_min-height;

  &__container,
  &__inner {
    height: 100%;
  }

  &__container {
    padding-top: $lead-section-container_padding-top;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__body {
    flex: 1 1 auto;
    height: 100%;
  }

  &__arrow {
    font-size: $lead-section-arrow_font-size;
  }

  @include media-max(md) {
    &__title {
      font-size: $lead-section-title_font-size_max-md;
    }
  }

  @include media-min(md) {
    // min-height: $lead-section_min-height_min-md;

    &__container {
      padding-top: $lead-section-container_padding-top_min-md;
    }

    &__arrow {
      font-size: $lead-section-arrow_font-size_min-md;
    }

    &__footnote {
      font-size: $lead-section-footnote_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $lead-section-container_padding-top_min-xxl;
    }

    &__title {
      line-height: $lead-section-title_line-height_min-xxl;
    }

    &__footnote {
      font-size: $lead-section-footnote_font-size_min-xxl;
    }
  }
}
