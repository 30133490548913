.portfolio-item {
  @import "./vars";

  $this: &;

  display: block;
  cursor: none;

  &__preview-inner {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    margin: auto;

    overflow: hidden;
  }

  &__preview {
    position: relative;
    background-color: $portfolio-item-preview_background-color;

    &::before {
      content: "";

      display: block;

      padding-top: $portfolio-item-preview_aspect-ration;
    }
  }

  &__preview-media {
    position: relative;
  }

  &__preview--video &__preview-media {
    object-fit: cover;
    width: 100%;
    pointer-events: none;

    // iframe {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   // height: $portfolio-item-preview_aspect-ration) !important;
    //   // width: vh(366 /227) !important;
    //   min-height: 100%;
    //   min-width: 100%;
    //   transform: translate(-50%, -50%);
    // }
  }

  &__preview--image &__preview-media {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__caption {
    margin-top: $portfolio-item-caption_margin-top;
  }

  &__name {
    margin-right: $portfolio-item-name_margin-right;

    white-space: nowrap;
  }

  &__type {
    padding-top: $portfolio-item-type_padding-top;
  }

  &.no-detail {
    pointer-events: none;
  }

  &.no-padding {

  }

  @include media-max(md) {
    &__preview {
      background-color: none !important;
    }
  }

  @include media-min(md) {
    // &__preview::before {
    //   padding-top: percentage($portfolio-item-preview_aspect-ration_min-md);
    // }

    &__preview--video &__preview-media {
      // iframe {
      // height: vw($portfolio-item-preview_aspect-ration_min-md) !important;
      //   // wi  dth: vh(600 / 385) !important;
      // }
    }

    &__name {
      margin-right: $portfolio-item-name_margin-right_min-md;
    }
  }

  @include media-min(xxl) {
    // &__preview::before {
    //   padding-bottom: $portfolio-item-preview_aspect-ration_min-xxl;
    // }
  }
}
