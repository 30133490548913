@import "src/scss/layout/header/vars";

$view-about-lead-section-design-height: 631px;

// container
$view-about-lead-section-container_padding-top_min-md: (
  rem(130px) - $header_height_min-md
);

$view-about-lead-section-container_padding-bottom: rem(25px);
$view-about-lead-section-container_padding-bottom_min-md: rem(15px);
$view-about-lead-section-container_padding-bottom_min-xxl: rem(5px);

// header
$view-about-lead-section-header_margin-bottom: rem(40px);
$view-about-lead-section-header_margin-right: 0;

// stat-number
$view-about-lead-section-stat-number_font-size: rem(60px);
$view-about-lead-section-stat-number_font-size_min-md: rem(80px);

$view-about-lead-section-stat-number_margin-right: rem(3px);
$view-about-lead-section-stat-number_margin-right_min-md: rem(5px);

// stats-list list-item
$view-about-lead-section-stats-list-item_margin-bottom: rem(45px);
$view-about-lead-section-stats-list-item_margin-bottom_min-md: 0;

// $view-about-lead-section-stats-list-item_margin-right_min-md: rem(35px);

// description
$view-about-lead-section-description_max-width_min-md: rem(360px);

$view-about-lead-section-description_font-size_max-md: rem(16px);

// stats-list
$view-about-lead-section-stats-list_margin-bottom_min-md: rem(45px);
$view-about-lead-section-stats-list_margin-bottom_min-xxl: rem(40px);

// background-parallax
$view-about-lead-section-background-parallax_margin-left: -$grid-container-xl /
  2;
