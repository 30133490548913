.view-portfolio-ensemble-branding-section{
    overflow-x: hidden;
    img{
        width: 100%;
    }
    &.border-bottom{
        border-bottom: 4px solid #E8E8E8;
    }
    &.border-top{
        border-top: 4px solid #E8E8E8;
    }
    .number-slide{
        font-size: rem(15);
    }
    .caption-slide{
        font-size: rem(15);
    }
    .text-slide{
        font-size: rem(15);
    }
    &.portfolio-item-section{
        &--ens-type-variant{
            padding-top: rem(95);
            padding-bottom: rem(190);
            background-size: cover;
            background-position: center;
            .variant-title{
                text-transform: uppercase;
                margin-bottom: rem(214);
                font-size: rem(15);
                margin-top: rem(16);
            }
            .variant-text{
                margin-bottom: rem(214);
                p{
                  font-feature-settings: "cpsp" on, "ss02" on;
                  font-size: rem(35);
                  margin-bottom: rem(50);
                }
                span{
                  font-size: rem(15);
                }
            }
            .count-slide{
                font-size: rem(15);
            }
        }
        &--ens-3{
            .portfolio-item-section__inner{
                position: relative;
            }
            .wrap-img{
                padding-right: 9%;
            }
            .number-slide{
                position: absolute;
                top: 20%;
                right: 60px;
            }
        }
        &--img-line{
            .row{
                margin-right: -4px;
                margin-left: -4px;
            }
            .wrap-img{
                border-right: 4px solid #E8E8E8;
                border-left: 4px solid #E8E8E8;
            }
            &.double{
                .wrap-img{
                    width: 50%;
                    padding: 10.7% 6.9%;
                }
            }
            &.triple{
                .wrap-img{
                    width: 33.3%;
                    padding: 4.7% 3.1%;
                }
            }
        }
        &--ens-4{
            padding-top: rem(180);
            padding-bottom: rem(180);
            background-position: center;
            background-size: cover;
            .text-slide{
                margin-top: auto;
            }
        }
        &--ens-5{
            padding-top: rem(55);
            padding-bottom: rem(190);
            .wrap-img{
                margin-top: rem(190);
            }
        }
        &--ens-6{
            padding-top: rem(55);
            padding-bottom: rem(190);
            background-size: cover;
            background-position: center;
            .wrap-img{
                margin-top: rem(190);
            }
        }
        &--ens-7{
            padding-top: rem(50);
            padding-bottom: rem(190);
            .wrap-img{
                margin-top: rem(120);
            }
        }
        &--ens-9{
            padding-top: rem(55);
            padding-bottom: rem(190);
            background-size: cover;
            background-position: bottom;
            .wrap-img{
                margin-top: rem(190);
            }
        }
        &--ens-10{
            padding-top: rem(50);
            padding-bottom: rem(190);
            .caption-slide:nth-child(2){
                text-align: right;
            }
            .wrap-img{
                margin-top: rem(195);
            }
        }
        &--ens-12{
            padding-top: rem(60);
            padding-bottom: rem(190);
            .wrap-img{
                margin-top: rem(195);
            }
        }
        &--ens-14{
            padding-top: rem(55);
            padding-bottom: rem(194);
            .wrap-img{
                margin-top: rem(190);
            }
        }
        @include media-max(xl) {
            &--ens-type-variant{
                padding-bottom: rem(150);
                .variant-title{
                    margin-bottom: rem(60);
                    margin-top: rem(0);
                }
                .variant-text{
                    margin-bottom: rem(150);
                    p{
                        margin-bottom: rem(35);
                    }
                }
                .count-slide{
                    margin-bottom: rem(60);
                }
            }
            &--ens-3{
                .number-slide {
                    top: 13%;
                    right: 24px;
                }
                .wrap-img {
                    padding-right: 11%;
                }
            }
            &--ens-4{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .text-slide {
                    margin-top: rem(30);
                }
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-5{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-6{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-7{
                padding-bottom: rem(150);
            }
            &--ens-9{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-10{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-12{
                padding-top: rem(55);
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
            &--ens-14{
                padding-bottom: rem(150);
                .wrap-img{
                    margin-top: rem(150);
                }
            }
        }
        @include media-max(md) {
            &--ens-type-variant{
                padding-bottom: rem(95);
                .variant-title{
                    margin-bottom: rem(40);
                }
                .variant-text{
                    margin-bottom: rem(100);
                    p{
                        font-size: rem(25);
                    }
                    span{
                        margin-bottom: rem(20);
                        display: inline-block;
                    }
                }
                .count-slide{
                    margin-bottom: rem(40);
                }
            }
            &--img-line{
                padding-bottom: rem(95);
                .row{
                    margin-right: 0;
                    margin-left: 0;
                }
                .wrap-img{
                    border-right: none;
                    border-left: none;
                }
                &.double{
                    .wrap-img{
                        width: 100%;
                        padding: rem(95) rem(24) 0;
                    }
                }
                &.triple{
                    .wrap-img{
                        width: 100%;
                        padding: rem(95) 24px 0;
                    }
                }
            }
            &--ens-3{
                .number-slide {
                    top: 9%;
                }
                .wrap-img {
                    padding-right: 13%;
                }
            }
            &--ens-4{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .number-slide {
                    //margin-bottom: rem(30);
                }
                .text-slide {
                    margin-top: rem(30);
                }
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-5{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-6{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-7{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-9{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .caption-slide:first-child{
                    margin-bottom: rem(30);
                }
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-10{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .caption-slide{
                    &:first-child{
                        margin-bottom: rem(30);
                    }
                    &:nth-child(2){
                        text-align: left;
                    }
                }
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-12{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .caption-slide:first-child{
                    margin-bottom: rem(30);
                }
                .wrap-img{
                    margin-top: rem(95);
                }
            }
            &--ens-14{
                padding-top: rem(40);
                padding-bottom: rem(95);
                .caption-slide:first-child{
                    margin-bottom: rem(30);
                }
                .wrap-img{
                    margin-top: rem(95);
                }
            }
        }
    }
}