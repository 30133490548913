.view-about-lead-section {
  @import "./vars";

  &__background {
    display: none;
  }

  &__background-container {
    overflow: hidden;
    margin-left: $view-about-lead-section-background-parallax_margin-left;
    height: 100%;

    .simpleParallax {
      height: 100%;
    }

    .image {
      object-fit: cover;
    }
  }

  &__background-col-with-parallax {
    height: 100%;
  }

  &__content,
  &__container {
    height: 100%;
  }

  &__container {
    position: relative;
  }

  &__header {
    margin-bottom: $view-about-lead-section-header_margin-bottom;
  }

  &__body-inner {
    display: flex;
    flex-direction: column;
  }

  &__description {
    margin-top: auto;
  }

  &__stat-number,
  &__stat-label {
    display: inline-block;
  }

  &__stat-number {
    margin-right: $view-about-lead-section-stat-number_margin-right;

    font-size: $view-about-lead-section-stat-number_font-size;

    vertical-align: bottom;
  }

  &__stats-list .list__item:not(:last-child) {
    margin-bottom: $view-about-lead-section-stats-list-item_margin-bottom;
  }

  &__stat-label {
    vertical-align: top;
  }

  &__arrow,
  &__footer-title {
    display: none;
  }

  &__title-text {
    display: inline-block;
  }

  &__background-parallax-background-image {
    width: 100%;
    height: 100%;
  }

  &__heading-description {
    font-feature-settings: "cpsp" on, "ss02" on;
  }

  @include media-max(md) {
    &__description {
      font-size: $view-about-lead-section-description_font-size_max-md;
    }
  }

  @include media-min(md) {
    &__container {
      // padding-top: $view-about-lead-section-container_padding-top_min-md;
    }

    &__header {
      display: none;
    }

    &__body-inner {
      margin-left: auto;
    }

    &__stat-number {
      margin-right: $view-about-lead-section-stat-number_margin-right_min-md;

      font-size: $view-about-lead-section-stat-number_font-size_min-md;
    }

    &__stats-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: $view-about-lead-section-stats-list_margin-bottom_min-md;

      .list__item:not(:last-child) {
        margin-bottom: $view-about-lead-section-stats-list-item_margin-bottom_min-md;
      }
    }

    &__description {
      max-width: $view-about-lead-section-description_max-width_min-md;
      margin-bottom: auto;
      margin-top: 0;
    }

    &__footer-title {
      display: block;
    }
  }

  @include media-min(lg) {
    // min-height: 100vh;
    margin-bottom: rem(130px);

    &__footer-title {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__title {
      padding-right: rem(100px);
    }

    &__arrow {
      display: inline-block;
    }

    &__stats-list {
      margin-bottom: $view-about-lead-section-stats-list_margin-bottom_min-xxl;
    }

    &__background {
      display: block;
      margin-bottom: rem(-130px);
    }
  }
}
