.view-home-link-section {
  .section-link__col-with-additional {
    display: none;
  }

  // .section-link__col-with-label {
  //   text-align: right;
  // }

  @include media-min(md) {
    // .section-link__col-with-label {
    //   text-align: left;
    // }

    &__label-watch-word {
      display: none;
    }

    .section-link__col-with-additional {
      display: block;
    }
  }
}
