.portfolio-item-section--type-git-8 {
    padding-top: 0;
    padding-bottom: 0;
    // padding-bottom: 250px;
    @media (max-width: 575px) {
      padding-bottom: 150px;
    }
}
.view-portfolio-globe-it-contact-404 {
    .row {
        &__2 {
            margin: 0 auto;
        }
    }
    &__content {
        position: relative;
        margin: 80px auto 0;
    }
    @media (max-width: 575px) {
      .offset-auto {
        margin-right: auto;
      }
    }
}
.not-found-hero {
    $container_padding-top: rem(35px);
    $container_padding-top_min-md: rem(40px);
    $container_padding-top_min-xl: rem(90px);
  
    $container_padding-bottom: rem(40px);
    $container_padding-bottom_min-md: rem(20px);
    $container_padding-bottom_min-xl: rem(60px);
  
    $header_margin-bottom: rem(45px);
    $header_margin-bottom_min-xl: 0;
  
    $title_font-size: rem(40px);
    $title_font-size_min-sm: rem(49px);
    $title_font-size_min-md: rem(70px);
    $title_font-size_min-xl: rem(69px);
  
    $title-container_margin-bottom: rem(30px);
  
    $description-container_margin-bottom_min-md: rem(30px);
  
    $alternative-container_margin-bottom_min-xl: rem(30px);
  
    // height: 80%;
    overflow: hidden;
    position: absolute;
    top: 12%;
    right: 15%;
    width: 43%;
    // transform: scale(0.5);
    transform-origin: center;
    @media (max-width: 991px) {
      width: 54%;
      right: 10%;
    }
    @media (max-width: 575px) {
      right: 8%;
    }
    &__container,
    &__inner {
      height: 100%;
    }
  
    &__header {
      margin-bottom: $header_margin-bottom;
    }
  
    &__body {
      align-self: flex-end;
    }
  
    &__container {
      overflow-y: auto;
    }
  
   
    
    &__control-cabinet-drawing {
      position: relative;
  
      width: 100%;
      height: 100%;
  
      max-width: 100%;
  
      overflow: visible;
  
    //   z-index: -1;
  
      user-select: none;
    }
   
  }
  