@import "/src/scss/mixins.scss";

.view-developer-sites-customer-reviews-section {
  $p: &;
  background: $color-black-haze;
  padding: 185px 0;

  @include media-max(md) {
    padding: rem(125px) 0;
  }

  @include media-max(sm) {
    padding: rem(100px) 0;
  }

  &__header {
    margin-bottom: 155px;

    @include media-max(xl) {
      gap: 40px;
      margin-bottom: 100px;
    }

    @include media-max(md) {
      gap: 45px;
      margin-bottom: 65px;
    }
  }

  .reviewer {
    font-size: 25px;

    @include media-max(md) {
      font-size: 18px;
    }
  }

  .review-subname {
    font-size: 15px;
    text-transform: uppercase;

    @include media-max(md) {
      font-size: 14px;
    }
  }

  &__footer {
    justify-content: space-between;
    margin-top: 110px;

    @include media-max(lg) {
      flex-direction: row-reverse;
      margin-top: 80px;
    }

    @include media-max(md) {
      flex-direction: row-reverse;
      margin-top: 65px;
    }
  }

  &__slider-header {
    margin-bottom: rem(68px);

    @include media-max(md) {
      margin-bottom: rem(30px);
    }
  }

  &__paginator {
    gap: rem(30px);

    .paginator-item {
      width: rem(70px);
      height: rem(70px);
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      @include media-max(md) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ededed;
        opacity: 0.5;
        top: 0;
        left: 0;
        transition: opacity 0.4s ease-in-out;
      }
    }

    .active {
      &::after {
        opacity: 0;
      }

      @include media-max(md) {
        display: initial;
      }
    }
  }

  &__footer-text {
    margin: auto 0;
  }

  &__footer-icon {
    transform: rotate(270deg);
    transition: transform 0.4s ease-in-out padding-left 0.4s ease-in-out;
    will-change: transform;
    height: rem(70px);

  }

  &__review-footer-text {
    font-size: 50px !important;

    @include media-max(lg) {
      font-size: 45px !important;
    }


    @include media-max(md) {
      font-size: 27px !important;
    }

  }

  &__footer-navigation {
    width: 50%;
  }

  &__award-tag {
    gap: rem(5px);
    padding: rem(5px) rem(14px);
    border-radius: rem(30px);
    background: #ededed;
    display: inline-flex;
  }
}