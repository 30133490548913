.privacy-policy {
  &__nav {
    margin-bottom: rem(80px);
  }

  &__chapter-header {
    margin: rem(60px) 0;
  }

  // &__chapter:not(:last-child) {
  //   margin-bottom: rem(85);
  // }

  &__nav-list {
    counter-reset: contents;

    padding-left: rem(55px);
  }

  &__nav-label {
    margin-bottom: rem(20px);
  }

  &__nav-link {
    text-align: left;
  }

  &__nav-item {
    position: relative;

    &:before {
      counter-increment: contents;
      content: counter(contents) ".\00a0";

      position: absolute;

      transform: translateX(-100%);
    }
  }

  &__content {
    counter-reset: chapter;
  }

  &__chapter {
    counter-increment: chapter;
  }

  &__chapter-heading {
    &:before {
      content: counter(chapter) ".\00a0";
    }
  }

  &__chapter-body {
    .highlighted {
      background-color: $primary-color;
      color: $color-white;
    }

    a.highlighted {
      text-decoration: none;
    }

    p,
    li {
      text-indent: percentage(1 / 5); //rem(120px);
    }

    p {
      margin-bottom: em(45px, 18px);
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
      }
      li:before {
        // position: absolute;
        content: "– ";
        // transform: translateX(-200%);
      }
    }

    ol {
      padding: 0;
      counter-reset: chapter-list;
      list-style-type: none;

      &.chapter-list > li:before {
        counter-increment: chapter-list;
        content: counter(chapter) "." counters(chapter-list, ".") ".\00a0";
      }
    }
  }

  @include media-min(md) {
    // &__nav {
    //   margin-bottom: rem(150px);
    // }

    &__nav-label {
      margin-bottom: 0;
    }

    &__chapter:not(:last-child) {
      margin-bottom: rem(30px);
    }

    &__chapter-header {
      margin-bottom: rem(70px);
    }
  }

  @include media-min(xl) {
    // &__nav {
    //   margin-bottom: rem(200px);
    // }

    &__chapter:not(:last-child) {
      margin-bottom: rem(65px);
    }

    &__chapter-header {
      margin-bottom: rem(80px);
    }
  }
}
