.vacancy-experience-section {
  @import "./vars";

  position: relative;

  &__container {
    position: relative;

    padding-top: $vacancy-experience-section-container_padding-top;
    padding-bottom: $vacancy-experience-section-container_padding-bottom;
  }

  &__heading {
    margin-bottom: $vacancy-experience-section-heading_margin-bottom;
    font-size: $vacancy-experience-section-heading_font-size;
  }

  &__subheading {
    margin-bottom: $vacancy-experience-section-subheading_margin-bottom;
    text-align: right;
  }

  &__level-item {
    margin-bottom: $vacancy-experience-section-level-item_margin-bottom;
  }

  &__level-item-label {
    margin-bottom: $vacancy-experience-section-level-item-label_margin-bottom;
  }

  @include media-min(sm) {
    &__heading {
      font-size: $vacancy-experience-section-heading_font-size_min-sm;
    }
  }

  @include media-min(md) {
    &__container {
      padding-top: $vacancy-experience-section-container_padding-top_min-md;
      padding-bottom: $vacancy-experience-section-container_padding-bottom_min-md;
    }

    &__heading {
      margin-bottom: $vacancy-experience-section-heading_margin-bottom_min-md;
      font-size: $vacancy-experience-section-heading_font-size_min-md;
    }

    &__subheading {
      margin-bottom: $vacancy-experience-section-subheading_margin-bottom_min-md;
    }
  }

  @include media-min(xl) {
    &__container {
      padding-top: $vacancy-experience-section-container_padding-top_min-xl;
      padding-bottom: $vacancy-experience-section-container_padding-bottom_min-xl;
    }

    &__heading {
      margin-bottom: $vacancy-experience-section-heading_margin-bottom_min-xl;
      font-size: $vacancy-experience-section-heading_font-size_min-xl;
    }

    &__subheading {
      margin-bottom: $vacancy-experience-section-subheading_margin-bottom_min-xl;
    }

    &__level-item {
      margin-bottom: $vacancy-experience-section-level-item_margin-bottom_min-xl;
      margin-right: $vacancy-experience-section-level-item_margin-right_min-xl;
    }
  }
}
