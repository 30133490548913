$color-gray-l4: #0b0b0b;
$color-gray-l31: #4e4e4e;
$color-gray-l77: #c4c4c4;
$color-gray-l88: #e0e0e0;
$color-gray-l93: #ededed;
$color-gray-l95: #f1f1f1;

$color-gray-7: #8a8a8b;

$color-shark-1: #1f1f21;
$color-shark-2: #18181b;
$color-shark-3: #202022;

$color-blue-ribbon-l47: #0241ed;
$color-blue-ribbon-l56: #215bfd;
$color-blue-3: #384dc8;

$color-black-haze: #f3f4f4;
$color-iron: #dddddf;
$color-jumbo: #8a8a8b;
$color-abbey: #515253;
$color-masala: #3d3a37;

$color-totem-pole: #a10707;
$color-nutmeg: #802c2c;
$color-bright-red: #b00000;

$color-white: #fff;
$color-black: #000;

$primary-color: $color-shark-1;

$text-primary-color: $primary-color;
