.body {
  @import "./vars";

  margin: 0;

  font-family: $body_font-family;
  line-height: $body_line-height;
  font-weight: $body_font-weight;
  font-size: $body_font-size;
  color: $body_color;
  overflow-y: scroll;
  overflow-x: hidden;

  background-color: $body_background-color;
  overscroll-behavior: none;
  overscroll-behavior: none;

  &--menu-opened {
    overflow: hidden;

    .wrapper {
      overflow-y: scroll;
    }

    #root {
      overflow: visible;
    }
  }
}
