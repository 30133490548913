.text {
  @import "./vars";

  font-size: $text_font-size;
  line-height: $text_line-height;

  &--text-right &--accent {
    line-height: $text-accent_line-height;
    letter-spacing: $text-accent_letter-spacing;
    font-style: italic;
    text-transform: uppercase;
  }

  &--underline {
    text-decoration: underline;
  }

  &--secondary {
    font-family: $font-family-anonymous-pro;
    line-height: $line-height-xl;
  }

  &--light {
    color: $text-light_color;
  }

  &--right {
    text-align: right;
  }

  &--muted {
    color: $text-muted_color;
  }

  &--pre-line {
    white-space: pre-line;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--not-empty:empty:after {
    content: " ";
    white-space: pre;
  }

  &--lowercase {
    text-transform: lowercase;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  @include generate-modifiers-by-breakpoints(indent) {
    @each $text-indent-name, $text-indent-value in $text-indents {
      &-#{$text-indent-name} {
        text-indent: $text-indent-value;
      }
    }
  }

  @include generate-modifiers-by-breakpoints("fs") {
    @each $text-font-size-name, $text-font-size-value in $text-font-sizes {
      &-#{$text-font-size-name} {
        font-size: $text-font-size-value;
      }
    }
  }
}
