.view-portfolio-item-related-section {
  @import "./vars";
  @import "../../../layout/body/vars";

  position: relative;

  &__info {
    height: $view-portfolio-item-related-section-info_height;
  }

  &__info-container {
    height: 100%;

    padding-top: $view-portfolio-item-related-section-info-container_padding-top;
    padding-bottom: $view-portfolio-item-related-section-info-container_padding-bottom;
  }

  &__info-inner {
    height: 100%;

    display: flex;

    flex-direction: column;
    justify-content: space-between;
  }

  &__preview {
    height: $view-portfolio-item-related-section-preview_height;
  }

  &__preview-container {
    height: 100%;
  }

  &__preview--frame &__preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__preview--frame &__preview-container {
    padding-top: rem(32px);
    padding-bottom: rem(32px);
  }

  &__preview-image {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__preview--frame &__preview-image {
    width: auto;
    max-height: 100%;
    max-width: 100%;

    object-fit: contain;
    // max-width: none;
  }

  &__info-title {
    white-space: nowrap;
  }

  &__info-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__info-type {
    // display: none;

    margin-left: $view-portfolio-item-related-section-info-type_margin-left;
    vertical-align: top;
    opacity: 0.8;
  }

  &__info-name {
    display: inline;
    font-size: $view-portfolio-item-related-section-info-about-name_font-size;
    font-weight: $view-portfolio-item-related-section-info-about-name_font-weight;
    line-height: $view-portfolio-item-related-section-info-about-name_line-height;
    letter-spacing: $view-portfolio-item-related-section-info-about-name_letter-spacing;

    text-transform: uppercase;
    white-space: pre-line;
  }

  &__arrow {
    font-size: $view-portfolio-item-related-section-info-about-type_font-size;
  }

  &__preview-reveal-container {
    height: 100%;

    & > * {
      height: 100%;
    }
  }

  // @include media-min(sm) {
  //   &__info-type {
  //     display: inline-block;
  //   }
  // }

  @include media-min(md) {
    &__info {
      height: $view-portfolio-item-related-section-info_height_min-md;
    }

    &__info-container {
      padding-top: $view-portfolio-item-related-section-info-container_padding-top_min-md;
      padding-bottom: $view-portfolio-item-related-section-info-container_padding-bottom_min-md;
    }

    &__preview {
      height: $view-portfolio-item-related-section-preview_height_min-md;
    }

    &__arrow {
      font-size: $view-portfolio-item-related-section-info-about-type_font-size_min-md;
    }
  }

  @include media-min(lg) {
    &__info-container {
      padding-left: 0;
    }

    &__preview {
      height: $view-portfolio-item-related-section-preview_height_min-lg;
    }

    &__preview--frame {
      overflow: hidden;
    }

    &__preview--frame &__preview-container {
      justify-content: flex-end;

      width: 110%;
      padding-right: 0;
    }
  }

  @include media-min(xxl) {
    &__preview--frame &__preview-container {
      padding-top: rem(80px);
      padding-bottom: rem(80px);
    }
  }
}
