.footnote {
  @import "./vars";

  line-height: $footnote_line-height;
  font-size: $footnote_font-size;
  font-style: italic;
  text-transform: uppercase;

  &--tight {
    letter-spacing: $footnote-tight_letter-spacing;
  }

  // Генерируем классы для создания адаптивных размеров шрифта (.footnote--fs-md-big, .footnote--fs-md-large, ...)
  @include generate-modifiers-by-breakpoints(
    fs,
    $footnote-font-sizes-breakpoints
  ) {
    @each $font-size-name, $font-size-value in $footnote-font-sizes {
      &-#{$font-size-name} {
        font-size: $font-size-value;
      }
    }
  }

  @include media-min(md) {
    &--adaptive {
      font-size: $footnote-adaptive_font-size_min-md;
    }
  }

  @include media-min(xl) {
    &--adaptive {
      font-size: $footnote-adaptive_font-size_min-xxl;
    }
  }
}
