.view-services-lead-section {
  @import "./vars";

  margin-bottom: rem(45px);

  &__body-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__container {
    height: 100%;
  }

  &__body-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__col-with-service-type {
    max-width: $view-services-lead-section-col-with-service-type_max-width;
    text-align: right;
  }

  &__body-top,
  &__body-bottom-title {
    display: none;
  }

  &__services-group:not(:last-child) {
    margin-bottom: $view-services-lead-section-services-group_margin-bottom;
  }

  &__col-with-description {
    max-width: 625px;
  }

  &__col-with-service-list {
    margin-left: 25px;

    @include media-max(sm) {
      p {
        font-size: rem(14px);
      }
    }
  }

  @include media-min(md) {
    margin-bottom: rem(110px);

    &__header,
    &__body-bottom-cost-btn {
      display: none;
    }

    &__body-bottom-title {
      display: block;
    }

    &__body-top {
      display: flex;
      justify-content: space-between;
    }
  }

  @include media-min(xl) {
    margin-bottom: rem(180px);

    &__body-middle {
      margin: 0 0 0 auto;
    }

    &__body-inner {
      flex-direction: row;
    }

    &__body-top {
      align-content: flex-start;
    }

    &__col-with-description {
      margin-bottom: rem(105px);
    }

    &__col-with-service-type {
      min-width: $view-services-lead-section-col-with-service-type_max-width;
    }
  }
}
