.view-portfolio-item-about-section--type-aag {
  padding-top: rem(160);
  padding-bottom: rem(280);

  @include media-max(md) {
    padding-top: rem(100);
    padding-bottom: rem(150);
  }

  .view-portfolio-item-about-section__video {
    padding-top: 56%;
  }
}

.view-portfolio-aag-section {
  .description {
    row-gap: rem(20);
    margin-bottom: rem(200);
    @include media-max(md) {
      margin-bottom: rem(100);
    }
  }
}

.portfolio-item-section {

  &--type-aag-4 {
    padding-top: rem(200);
    padding-bottom: rem(260);

    .u-ratio {
      margin-bottom: rem(210);
      padding-top: 56%;
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }
    .description-img {
      margin-top: auto;
      text-align: right;

      @include media-max(md) {
        margin-top: rem(30);
        text-align: left;
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }

  &--type-aag-5 {
    padding-top: rem(200);
    padding-bottom: rem(260);

    .u-ratio {
      margin-bottom: rem(60);
      padding-top: 76%;
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }

  &--type-aag-6 {
    .col-half {
      max-width: 50%;
    }
  }

  &--type-aag-7{
    padding-top: rem(200);
    padding-bottom: rem(260);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }

  &--type-aag-8 {
    .col-half {
      max-width: 50%;
    }
  }

  &--type-aag-9 {
    padding-top: rem(200);
    padding-bottom: rem(260);
    .img-wrap {
      margin-bottom: rem(200);
      @include media-max(md) {
        margin-bottom: rem(100);
      }
    }

    .description-img {
      margin-top: auto;
      @include media-max(md) {
        margin-bottom: rem(30);
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }

  &--type-aag-11 {
    padding-top: rem(200);
    padding-bottom: rem(270);
    .img-wrap {
      &:not(:last-child) {
        margin-bottom: rem(30);
        @include media-max(md) {
          margin-bottom: rem(80);
        }
      }
    }

    .right-img {
      margin-top: rem(150);
      @include media-max(md) {
        margin-top: rem(80);
      }
    }

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }

  &--type-aag-12 {
    padding-top: rem(200);
    padding-bottom: rem(270);

    @include media-max(md) {
      padding-top: rem(100);
      padding-bottom: rem(150);
    }
  }
}