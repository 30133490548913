.view-portfolio-globe-it-pages {
    .darken {
        mix-blend-mode: darken;
        margin-top: 18px;
        margin-bottom: 164px;
        @media (max-width: 575px) {
            margin-bottom: 0;
            margin-top: 75px;
        }
    }
    .row {
        &__1 {
            margin-bottom: 165px;
        }
        &__2 {
            margin-bottom: 135px;
        }
        &__3 {
            margin-top: 132px;
            @media (max-width: 575px) {
                margin-top: 0;
                margin-bottom: 100px;
            }
        }
        &__4 {
            margin-bottom: 180px;
        }
        &__6 {
            margin-top: 97px;
        }
        &__7 {
            margin-bottom: 227px;
            @media (max-width: 575px) {
                margin-bottom: 127px;
                margin-top: 100px;
            }
        }
        
    }
    @media (max-width: 575px) {
        .offset-auto {
            margin-right: auto;
        }
    }
}
.portfolio-item-section--type-git-7 {
    padding-bottom: 0;
    padding-top: 156px;
}

