.portfolio-item-section--type-csd-11 {
  padding-top: rem(76);
  padding-bottom: rem(263);
  flex-direction: column;
  .portfolio-item-section {
    &__first-group {
      margin-bottom: rem(275);
      flex-wrap: nowrap;
      .portfolio-item-section {
        &__first-group-image {
          &--left {
            width: 87.2%;
            padding-top: rem(37);
            .portfolio-item-section {
              &__image-top {
                margin-bottom: rem(415);
                img{
                  border: 1px solid #1F1F21;
                }
              }
              &__description {
                margin-bottom: rem(47);
              }
            }
          }
          &--right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .portfolio-item-section {
              &__first-group-image {
                &--top {
                  width: 93.5%;
                  margin-left: auto;
                }
                &--description {
                  justify-content: space-between;
                  margin-bottom: rem(23);
                }
                &--bottom {
                  justify-content: flex-end;
                  margin-top: rem(180);
                  .portfolio-item-section {
                    &__inner {
                      &--arrow {
                        align-items: flex-end;
                        display: flex;
                        margin-right: rem(258);
                      }
                      &--image {
                        width: 26.6%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &__second-group-image {
      &--description {
        justify-content: space-between;
        margin-bottom: rem(47);
      }
      &--right-side {
        display: flex;
        align-items: flex-end;
        justify-content: right;
        .view-portfolio-csd-11{
        &__block{
          width: 91.8%;
        }
      }
    }
  }
}
  @include media-max(xl) {
    padding-bottom: rem(150);
    padding-top: rem(150);
    .portfolio-item-section {
      &__first-group {
        margin-bottom: rem(150);
        .portfolio-item-section {
          &__first-group-image {
            &--left {
              width: 87.2%;
            }
            &--right {
              .portfolio-item-section {
                &__first-group-image {
                  &--top {
                    width: 100%;
                  }
                  &--bottom {
                    .portfolio-item-section {
                      &__inner {
                        &--arrow {
                          margin-right: rem(158);
                        }
                        &--image {
                          width: 52%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__second-group-image {
        &--right-side {
          margin-top: rem(100);
        }
      }
    }
  }
  @include media-max(md) {
    padding-bottom: rem(100);
    padding-top: rem(100);
    .portfolio-item-section {
      &__first-group {
        flex-direction: column-reverse;
        margin-bottom: rem(100);
        .portfolio-item-section {
          &__first-group-image {
            &--left {
              width: 100%;
              align-items: stretch;
              .portfolio-item-section {
                &__image-top {
                  width: 80%;
                  margin-bottom: rem(80);
                }
                &__description {
                  justify-content: flex-end;
                  display: flex;
                }
                &__image-bottom {
                  width: 70%;
                  margin-left: auto;
                }
              }
            }
            &--right {
              flex-direction: column;
              .portfolio-item-section {
                &__first-group-image {
                  &--top {
                    width: 100%;
                    padding-bottom: rem(100);
                  }
                  &--description {
                    justify-content: space-between;
                    margin-bottom: rem(23);
                  }
                  &--bottom {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: stretch;
                    padding-bottom: rem(100);
                    margin-top: 0;
                    .portfolio-item-section {
                      &__inner {
                        &--arrow {
                          display: flex;
                          margin-right: rem(0);
                          margin-left: 10%;
                        }
                        &--image {
                          width: 65%;
                          padding-bottom: rem(70);
                          align-self: self-end;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__second-group-image {
        &--description {
          justify-content: space-between;
          margin-bottom: rem(47);
        }
        &--right-side {
          display: flex;
          align-items: flex-end;
          justify-content: right;
        }
      }
    }
  }
  @include media-max(sm) {
    .portfolio-item-section {
      &__first-group {
        .portfolio-item-section {
          &__first-group-image {
            &--left {
              .portfolio-item-section {
                &__image-top {
                  width: 85%;
                  margin-bottom: rem(100);
                }
                &__image-bottom {
                  width: 80%;
                  margin-bottom: rem(40);
                }
              }
            }
          }
        }
      }
          &__second-group-image {
            &--description {
            justify-content: space-between;
            margin-bottom: rem(47);
          }
            &--right-side {
            display: flex;
            align-items: flex-end;
            justify-content: right;
          }
        }
      }
    }
  }